import React from "react";
import { Row, Col, Button } from "antd";
import PlusIcon from "../../../assets/images/plusIconReport.svg";
import { useHistory } from "react-router";

const ReportDeveloper = () => {
    const history = useHistory();
    const newDeveloper = () => {
        history.push(`/others/window/7464/newReport`);
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <h2 style={{ fontWeight: "700", fontSize: "16px", color: "rgb(0 0 0 / 65%)", marginBottom: "0px", marginTop: "1%" }}>Report Developer</h2>
                </Col>
                <Col span={12}>
                    <span style={{ float: "right" }}>
                        <Button style={{ marginBottom: "8px", backgroundColor: "rgb(8 158 164)", color: "white", width: "93px", height: "33px" }} onClick={newDeveloper}>
                            New
                        </Button>
                    </span>
                </Col>
            </Row>
            <br />
            <Row>
                PURCHASE
            </Row>
            <Row>
                <Col span={4} style={{ backgroundColor: "white", height: "176px", width: "196px" }}>
                    <div style={{ textAlign: "center", marginTop: "30px" }}>
                        <img src={PlusIcon} alt="plusIcon" />
                    </div>
                    <br />
                    <br />
                    <hr style={{ marginTop: "-5px" }} />
                    <div style={{ textAlign: "center", marginTop: "12px", fontWeight: 400, fontSize: "14px" }}>New Report</div>
                </Col>
                &nbsp;
                &nbsp;
                <Col span={4} style={{ backgroundColor: "white", height: "176px", width: "196px" }}>
                    <div style={{ textAlign: "center", marginTop: "30px", height: "45px" }}>
                    </div>
                    <br />
                    <br />
                    <hr style={{ marginTop: "-5px" }} />
                    <div style={{ textAlign: "center", marginTop: "12px", fontWeight: 400, fontSize: "14px" }}>Purchase Summary Report <br /> Date</div>
                </Col>
                &nbsp;
                &nbsp;
                <Col span={4} style={{ backgroundColor: "white", height: "176px", width: "196px" }}>
                    <div style={{ textAlign: "center", marginTop: "30px", height: "45px" }}>
                    </div>
                    <br />
                    <br />
                    <hr style={{ marginTop: "-5px" }} />
                    <div style={{ textAlign: "center", marginTop: "12px", fontWeight: 400, fontSize: "14px" }}>Purchase Summary Report <br /> Date</div>
                </Col>
            </Row>
            <br />
            <Row>
                SALES
            </Row>
            <Row>
                <Col span={4} style={{ backgroundColor: "white", height: "176px", width: "196px" }}>
                    <div style={{ textAlign: "center", marginTop: "30px" }}>
                        <img src={PlusIcon} alt="plusIcon" />
                    </div>
                    <br />
                    <br />
                    <hr style={{ marginTop: "-5px" }} />
                    <div style={{ textAlign: "center", marginTop: "12px", fontWeight: 400, fontSize: "14px" }}>New Report</div>
                </Col>
                &nbsp;
                &nbsp;
                <Col span={4} style={{ backgroundColor: "white", height: "176px", width: "196px" }}>
                    <div style={{ textAlign: "center", marginTop: "30px", height: "45px" }}>
                    </div>
                    <br />
                    <br />
                    <hr style={{ marginTop: "-5px" }} />
                    <div style={{ textAlign: "center", marginTop: "12px", fontWeight: 400, fontSize: "14px" }}>Purchase Summary Report <br /> Date</div>
                </Col>
                &nbsp;
                &nbsp;
                <Col span={4} style={{ backgroundColor: "white", height: "176px", width: "196px" }}>
                    <div style={{ textAlign: "center", marginTop: "30px", height: "45px" }}>
                    </div>
                    <br />
                    <br />
                    <hr style={{ marginTop: "-5px" }} />
                    <div style={{ textAlign: "center", marginTop: "12px", fontWeight: 400, fontSize: "14px" }}>Purchase Summary Report <br /> Date</div>
                </Col>
            </Row>
        </div>
    )
};

export default ReportDeveloper;