import React, { useState, useEffect } from "react";
import { Card, Tabs, Row, Col, Form, Select, Checkbox, Button, message, Spin,Input,Space } from "antd";
import { LoadingOutlined ,MinusCircleOutlined, PlusOutlined,PlusCircleOutlined } from "@ant-design/icons";
import { useGlobalContext } from "../../../lib/storage";
import Axios from "axios";
import RetailCustomer from "./retailCustomer"
import Customers from "./customers"
import StockOpeningBalance from "./stockOpeningBalance"
import PurchaseInvoice from "./purchaseInvoice"
import SalesInvoice from "./salesInvoice"
import Products from "./product"
import { serverUrl } from "../../../constants/serverConfig";
import "antd/dist/antd.css";
import "../../../styles/antd.css";

const { TabPane } = Tabs;
const { Option } = Select;

const InitialDataLoadConfigurations = () => {
  const { globalStore } = useGlobalContext();
  const Themes = globalStore.userData.CW360_V2_UI;

  const [checkedSupValue,setcheckedSupValue] = useState(1)
  const [checkedsupName,setcheckedsupName] = useState(1)
  const [checkedsupTaxid,setcheckedsupTaxid] = useState(1)
  const [checkedsupAddressline1,setcheckedsupAddressline1] = useState(1)
  const [checkedsupAddressline2,setcheckedsupAddressline2] = useState(1)
  const [checkedsupCity,setcheckedsupCity] = useState(1)
  const [checkedsupRegion,setcheckedsupRegion] = useState(1)

  const [checkedSupValuesupValueisMandatory,setcheckedSupValuesupValueisMandatory] = useState(1)
  const [checkedsupNameisMandatory,setcheckedsupNameisMandatory] = useState(1)
  const [checkedsupTaxidisMandatory,setcheckedsupTaxidisMandatory] = useState(1)
  const [checkedsupAddressline1isMandatory,setcheckedsupAddressline1isMandatory] = useState(1)
  const [supAddressline2isMandatoryisMandatory,setsupAddressline2isMandatoryisMandatory] = useState(1)
  const [checkedsupCityisMandatory,setcheckedsupCityisMandatory] = useState(1)
  const [checkedsupRegionisMandatory,setcheckedsupRegionisMandatory] = useState(1)

  // retail customers
  const [retailCustomerValues, setRetailCustomerValues] = useState([]); 
  // customers

  const [customerValues,setCustomerValues] = useState([])
  const [stockOpeningBalanceValues,setStockOpeningBalanceValues] = useState([])
  const [dataLoadTypeForSave,setDataLoadTypeForSave] = useState('Prd')
  const [purchaseInvoiceValues,setpPurchaseInvoiceValues] = useState([])
  const [salesInvoiceValues,setSalesInvoiceValues] = useState([])
  const [productValues,setProductValues] = useState([])
  

  // End products tab

  const [loading, setLoading] = useState(false);

  const [mainForm] = Form.useForm();

  useEffect(() => {
      getAppSetupData();
  }, []);

  const getAppSetupData = async () => {
    try {
      setLoading(true);
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const getAppSetupMutation = {
        query: `query{
            getDataLoadConfig(dataLoad:"Prd")
            {
                dataLoadConfigId
                dataLoadType
                configJson
            }
          }`,
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      };

      const serverResponse = await Axios.post(serverUrl, getAppSetupMutation, { headers: headers }, { async: true }, { crossDomain: true });
      const appSetupResponse = serverResponse.data.data.getDataLoadConfig;     
      if (appSetupResponse.length > 0) {
        const appSetupRecord = appSetupResponse[0];
        const { configJson } = appSetupRecord;
        const poParsedConfigJSON = JSON.parse(configJson);
        setProductValues(poParsedConfigJSON)
        setLoading(false);
      } else {
        message.error("No Data Available");
        setLoading(false);
      }
    } catch (error) {
      const { message } = JSON.parse(JSON.stringify(error));
      if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
        localStorage.clear();
        window.location.replace("/login");
      } else {
        return Promise.reject(error);
      }
    }
  };

  const callBackTabs = async (value) => {
      setDataLoadTypeForSave(value)
    try {
        setLoading(true);
        const newToken = JSON.parse(localStorage.getItem("authTokens"));
        const getAppSetupMutation = {
          query: `query{
              getDataLoadConfig(dataLoad:"${value}")
              {
                  dataLoadConfigId
                  dataLoadType
                  configJson
              }
            }`,
        };
        const headers = {
          "Content-Type": "application/json",
          Authorization: `bearer ${newToken.access_token}`,
        };
  
        const serverResponse = await Axios.post(serverUrl, getAppSetupMutation, { headers: headers }, { async: true }, { crossDomain: true });
        const appSetupResponse = serverResponse.data.data.getDataLoadConfig;  
      if (appSetupResponse.length > 0) {
        const appSetupRecord = appSetupResponse[0];
        const { dataLoadType, configJson } = appSetupRecord;      
        if (dataLoadType === "Sup") {
          const poParsedConfigJSON = JSON.parse(configJson);
                      if(poParsedConfigJSON[0].fieldName === 'value'){
                        if(poParsedConfigJSON[0].enabledField === "Y"){
                            setcheckedSupValue(1)
                        }else{
                            setcheckedSupValue(0)
                        }
                        if(poParsedConfigJSON[0].isMandatory === "Y"){
                            setcheckedSupValuesupValueisMandatory(1)
                        }else{
                            setcheckedSupValuesupValueisMandatory(0)
                        }
                      }
                      if(poParsedConfigJSON[1].fieldName === 'name'){
                        if(poParsedConfigJSON[1].enabledField === "Y"){
                            setcheckedsupName(1)
                        }else{
                            setcheckedsupName(0)
                        }
                        if(poParsedConfigJSON[1].isMandatory === "Y"){
                            setcheckedsupNameisMandatory(1)
                        }else{
                            setcheckedsupNameisMandatory(0)
                        }
                      }
                      if(poParsedConfigJSON[2].fieldName === 'taxId'){
                        if(poParsedConfigJSON[2].enabledField === "Y"){
                            setcheckedsupTaxid(1)
                        }else{
                            setcheckedsupTaxid(0)
                        }
                        if(poParsedConfigJSON[2].isMandatory === "Y"){
                            setcheckedsupTaxidisMandatory(1)
                        }else{
                            setcheckedsupTaxidisMandatory(0)
                        }
                      }
                      if(poParsedConfigJSON[3].fieldName === 'addressLine1'){
                        if(poParsedConfigJSON[3].enabledField === "Y"){
                            setcheckedsupAddressline1(1)
                        }else{
                            setcheckedsupAddressline1(0)
                        }
                        if(poParsedConfigJSON[3].isMandatory === "Y"){
                            setcheckedsupAddressline1isMandatory(1)
                        }else{
                            setcheckedsupAddressline1isMandatory(0)
                        }
                      }
                      if(poParsedConfigJSON[4].fieldName === 'addressLine2'){
                        if(poParsedConfigJSON[4].enabledField === "Y"){
                            setcheckedsupAddressline2(1)
                        }else{
                            setcheckedsupAddressline2(0)
                        }
                        if(poParsedConfigJSON[4].isMandatory === "Y"){
                            setsupAddressline2isMandatoryisMandatory(1)
                        }else{
                            setsupAddressline2isMandatoryisMandatory(0)
                        }
                      }
                      if(poParsedConfigJSON[5].fieldName === 'city'){
                        if(poParsedConfigJSON[5].enabledField === "Y"){
                            setcheckedsupCity(1)
                        }else{
                            setcheckedsupCity(0)
                        }
                        if(poParsedConfigJSON[5].isMandatory === "Y"){
                            setcheckedsupCityisMandatory(1)
                        }else{
                            setcheckedsupCityisMandatory(0)
                        }
                      }
                      if(poParsedConfigJSON[6].fieldName === 'region'){
                        if(poParsedConfigJSON[6].enabledField === "Y"){
                            setcheckedsupRegion(1)
                        }else{
                            setcheckedsupRegion(0)
                        }
                        if(poParsedConfigJSON[6].isMandatory === "Y"){
                            setcheckedsupRegionisMandatory(1)
                        }else{
                            setcheckedsupRegionisMandatory(0)
                        }
                      }
        }else if(dataLoadType === "Rc"){
            const poParsedConfigJSON = JSON.parse(configJson);
            setRetailCustomerValues(poParsedConfigJSON)
        }else if(dataLoadType === "Cus"){
            const poParsedConfigJSON = JSON.parse(configJson);
            setCustomerValues(poParsedConfigJSON)
        }else if(dataLoadType==="SOB"){
            const poParsedConfigJSON = JSON.parse(configJson);
            setStockOpeningBalanceValues(poParsedConfigJSON)
        }else if(dataLoadType==="Pi"){
            const poParsedConfigJSON = JSON.parse(configJson);
            setpPurchaseInvoiceValues(poParsedConfigJSON)
        }else if(dataLoadType==="Si"){
            const poParsedConfigJSON = JSON.parse(configJson);
            setSalesInvoiceValues(poParsedConfigJSON)
        }else if(dataLoadType === "Prd"){
            const poParsedConfigJSON = JSON.parse(configJson);
            setProductValues(poParsedConfigJSON)
        }
        setLoading(false);
      }else{
        message.error("No data available")
        setLoading(false);
      }
    } catch (error) {
      const { message } = JSON.parse(JSON.stringify(error));
      if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
        localStorage.clear();
        window.location.replace("/login");
      } else {
        return Promise.reject(error);
      }
    }
  };

  
  const saveData = () => {
    mainForm.submit();     
  };

  const onFinish = async () => { 
      const values = mainForm.getFieldsValue()
    try {
      setLoading(true);
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const appSetupArray = [];
      let supplierConfig = [
          {fieldName:'value',columnName:'Value',enabledField:checkedSupValue === 1 ? "Y":"N",isMandatory:checkedSupValuesupValueisMandatory === 1 ? "Y":"N"},
          {fieldName:'name',columnName:'Name',enabledField:checkedsupName === 1 ? "Y":"N",isMandatory:checkedsupNameisMandatory === 1 ? "Y":"N"},
          {fieldName:'taxId',columnName:'Tax Id',enabledField:checkedsupTaxid === 1 ? "Y":"N",isMandatory:checkedsupTaxidisMandatory === 1 ? "Y":"N"},
          {fieldName:'addressLine1',columnName:'Address Line1',enabledField:checkedsupAddressline1 === 1 ? "Y":"N",isMandatory:checkedsupAddressline1isMandatory === 1 ? "Y":"N"},
          {fieldName:'addressLine2',columnName:'Address Line2',enabledField:checkedsupAddressline2 === 1 ? "Y":"N",isMandatory:supAddressline2isMandatoryisMandatory === 1 ? "Y":"N"},
          {fieldName:'city',columnName:'City',enabledField:checkedsupCity === 1 ? "Y":"N",isMandatory:checkedsupCityisMandatory === 1 ? "Y":"N"},
          {fieldName:'region',columnName:'region',enabledField:checkedsupRegion === 1 ? "Y":"N",isMandatory:checkedsupRegionisMandatory === 1 ? "Y":"N"}
      ]
      const stringifiedSupplierData= JSON.stringify(JSON.stringify(supplierConfig))
      let retailCustomerArray = [
        {fieldName:'value',columnName:'Value',enabledField:values.rcValue === undefined || values.rcValue === null || values.rcValue === false ? "N":"Y",isMandatory:values.rcValueisMandatory === undefined || values.rcValueisMandatory === null || values.rcValueisMandatory === false ? "N":"Y"},
        {fieldName:'name',columnName:'Name',enabledField:values.rcName === undefined || values.rcName === null || values.rcName === false ? "N":"Y",isMandatory:values.rcNameisMandatory === undefined || values.rcNameisMandatory === null || values.rcNameisMandatory === false ? "N":"Y"},
        {fieldName:'contactNo',columnName:'Contact No',enabledField:values.rcContactNo === undefined || values.rcContactNo === null || values.rcContactNo === false ? "N":"Y",isMandatory:values.rcContactNoisMandatory === undefined || values.rcContactNoisMandatory === null || values.rcContactNoisMandatory === false ? "N":"Y"},
        {fieldName:'creditLimit',columnName:'Credit Limit',enabledField:values.rcCreditLimit === undefined || values.rcCreditLimit === null || values.rcCreditLimit === false ? "N":"Y",isMandatory:values.rcCreditLimitisMandatory === undefined || values.rcCreditLimitisMandatory === null || values.rcCreditLimitisMandatory === false ? "N":"Y"},
        {fieldName:'loyaltyBalance',columnName:'Loyalty Balance',enabledField:values.rcLoyaltiBalance === undefined || values.rcLoyaltiBalance === null || values.rcLoyaltiBalance === false ? "N":"Y",isMandatory:values.rcLoyaltiBalanceisMandatory === undefined || values.rcLoyaltiBalanceisMandatory === null || values.rcLoyaltiBalanceisMandatory === false ? "N":"Y"},

      ]
      const stringifiedretailCustomerArray= JSON.stringify(JSON.stringify(retailCustomerArray))

      let customerArray = [
        {fieldName:'value',columnName:'Value',enabledField:values.cusValue === undefined || values.cusValue === null || values.cusValue === false ? "N":"Y",isMandatory:values.cusValueisMandatory === undefined || values.cusValueisMandatory === null || values.cusValueisMandatory === false ? "N":"Y"},
        {fieldName:'name',columnName:'Name',enabledField:values.cusName === undefined || values.cusName === null || values.cusName === false ? "N":"Y",isMandatory:values.cusNameisMandatory === undefined || values.cusNameisMandatory === null || values.cusNameisMandatory === false ? "N":"Y"},   
        {fieldName:'taxId',columnName:'Tax Id',enabledField:values.cusTaxid === undefined || values.cusTaxid === null || values.cusTaxid === false ? "N":"Y",isMandatory:values.cusTaxidisMandatory === undefined || values.cusTaxidisMandatory === null || values.cusTaxidisMandatory === false ? "N":"Y"},   
        {fieldName:'addressLine1',columnName:'Address Line1',enabledField:values.cusAddressLine1 === undefined || values.cusAddressLine1 === null || values.cusAddressLine1 === false ? "N":"Y",isMandatory:values.cusAddressLine1isMandatory === undefined || values.cusAddressLine1isMandatory === null || values.cusAddressLine1isMandatory === false ? "N":"Y"},   
        {fieldName:'addressLine2',columnName:'Address Line2',enabledField:values.cusAddressLine2 === undefined || values.cusAddressLine2 === null || values.cusAddressLine2 === false ? "N":"Y",isMandatory:values.cusAddressLine2isMandatory === undefined || values.cusAddressLine2isMandatory === null || values.cusAddressLine2isMandatory === false ? "N":"Y"},   
        {fieldName:'pinCode',columnName:'Pin Code',enabledField:values.cusPincode === undefined || values.cusPincode === null || values.cusPincode === false ? "N":"Y",isMandatory:values.cusPincodeisMandatory === undefined || values.cusPincodeisMandatory === null || values.cusPincodeisMandatory === false ? "N":"Y"},   
        {fieldName:'city',columnName:'City',enabledField:values.cusCity === undefined || values.cusCity === null || values.cusCity === false ? "N":"Y",isMandatory:values.cusCityisMandatory === undefined || values.cusCityisMandatory === null || values.cusCityisMandatory === false ? "N":"Y"},   
        {fieldName:'region',columnName:'Region',enabledField:values.cusRegion === undefined || values.cusRegion === null || values.cusRegion === false ? "N":"Y",isMandatory:values.cusRegionisMandatory === undefined || values.cusRegionisMandatory === null || values.cusRegionisMandatory === false ? "N":"Y"},   
    ]
    const stringifiedCustomerArray= JSON.stringify(JSON.stringify(customerArray))

    let stockOpeningBalaceArray = [
        {fieldName:'value',columnName:'Value',enabledField:values.sobValue === undefined || values.sobValue === null || values.sobValue === false ? "N":"Y",isMandatory:values.sobValueisMandatory === undefined || values.sobValueisMandatory === null || values.sobValueisMandatory === false ? "N":"Y"},
        {fieldName:'qty',columnName:'Qty',enabledField:values.sobQty === undefined || values.sobQty === null || values.sobQty === false ? "N":"Y",isMandatory:values.sobQtyisMandatory === undefined || values.sobQtyisMandatory === null || values.sobQtyisMandatory === false ? "N":"Y"},
        {fieldName:'cost',columnName:'Cost',enabledField:values.sobCost === undefined || values.sobCost === null || values.sobCost === false ? "N":"Y",isMandatory:values.sobCostisMandatory === undefined || values.sobCostisMandatory === null || values.sobCostisMandatory === false ? "N":"Y"},
        {fieldName:'batchNo',columnName:'Batch No',enabledField:values.sobBatchNo === undefined || values.sobBatchNo === null || values.sobBatchNo === false ? "N":"Y",isMandatory:values.sobBatchNoisMandatory === undefined || values.sobBatchNoisMandatory === null || values.sobBatchNoisMandatory === false ? "N":"Y"},
        {fieldName:'mfgDate',columnName:'Mfg Date',enabledField:values.sobMfgDate === undefined || values.sobMfgDate === null || values.sobMfgDate === false ? "N":"Y",isMandatory:values.sobMfgDateisMandatory === undefined || values.sobMfgDateisMandatory === null || values.sobMfgDateisMandatory === false ? "N":"Y"},
        {fieldName:'expDate',columnName:'Exp Date',enabledField:values.sobExpDate === undefined || values.sobExpDate === null || values.sobExpDate === false ? "N":"Y",isMandatory:values.sobExpDateisMandatory === undefined || values.sobExpDateisMandatory === null || values.sobExpDateisMandatory === false ? "N":"Y"},

    ]
    const stringifiedstockOpeningBalaceArray= JSON.stringify(JSON.stringify(stockOpeningBalaceArray))

    let purchaseInvoiceArray = [
        {fieldName:'supplierCode',columnName:'Supplier Code',enabledField:values.piSupplierCode === undefined || values.piSupplierCode === null || values.piSupplierCode === false ? "N":"Y",isMandatory:values.piSupplierCodeisMandatory === undefined || values.piSupplierCodeisMandatory === null || values.piSupplierCodeisMandatory === false ? "N":"Y"},
        {fieldName:'outstanding',columnName:'Outstanding',enabledField:values.piOutstanding === undefined || values.piOutstanding === null || values.piOutstanding === false ? "N":"Y",isMandatory:values.piOutstandingisMandatory === undefined || values.piOutstandingisMandatory === null || values.piOutstandingisMandatory === false ? "N":"Y"},
        {fieldName:'mproductId',columnName:'Product Id',value:values.mProductId,enabledField:"N",isMandatory:values.mProductIdisMandatory === undefined || values.mProductIdisMandatory === null || values.mProductIdisMandatory === false ? "N":"Y"},
    
    ]

    const stringifiedpurchaseInvoiceArray= JSON.stringify(JSON.stringify(purchaseInvoiceArray))

    let salesInvoiceArray = [
        {fieldName:'customerCode',columnName:'Customer Code',enabledField:values.sICustomerCode === undefined || values.sICustomerCode === null || values.sICustomerCode === false ? "N":"Y",isMandatory:values.sICustomerCodeisMandatory === undefined || values.sICustomerCodeisMandatory === null || values.sICustomerCodeisMandatory === false ? "N":"Y"},
        {fieldName:'outstanding',columnName:'Outstanding',enabledField:values.siOutstanding === undefined || values.siOutstanding === null || values.siOutstanding === false ? "N":"Y",isMandatory:values.siOutstandingisMandatory === undefined || values.siOutstandingisMandatory === null || values.siOutstandingisMandatory === false ? "N":"Y"},
        {fieldName:'mproductId',columnName:'Product Id',value:values.salesInvoicemProductId,enabledField:"N",isMandatory:values.simProductIdisMandatory === undefined || values.simProductIdisMandatory === null || values.simProductIdisMandatory === false ? "N":"Y"},
        ]
    const stringifiedsalesInvoiceArray= JSON.stringify(JSON.stringify(salesInvoiceArray))

      let productArray = [
        {fieldName:'productCode',columnName:'Product Code',enabledField:values.productCode === undefined || values.productCode === null || values.productCode === false ? "N":"Y",isMandatory:values.productCodeisMandatory === undefined || values.productCodeisMandatory === null || values.productCodeisMandatory === false ? "N":"Y"},
        {fieldName:'upcBarCode',columnName:'UPC/Bar Code',enabledField:values.productUpcBarCode === undefined || values.productUpcBarCode === null || values.productUpcBarCode === false ? "N":"Y",isMandatory:values.productUpcBarCodeisMandatory === undefined || values.productUpcBarCodeisMandatory === null || values.productUpcBarCodeisMandatory === false ? "N":"Y"},
        {fieldName:'productName',columnName:'Product Name',enabledField:values.productName === undefined || values.productName === null || values.productName === false ? "N":"Y",isMandatory:values.productNameisMandatory === undefined || values.productNameisMandatory === null || values.productNameisMandatory === false ? "N":"Y"},
        {fieldName:'uom',columnName:'UOM',enabledField:values.productUom === undefined || values.productUom === null || values.productUom === false ? "N":"Y",isMandatory:values.productUomisMandatory === undefined || values.productUomisMandatory === null || values.productUomisMandatory === false ? "N":"Y"},
        {fieldName:'productCategory',columnName:'Product Category',enabledField:values.productCategoery === undefined || values.productCategoery === null || values.productCategoery === false ? "N":"Y",isMandatory:values.productCategoeryisMandatory === undefined || values.productCategoeryisMandatory === null || values.productCategoeryisMandatory === false ? "N":"Y"},
        {fieldName:'brand',columnName:'Brand',enabledField:values.productBrand === undefined || values.productBrand === null || values.productBrand === false ? "N":"Y",isMandatory:values.productBrandisMandatory === undefined || values.productBrandisMandatory === null || values.productBrandisMandatory === false ? "N":"Y"},
        {fieldName:'imageUrl',columnName:'Image URL',enabledField:values.productImageUrl === undefined || values.productImageUrl === null || values.productImageUrl === false ? "N":"Y",isMandatory:values.productImageUrlisMandatory === undefined || values.productImageUrlisMandatory === null || values.productImageUrlisMandatory === false ? "N":"Y"},
        {fieldName:'taxCategory',columnName:'Tax Category',enabledField:values.productTaxCategory === undefined || values.productTaxCategory === null || values.productTaxCategory === false ? "N":"Y",isMandatory:values.productTaxCategoryisMandatory === undefined || values.productTaxCategoryisMandatory === null || values.productTaxCategoryisMandatory === false ? "N":"Y"},     
        {fieldName:'hsnCode',columnName:'HSN Code',enabledField:values.productHsnCode === undefined || values.productHsnCode === null || values.productHsnCode === false ? "N":"Y",isMandatory:values.productHsnCodeisMandatory === undefined || values.productHsnCodeisMandatory === null || values.productHsnCodeisMandatory === false ? "N":"Y"},
        {fieldName:'batchForSale',columnName:'Batch For Sale',enabledField:values.productBatchForSale === undefined || values.productBatchForSale === null || values.productBatchForSale === false ? "N":"Y",isMandatory:values.productBatchForSaleisMandatory === undefined || values.productBatchForSaleisMandatory === null || values.productBatchForSaleisMandatory === false ? "N":"Y"},
        {fieldName:'batchForStock',columnName:'Batch For Stock',enabledField:values.productBatchForStock === undefined || values.productBatchForStock === null || values.productBatchForStock === false ? "N":"Y",isMandatory:values.productBatchForStockisMandatory === undefined || values.productBatchForStockisMandatory === null || values.productBatchForStockisMandatory === false ? "N":"Y"},
        {fieldName:'shelfLifeInMonnths',columnName:'Shelf Life(In Months)',enabledField:values.productShelfLife === undefined || values.productShelfLife === null || values.productShelfLife === false ? "N":"Y",isMandatory:values.productShelfLifeisMandatory === undefined || values.productShelfLifeisMandatory === null || values.productShelfLifeisMandatory === false ? "N":"Y"},
        {fieldName:'salePrice',columnName:'Sale Price',enabledField:values.productSalePrice === undefined || values.productSalePrice === null || values.productSalePrice === false ? "N":"Y",isMandatory:values.productSalePriceisMandatory === undefined || values.productSalePriceisMandatory === null || values.productSalePriceisMandatory === false ? "N":"Y"}, 
        {fieldName:'mrp',columnName:'MRP',enabledField:values.productMrp === undefined || values.productMrp === null || values.productMrp === false ? "N":"Y",isMandatory:values.productMrpisMandatory === undefined || values.productMrpisMandatory === null || values.productMrpisMandatory === false ? "N":"Y"},
        {fieldName:'supplier',columnName:'Supplier',enabledField:values.productSupplier === undefined || values.productSupplier === null || values.productSupplier === false ? "N":"Y",isMandatory:values.productSupplierisMandatory === undefined || values.productSupplierisMandatory === null || values.productSupplierisMandatory === false ? "N":"Y"},
        {fieldName:'purchasePrice',columnName:'Purchase Price',enabledField:values.productPurchasePrice === undefined || values.productPurchasePrice === null || values.productPurchasePrice === false ? "N":"Y",isMandatory:values.productPurchasePriceisMandatory === undefined || values.productPurchasePriceisMandatory === null || values.productPurchasePriceisMandatory === false ? "N":"Y"},
      ]

      const stringifiedproductArray= JSON.stringify(JSON.stringify(productArray))

      if(dataLoadTypeForSave === "Prd"){
        appSetupArray.push(
            `{
                dataLoadType:"Prd"
                configJson:${stringifiedproductArray}
            }`
        )  
      }else if(dataLoadTypeForSave==="Sup"){
        appSetupArray.push(
            `{
                dataLoadType:"Sup"
                configJson:${stringifiedSupplierData}
            }`
        )
      }else if(dataLoadTypeForSave==="Rc"){
        appSetupArray.push(
            `{
                dataLoadType:"Rc"
                configJson:${stringifiedretailCustomerArray}
            }`
        )
      }else if(dataLoadTypeForSave==="Cus"){
        appSetupArray.push(
            `{
                dataLoadType:"Cus"
                configJson:${stringifiedCustomerArray}
            }`
        )
      }else if(dataLoadTypeForSave==="SOB"){
        appSetupArray.push(
            `{
                dataLoadType:"SOB"
                configJson:${stringifiedstockOpeningBalaceArray}
            }`
        )
      }else if(dataLoadTypeForSave==="Pi"){
        appSetupArray.push(
            `{
                dataLoadType:"Pi"
                configJson:${stringifiedpurchaseInvoiceArray}
            }`
        )
      }else if(dataLoadTypeForSave==="Si"){
        appSetupArray.push(
            `{
                dataLoadType:"Si"
                configJson:${stringifiedsalesInvoiceArray}
            }`
        )
      }   
      const appSetupMutation = {
        query: `mutation {
            upsertDataLoadConfig(dataLoad: { 
                dataLoadConfigs:[${appSetupArray}]
          }) {
            status
            message
        }
      }`,
      };

      Axios({
        url: serverUrl,
        method: "POST",
        data: appSetupMutation,
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${newToken.access_token}`,
        },
      }).then((response) => {
        const appSetupResponse = response.data.data.upsertDataLoadConfig;
        if (appSetupResponse.status === "200") {          
          message.success(appSetupResponse.message);
          setLoading(false);
        } else {
          message.error(appSetupResponse.message);
          setLoading(false);
        }
      });
    } catch (error) {
      const { message } = JSON.parse(JSON.stringify(error));
      if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
        localStorage.clear();
        window.location.replace("/login");
      } else {
        return Promise.reject(error);
      }
    }
  };

  const onChangeCheckbox = (e) => {
    const eventId = e.target.id;
    const checkedValue = e.target.checked;
      if (eventId === "control-hooks_supValue") {
        if (checkedValue === true) {
            setcheckedSupValue(1);
        } else {
            setcheckedSupValue(0);
        }
      }
      if (eventId === "control-hooks_supName") {
        if (checkedValue === true) {
            setcheckedsupName(1);
        } else {
            setcheckedsupName(0);
        }
      }
      if (eventId === "control-hooks_supTaxid") {
        if (checkedValue === true) {
            setcheckedsupTaxid(1);
        } else {
            setcheckedsupTaxid(0);
        }
      }
      if (eventId === "control-hooks_supAddressline1") {
        if (checkedValue === true) {
            setcheckedsupAddressline1(1);
        } else {
            setcheckedsupAddressline1(0);
        }
      }
      if (eventId === "control-hooks_supAddressline2") {
        if (checkedValue === true) {
            setcheckedsupAddressline2(1);
        } else {
            setcheckedsupAddressline2(0);
        }
      }
      if (eventId === "control-hooks_supCity") {
        if (checkedValue === true) {
            setcheckedsupCity(1);
        } else {
            setcheckedsupCity(0);
        }
      }
      if (eventId === "control-hooks_supRegion") {
        if (checkedValue === true) {
            setcheckedsupRegion(1);
        } else {
            setcheckedsupRegion(0);
        }
      }
      if (eventId === "control-hooks_supValueisMandatory") {
        if (checkedValue === true) {
            setcheckedSupValuesupValueisMandatory(1);
        } else {
            setcheckedSupValuesupValueisMandatory(0);
        }
      }
      if (eventId === "control-hooks_supNameisMandatory") {
        if (checkedValue === true) {
            setcheckedsupNameisMandatory(1);
        } else {
            setcheckedsupNameisMandatory(0);
        }
      }
      if (eventId === "control-hooks_supTaxidisMandatory") {
        if (checkedValue === true) {
            setcheckedsupTaxidisMandatory(1);
        } else {
            setcheckedsupTaxidisMandatory(0);
        }
      }
      if (eventId === "control-hooks_supAddressline1isMandatory") {
        if (checkedValue === true) {
            setcheckedsupAddressline1isMandatory(1);
        } else {
            setcheckedsupAddressline1isMandatory(0);
        }
      }
      if (eventId === "control-hooks_supAddressline2isMandatory") {
        if (checkedValue === true) {
            setsupAddressline2isMandatoryisMandatory(1);
        } else {
            setsupAddressline2isMandatoryisMandatory(0);
        }
      }
      if (eventId === "control-hooks_supCityisMandatory") {
        if (checkedValue === true) {
            setcheckedsupCityisMandatory(1);
        } else {
            setcheckedsupCityisMandatory(0);
        }
      }
      if (eventId === "control-hooks_supRegionisMandatory") {
        if (checkedValue === true) {
            setcheckedsupRegionisMandatory(1);
        } else {
            setcheckedsupRegionisMandatory(0);
        }
      }
    // Product checkbox end

  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const getAllFormValues = (e,val) =>{
    console.log("e:", e);
    console.log("val:", val);
  }

  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: "52px" }} spin />} spinning={loading}>
      <div>
        <Row>
          <Col span={12}>
            <h2>Initial Data Load Configuration</h2>
          </Col>
          <Col span={10} />
          <Col span={2}>
            <Button style={Themes.contentWindow.ListWindowHeader.newButtonForlist} type="primary" onClick={saveData}>
              Save
            </Button>
            &nbsp;
          </Col>
         
        </Row>
        <Row>
          <Col span={24}>
            <Form layout="vertical" form={mainForm} name="control-hooks" onValuesChange={getAllFormValues} onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <Card>
                <Tabs defaultActiveKey={"PO"} onChange={callBackTabs}>
                  <TabPane tab="Products" key="Prd">
                    <Products productValues={productValues} />
                  </TabPane>
                  <TabPane tab="Suppliers" key="Sup">
                  <div style={{ padding: "8px" }}>
                      <h3>Suppliers</h3>
                      <Row gutter={16}>
                      <Col className="gutter-row" span={6}>
                        <Card style={{ border: "1px solid" }}>
                            <Row>
                                <Col className="gutter-row" span={2} />
                                <Col className="gutter-row" span={10}>
                                <Form.Item label="Value" name="supValue">
                                <Checkbox
                                    checked={checkedSupValue}
                                    onChange={onChangeCheckbox}
                                    style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                                </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={2} />
                                <Col className="gutter-row" span={10}>
                                <Form.Item label="isMandatory" name="supValueisMandatory">
                                    <Checkbox
                                    checked={checkedSupValuesupValueisMandatory}
                                    onChange={onChangeCheckbox}
                                    style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                                </Form.Item>
                                </Col>
                            </Row>
                            <br />
                        </Card>
                        </Col>
                        <Col className="gutter-row" span={6}>
                        <Card style={{ border: "1px solid" }}>
                            <Row>
                                <Col className="gutter-row" span={2} />
                                <Col className="gutter-row" span={10}>
                                <Form.Item label="Name" name="supName">
                                    <Checkbox
                                    checked={checkedsupName}
                                    onChange={onChangeCheckbox}
                                    style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                                    ></Checkbox>
                                </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={2} />
                                <Col className="gutter-row" span={10}>
                                <Form.Item label="isMandatory" name="supNameisMandatory">
                                    <Checkbox
                                    checked={checkedsupNameisMandatory}
                                    onChange={onChangeCheckbox}
                                    style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                                </Form.Item>
                                </Col>
                            </Row>
                            <br />
                        </Card>
                        </Col>
                        <Col className="gutter-row" span={6}>
                        <Card style={{ border: "1px solid" }}>
                            <Row>
                                <Col className="gutter-row" span={2} />
                                <Col className="gutter-row" span={10}>
                                <Form.Item label="Tax Id" name="supTaxid">
                                    <Checkbox
                                    checked={checkedsupTaxid}
                                    onChange={onChangeCheckbox}
                                    style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                                    ></Checkbox>
                                </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={2} />
                                <Col className="gutter-row" span={10}>
                                <Form.Item label="isMandatory" name="supTaxidisMandatory">
                                    <Checkbox
                                    checked={checkedsupTaxidisMandatory}
                                    onChange={onChangeCheckbox}
                                    style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                                </Form.Item>
                                </Col>
                            </Row>
                            <br />
                        </Card>
                        </Col>
                        <Col className="gutter-row" span={6}>
                        <Card style={{ border: "1px solid" }}>
                            <Row>
                                <Col className="gutter-row" span={2} />
                                <Col className="gutter-row" span={10}>
                                <Form.Item label="Address Line1" name="supAddressline1">
                                    <Checkbox
                                    checked={checkedsupAddressline1}
                                    onChange={onChangeCheckbox}
                                    style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                                    ></Checkbox>
                                </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={2} />
                                <Col className="gutter-row" span={10}>
                                <Form.Item label="isMandatory" name="supAddressline1isMandatory">
                                    <Checkbox
                                    checked={checkedsupAddressline1isMandatory}
                                    onChange={onChangeCheckbox}
                                    style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                                </Form.Item>
                                </Col>
                            </Row>
                            <br />
                        </Card>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                      <Col className="gutter-row" span={6}>
                        <Card style={{ border: "1px solid" }}>
                            <Row>
                                <Col className="gutter-row" span={2} />
                                <Col className="gutter-row" span={10}>
                                <Form.Item label="Address Line2" name="supAddressline2">
                                    <Checkbox
                                    checked={checkedsupAddressline2}
                                    onChange={onChangeCheckbox}
                                    style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                                    ></Checkbox>
                                </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={2} />
                                <Col className="gutter-row" span={10}>
                                <Form.Item label="isMandatory" name="supAddressline2isMandatory">
                                    <Checkbox
                                    checked={supAddressline2isMandatoryisMandatory}
                                    onChange={onChangeCheckbox}
                                    style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                                </Form.Item>
                                </Col>
                            </Row>
                            <br />
                        </Card>
                        </Col>
                        <Col className="gutter-row" span={6}>
                        <Card style={{ border: "1px solid" }}>
                            <Row>
                                <Col className="gutter-row" span={2} />
                                <Col className="gutter-row" span={10}>
                                <Form.Item label ="City" name="supCity">
                                <Checkbox
                                    checked={checkedsupCity}
                                    onChange={onChangeCheckbox}
                                    style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                                </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={2} />
                                <Col className="gutter-row" span={10}>
                                <Form.Item label="isMandatory" name="supCityisMandatory">
                                    <Checkbox
                                    checked={checkedsupCityisMandatory}
                                    onChange={onChangeCheckbox}
                                    style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                                </Form.Item>
                                </Col>
                            </Row>
                            <br />
                        </Card>
                        </Col>
                        <Col className="gutter-row" span={6}>
                        <Card style={{ border: "1px solid" }}>
                            <Row>
                                <Col className="gutter-row" span={2} />
                                <Col className="gutter-row" span={10}>
                                <Form.Item  label ="Region" name="supRegion">
                                <Checkbox
                                    checked={checkedsupRegion}
                                    onChange={onChangeCheckbox}
                                    style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                                </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={2} />
                                <Col className="gutter-row" span={10}>
                                <Form.Item label="isMandatory" name="supRegionisMandatory">
                                    <Checkbox
                                    checked={checkedsupRegionisMandatory}
                                    onChange={onChangeCheckbox}
                                    style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                                </Form.Item>
                                </Col>
                            </Row>
                            <br />
                        </Card>
                        </Col>
                      </Row>
                      <br />
                    </div>
                  </TabPane>
                  <TabPane tab="Retail Customer" key = "Rc">
                    <RetailCustomer retailCustomerValues={retailCustomerValues} />
                  </TabPane>
                  <TabPane tab="Customers" key = "Cus">
                    <Customers customerValues={customerValues} />
                  </TabPane>
                  <TabPane tab="Stock Opening Balance" key = "SOB">
                    <StockOpeningBalance stockOpeningBalanceValues={stockOpeningBalanceValues} />
                  </TabPane>
                  <TabPane tab="Purchase Invoice" key = "Pi">
                    <PurchaseInvoice purchaseInvoiceValues={purchaseInvoiceValues} />
                  </TabPane>
                  <TabPane tab="Sales Invoice" key = "Si">
                    <SalesInvoice salesInvoiceValues={salesInvoiceValues} />
                  </TabPane>
                </Tabs>
              </Card>
            </Form>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default InitialDataLoadConfigurations;
