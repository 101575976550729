import React, { useEffect, useState } from "react";
import { Card, Form, Row, Col, Select, Button, Modal, Spin, Table, Image, Input, DatePicker, Radio, Space, Tabs,Popover, message, InputNumber,Menu } from "antd";
import { LoadingOutlined, PlusCircleOutlined, PrinterOutlined, EditOutlined, DeleteOutlined, SearchOutlined, DownOutlined } from "@ant-design/icons";
import { v4 as uuid } from "uuid";
import AuditTrail from "../../../assets/images/auditTrail.svg";
import Arrow from "../../../assets/images/arrow.svg";
import { getWorkOrderDetails, getAddonProductData, getRoleBusinessUnit,getSalesOrderDetailsData } from "../../../services/custom";
import { getOrderTypeData, getNewSalesCustomerData, getNewSalesPrice, getNewSalesRep, getCustomerAddressData, getOrderFormData, getSalesTypeData } from "../../../services/generic";
import moment from "moment";
import { serverUrl, genericUrl } from "../../../constants/serverConfig";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import ListData from "../NewWorkOrer/listData";
import { Collapse } from 'antd';
import "antd/dist/antd.css";
import "../../../styles/antd.css";
import Filter from "../../../assets/images/filter.svg";
// import { useHistory } from "react-router";
import { Scrollbars } from "react-custom-scrollbars";
import { useParams } from "react-router-dom";



const { Option } = Select;
const { TabPane } = Tabs;
const dateFormat = "YYYY-MM-DD";
const { Panel } = Collapse;

const ManageWorkOrder = () => {
const [loading, setLoading] = useState(false)
const [isListView,setIsListView] = useState(false)
const [workDetailsData,setWorkDetailsData] = useState([])
const [itemData,setItemData]= useState([])

  const { recordId } = useParams();

  useEffect(() => {
    if (recordId === "New_Record") {
      setIsListView(true);
    } else if (recordId) {
      setIsListView(true);
      setLoading(true);
      getWorkOrderDetailsData(recordId)
      // console.log(recordId)
    } else {
      setIsListView(false);
    }
  }, [recordId]);
const history = useHistory();


  const getWorkOrderDetailsData = async(recordId)=>{
    const workOrderDetailsData = await getWorkOrderDetails(recordId)
    setLoading(false)
    setWorkDetailsData(workOrderDetailsData)
    let data = []
    data.push(workOrderDetailsData[0].pOrderLines[0])
    setItemData(data)
    console.log(workOrderDetailsData)
  }

  const getMenuData=(data)=>{
    let item = []
    item.push(data)
    setItemData(item)
    console.log(data)
  }

  const renderView = ({ style, ...props }) => {
    const viewStyle = {
      color: "#00000",
    };
    return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
  };

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: "#c1c1c1",
      borderRadius: "5px",
      width: "8px",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };
  const style1 = {fontSize:'15px',letterSpacing: "0.3px",color: "#666666"}
 
  const style2 = {fontSize:'13px',letterSpacing: "0.52px",color: "#8E8E8E"}

  return (
   <div>
     { isListView ? workDetailsData.map((element)=>{
     return (
     <div>
      <Row >
      <Col span={12} style={{display:'flex',flexDirection:'column'}}>
        <span  style={{ fontWeight: "500", fontSize: "12px", color: "#1648AA", marginBottom: "0px", marginTop: "1%",cursor:'pointer' }} onClick={()=>{history.push(`/others/window/456`);}}>Work Order</span>
        <span  style={{ fontWeight: "500", fontSize: "25px", color: "#161537", marginBottom: "0px",marginLeft:'0px' }}>New Work Order</span>
      </Col>
      <Col span={11}>
        <span style={{ float: "right" }}>
          <Button  style={{ marginBottom: "8px", backgroundColor: "rgb(8 158 164)", color: "white", width: "93px", height: "33px" }}>
            New
          </Button>
          <Button style={{ marginBottom: "8px", backgroundColor: "rgb(8 158 164)", color: "white", width: "93px", height: "33px" }}>
            More
            <DownOutlined style={{ fontSize: "10px" }} />
          </Button>
        </span>
      </Col>
    </Row>
    <Card>
     <Row gutter={16} >
     <Col span={6} style={{marginTop:'10px',marginBottom:'10px' }}>
       <span style={style2}>Supplier</span>
       <br/>
       <span style={style1}>{element.supplierName}</span>
     </Col>
     <Col span={6} style={{marginTop:'10px',marginBottom:'10px'}}>
     <span  style={style2}>Date</span>
       <br/>
       <span style={style1}>{element.date}</span>
     </Col>
     <Col span={6} style={{marginTop:'10px',marginBottom:'10px'}}>
     <span  style={style2}>Work Order</span>
       <br/>
       <span style={style1}>{element.documentNo}</span>
     </Col>
     <Col span={6} style={{marginTop:'10px',marginBottom:'10px'}}>
     <span  style={style2}>Location</span>
       <br/>
       <span style={style1}>{element.city}</span>
     </Col>
     <Col span={6} style={{marginTop:'10px',marginBottom:'10px'}}>
     <span  style={style2}>Sales Order</span>
       <br/>
       <span style={style1}>{element.sDocumentNo}</span>
     </Col>
     <Col span={6} style={{marginTop:'10px',marginBottom:'10px'}}>
     <span  style={style2}>Required By</span>
       <br/>
       <span style={style1}>{element.date}</span>
     </Col>
     </Row>
    </Card>
      <Row style={{marginTop:'8px'}}>
        <Col span={3}>
        <Scrollbars
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            thumbSize={100}
            renderView={renderView}
            // renderThumbHorizontal={renderThumb}
            renderThumbVertical={renderThumb}
            style={{ height: "53vh" }}
          >
        {element.pOrderLines.map((item,index)=>{
          return(
            <Menu defaultSelectedKeys={[element.pOrderLines[0].prOrderId]} >
              {console.log()}
              <Menu.Item key={item.prOrderId} onClick={()=>getMenuData(item)} style={{backgroundColor:`${item.prOrderId === itemData[0].prOrderId? "#FFFF" : "#666666"}`}} ><img src={item.imageUrl} style={{height:'25px',width:'25px',borderRadius:'3px'}}/> {item.batchNo !== "" || item.batchNo !== undefined || item.batchNo !== null ? item.batchNo: null}</Menu.Item>
            </Menu>
         )
        }
        

        )}
        </Scrollbars>

        </Col>

        <Col span={21} style={{background:'white'}}>
          {itemData.map((ele)=>{
            return (<Row style={{marginTop:'20px',marginLeft:'20px'}}>
            <Col span={10} style={{marginLeft:'0px'}}>
          <img src={ele.imageUrl} style={{width: "350px", height: "300px",border: "0.5px solid #D7DADE"}}/>
            </Col>
            <Col span={14} style={{marginLeft:'-15px',marginTop:'-8px'}}>
            <div style={{marginRight:'0px',color: '#666666',fontSize:'22px',fontWeight:'500',marginBottom:'8px'}}>
              Twisting Vine Ring
              </div>
              <Form layout="vertical">
              
                <Row gutter={16}>
                
                  <Col span={8} >
                  <Form.Item name="sku" label="Sku" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                    <Input/>
                  </Form.Item>
                  </Col>
                  <Col span={4} >
                  <Form.Item name="ringSize" label="Ring Size" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                    <Input/>
                  </Form.Item>
                  </Col>
                  <Col span={4} >
                  <Form.Item name="" label="" style={{ color: "#8E8E8E", fontSize: "13px",marginTop:'24px' }}>
                    <Input/>
                  </Form.Item>
                  </Col>
                  <Col span={8} >
                  <Form.Item name="price" label="Price" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                    <Input/>
                  </Form.Item>
                  </Col>
                </Row>
                
              </Form>
              <Scrollbars
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            thumbSize={100}
            renderView={renderView}
            // renderThumbHorizontal={renderThumb}
            renderThumbVertical={renderThumb}
            style={{ height: "30vh",marginTop:'20px' }}
          >
              <Collapse  ghost style={{marginTop:'20px'}}>
              
                <Panel header="Metal" key="1">
                  <Row>
                    <Col span={5}>
                      <span style={{letterSpacing: '0.52px',color: '#8E8E8E',fontSize:'13px'}}>
                        Purity
                      </span>
                      <br/>
                      <span style={{letterSpacing: '0.3px',color: '#666666',fontSize:'15px'}}> 
                        18KT
                      </span>
                    </Col>
                    <Col span={5}>
                    <span style={{letterSpacing: '0.52px',color: '#8E8E8E',fontSize:'13px'}}>
                        Color
                      </span>
                      <br/>
                      <span style={{letterSpacing: '0.3px',color: '#666666',fontSize:'15px'}}> 
                        White
                      </span>
                    </Col>
                    <Col span={5}>
                    <span style={{letterSpacing: '0.52px',color: '#8E8E8E',fontSize:'13px'}}>
                        Qty
                      </span>
                      <br/>
                      <span style={{letterSpacing: '0.3px',color: '#666666',fontSize:'15px'}}> 
                        5
                      </span>
                    </Col>
                    <Col span={5}>
                    <span style={{letterSpacing: '0.52px',color: '#8E8E8E',fontSize:'13px'}}>
                        Unit
                      </span>
                      <br/>
                      <span style={{letterSpacing: '0.3px',color: '#666666',fontSize:'15px'}}> 
                      grams
                      </span>
                    </Col>
                    <Col span={4}>
                    <span style={{letterSpacing: '0.52px',color: '#8E8E8E',fontSize:'13px'}}>
                        Unit Price
                      </span>
                      <br/>
                      <span style={{letterSpacing: '0.3px',color: '#666666',fontSize:'15px'}}> 
                        20,000.00
                      </span>
                    </Col>
                  </Row>
                </Panel>
                <Panel header="Solitaire" key="2">
                <Row>
                    <Col span={5}>
                      <span style={{letterSpacing: '0.52px',color: '#8E8E8E',fontSize:'13px'}}>
                        Purity
                      </span>
                      <br/>
                      <span style={{letterSpacing: '0.3px',color: '#666666',fontSize:'15px'}}> 
                        18KT
                      </span>
                    </Col>
                    <Col span={5}>
                    <span style={{letterSpacing: '0.52px',color: '#8E8E8E',fontSize:'13px'}}>
                        Color
                      </span>
                      <br/>
                      <span style={{letterSpacing: '0.3px',color: '#666666',fontSize:'15px'}}> 
                        White
                      </span>
                    </Col>
                    <Col span={5}>
                    <span style={{letterSpacing: '0.52px',color: '#8E8E8E',fontSize:'13px'}}>
                        Qty
                      </span>
                      <br/>
                      <span style={{letterSpacing: '0.3px',color: '#666666',fontSize:'15px'}}> 
                        5
                      </span>
                    </Col>
                    <Col span={5}>
                    <span style={{letterSpacing: '0.52px',color: '#8E8E8E',fontSize:'13px'}}>
                        Unit
                      </span>
                      <br/>
                      <span style={{letterSpacing: '0.3px',color: '#666666',fontSize:'15px'}}> 
                      grams
                      </span>
                    </Col>
                    <Col span={4}>
                    <span style={{letterSpacing: '0.52px',color: '#8E8E8E',fontSize:'13px'}}>
                        Unit Price
                      </span>
                      <br/>
                      <span style={{letterSpacing: '0.3px',color: '#666666',fontSize:'15px'}}> 
                        20,000.00
                      </span>
                    </Col>
                  </Row>
                </Panel>
                <Panel header="Diamond" key="3">
                <Row>
                    <Col span={5}>
                      <span style={{letterSpacing: '0.52px',color: '#8E8E8E',fontSize:'13px'}}>
                        Purity
                      </span>
                      <br/>
                      <span style={{letterSpacing: '0.3px',color: '#666666',fontSize:'15px'}}> 
                        18KT
                      </span>
                    </Col>
                    <Col span={5}>
                    <span style={{letterSpacing: '0.52px',color: '#8E8E8E',fontSize:'13px'}}>
                        Color
                      </span>
                      <br/>
                      <span style={{letterSpacing: '0.3px',color: '#666666',fontSize:'15px'}}> 
                        White
                      </span>
                    </Col>
                    <Col span={5}>
                    <span style={{letterSpacing: '0.52px',color: '#8E8E8E',fontSize:'13px'}}>
                        Qty
                      </span>
                      <br/>
                      <span style={{letterSpacing: '0.3px',color: '#666666',fontSize:'15px'}}> 
                        5
                      </span>
                    </Col>
                    <Col span={5}>
                    <span style={{letterSpacing: '0.52px',color: '#8E8E8E',fontSize:'13px'}}>
                        Unit
                      </span>
                      <br/>
                      <span style={{letterSpacing: '0.3px',color: '#666666',fontSize:'15px'}}> 
                      grams
                      </span>
                    </Col>
                    <Col span={4}>
                    <span style={{letterSpacing: '0.52px',color: '#8E8E8E',fontSize:'13px'}}>
                        Unit Price
                      </span>
                      <br/>
                      <span style={{letterSpacing: '0.3px',color: '#666666',fontSize:'15px'}}> 
                        20,000.00
                      </span>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
              </Scrollbars>

            </Col>
          </Row>)
          })}
        </Col>
      </Row>
    
    </div>)
     }) 
     
     : <ListData/>
     }
   </div>
  )
};

export default ManageWorkOrder;
