import React from "react";
import {Row,Col,Card,Checkbox,Form,Input} from 'antd'

const SalesInvoice = (props) => {
  const propsValues = props.salesInvoiceValues;
  return (
    <div style={{ padding: "8px" }}>
      <h3>Sales Invoice</h3>
      {propsValues.length > 0 ?
      <div>
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
            <Row>
              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="Customer Code" name="sICustomerCode" valuePropName="checked" initialValue={propsValues[0].enabledField === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="isMandatory" name="sICustomerCodeisMandatory" valuePropName="checked" initialValue={propsValues[0].isMandatory === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <br />
          </Card>
        </Col>
        <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
            <Row>
              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="Outstanding" name="siOutstanding" valuePropName="checked" initialValue={propsValues[1].enabledField === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="isMandatory" name="siOutstandingisMandatory" valuePropName="checked" initialValue={propsValues[1].isMandatory === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <br />
          </Card>
        </Col>
        <Col className="gutter-row" span={12}>
          <Card style={{ border: "1px solid" }}>
            <Row>
              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="ProductId" name="salesInvoicemProductId" initialValue={propsValues[2].value}>
                  <Input  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }} />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="isMandatory" name="simProductIdisMandatory" valuePropName="checked" initialValue={propsValues[2].isMandatory === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <br />
          </Card>
        </Col>
      </Row>
      </div>
      :null}
    </div>
    
  );
};

export default SalesInvoice;
