import React from "react";
import {Row,Col,Card,Checkbox,Form} from 'antd'

const Customers = (props) => {
  const propsValues = props.customerValues;

  return (
    <div style={{ padding: "8px" }}>
      <h3>Customers</h3>
      {propsValues.length > 0 ?
      <div>
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
            <Row>
              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="Value" name="cusValue" valuePropName="checked" initialValue={propsValues[0].enabledField === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="isMandatory" name="cusValueisMandatory" valuePropName="checked" initialValue={propsValues[0].isMandatory === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <br />
          </Card>
        </Col>
        <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
            <Row>
              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="Name" name="cusName" valuePropName="checked" initialValue={propsValues[1].enabledField === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="isMandatory" name="cusNameisMandatory" valuePropName="checked" initialValue={propsValues[1].isMandatory === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <br />
          </Card>
        </Col>
        <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
            <Row>
              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="Tax Id" name="cusTaxid" valuePropName="checked" initialValue={propsValues[2].enabledField === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="isMandatory" name="cusTaxidisMandatory" valuePropName="checked" initialValue={propsValues[2].isMandatory === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <br />
          </Card>
        </Col>
        <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
            <Row>
              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="Address Line1" name="cusAddressLine1" valuePropName="checked" initialValue={propsValues[3].enabledField === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="isMandatory" name="cusAddressLine1isMandatory" valuePropName="checked" initialValue={propsValues[3].isMandatory === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <br />
          </Card>
        </Col>
      </Row>
      <br />
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
            <Row>
              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="Address Line2" name="cusAddressLine2" valuePropName="checked" initialValue={propsValues[4].enabledField === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="isMandatory" name="cusAddressLine2isMandatory" valuePropName="checked" initialValue={propsValues[4].isMandatory === "Y" ? true : false}>
                  <Checkbox
                    style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                  ></Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <br />
          </Card>
        </Col>
        <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
            <Row>
              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="Pin Code" name="cusPincode" valuePropName="checked" initialValue={propsValues[5].enabledField === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="isMandatory" name="cusPincodeisMandatory" valuePropName="checked" initialValue={propsValues[5].isMandatory === "Y" ? true : false}>
                  <Checkbox
                    style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                  ></Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <br />
          </Card>
        </Col>
        <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
            <Row>
              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="City" name="cusCity" valuePropName="checked" initialValue={propsValues[6].enabledField === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="isMandatory" name="cusCityisMandatory" valuePropName="checked" initialValue={propsValues[6].isMandatory === "Y" ? true : false}>
                  <Checkbox
                    style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                  ></Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <br />
          </Card>
        </Col>
        <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
            <Row>
              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="Region" name="cusRegion" valuePropName="checked" initialValue={propsValues[7].enabledField === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="isMandatory" name="cusRegionisMandatory" valuePropName="checked" initialValue={propsValues[7].isMandatory === "Y" ? true : false}>
                  <Checkbox
                    style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                  ></Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <br />
          </Card>
        </Col>
      </Row>
      </div>
      :null}
    </div>
    
  );
};

export default Customers;
