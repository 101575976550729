import React from "react";
import {Row,Col,Card,Checkbox,Form} from 'antd'

const Products = (props) => {
  const propsValues = props.productValues;

  return (
    <div style={{ padding: "8px" }}>
      <h3>Products</h3>
      {propsValues.length > 0 ?
       <div>
        <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
              <Row>
                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label="Product Code" name="productCode" valuePropName="checked" initialValue={propsValues[0].enabledField === "Y" ? true : false}>
                  <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label="isMandatory" name="productCodeisMandatory" valuePropName="checked" initialValue={propsValues[0].isMandatory === "Y" ? true : false}>
                      <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>
              </Row>
              <br />
          </Card>
          </Col>
          <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
              <Row>
                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label="UPC/Bar Code" name="productUpcBarCode" valuePropName="checked" initialValue={propsValues[1].enabledField === "Y" ? true : false}>
                      <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                      ></Checkbox>
                  </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label="isMandatory" name="productUpcBarCodeisMandatory" valuePropName="checked" initialValue={propsValues[1].isMandatory === "Y" ? true : false}>
                      <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>
              </Row>
              <br />
          </Card>
          </Col>
          <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
              <Row>
                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label="Product Name" name="productName" valuePropName="checked" initialValue={propsValues[2].enabledField === "Y" ? true : false}>
                      <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                      ></Checkbox>
                  </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label="isMandatory" name="productNameisMandatory" valuePropName="checked" initialValue={propsValues[2].isMandatory === "Y" ? true : false}>
                      <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>
              </Row>
              <br />
          </Card>
          </Col>
          <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
              <Row>
                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label="UOM" name="productUom" valuePropName="checked" initialValue={propsValues[3].enabledField === "Y" ? true : false}>
                      <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                      ></Checkbox>
                  </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label="isMandatory" name="productUomisMandatory" valuePropName="checked" initialValue={propsValues[3].isMandatory === "Y" ? true : false}>
                      <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>
              </Row>
              <br />
          </Card>
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
              <Row>
                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label="Product Category" name="productCategoery" valuePropName="checked" initialValue={propsValues[4].enabledField === "Y" ? true : false}>
                      <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                      ></Checkbox>
                  </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label="isMandatory" name="productCategoeryisMandatory" valuePropName="checked" initialValue={propsValues[4].isMandatory === "Y" ? true : false}>
                      <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>
              </Row>
              <br />
          </Card>
          </Col>
          <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
              <Row>
                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label ="Brand" name="productBrand" valuePropName="checked" initialValue={propsValues[5].enabledField === "Y" ? true : false}>
                  <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label="isMandatory" name="productBrandisMandatory" valuePropName="checked" initialValue={propsValues[5].isMandatory === "Y" ? true : false}>
                      <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>
              </Row>
              <br />
          </Card>
          </Col>
          <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
              <Row>
                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item  label ="Image URL" name="productImageUrl" valuePropName="checked" initialValue={propsValues[6].enabledField === "Y" ? true : false}>
                  <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label="isMandatory" name="productImageUrlisMandatory" valuePropName="checked" initialValue={propsValues[6].isMandatory === "Y" ? true : false}>
                      <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>
              </Row>
              <br />
          </Card>
          </Col>
          <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
              <Row>
                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label ="Tax Category" name="productTaxCategory" valuePropName="checked" initialValue={propsValues[7].enabledField === "Y" ? true : false}>
                  <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label="isMandatory" name="productTaxCategoryisMandatory" valuePropName="checked" initialValue={propsValues[7].isMandatory === "Y" ? true : false}>
                      <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>
              </Row>
              <br />
          </Card>
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
              <Row>
                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item  label ="HSN Code" name="productHsnCode" valuePropName="checked" initialValue={propsValues[8].enabledField === "Y" ? true : false}>
                  <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label="isMandatory" name="productHsnCodeisMandatory" valuePropName="checked" initialValue={propsValues[8].isMandatory === "Y" ? true : false}>
                      <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>
              </Row>
              <br />
          </Card>
          </Col>
          <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
              <Row>
                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label ="Batch For Sale" name="productBatchForSale" valuePropName="checked" initialValue={propsValues[9].enabledField === "Y" ? true : false}>
                  <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label="isMandatory" name="productBatchForSaleisMandatory" valuePropName="checked" initialValue={propsValues[9].isMandatory === "Y" ? true : false}>
                      <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>
              </Row>
              <br />
          </Card>
          </Col>
          <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
              <Row>
                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label ="Batch For Stock" name="productBatchForStock" valuePropName="checked" initialValue={propsValues[10].enabledField === "Y" ? true : false}>
                  <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label="isMandatory" name="productBatchForStockisMandatory" valuePropName="checked" initialValue={propsValues[10].isMandatory === "Y" ? true : false}>
                      <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>
              </Row>
              <br />
          </Card>
          </Col>
          <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
              <Row>
                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label ="Shelf Life(In Months)" name="productShelfLife" valuePropName="checked" initialValue={propsValues[11].enabledField === "Y" ? true : false}>
                  <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label="isMandatory" name="productShelfLifeisMandatory" valuePropName="checked" initialValue={propsValues[11].isMandatory === "Y" ? true : false}>
                      <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>
              </Row>
              <br />
          </Card>
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
              <Row>
                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label ="Sale Price" name="productSalePrice" valuePropName="checked" initialValue={propsValues[12].enabledField === "Y" ? true : false}> 
                  <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label="isMandatory" name="productSalePriceisMandatory" valuePropName="checked" initialValue={propsValues[12].isMandatory === "Y" ? true : false}>
                      <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>
              </Row>
              <br />
          </Card>
          </Col>
          <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
              <Row>
                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label ="MRP" name="productMrp" valuePropName="checked" initialValue={propsValues[13].enabledField === "Y" ? true : false}>
                  <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label="isMandatory" name="productMrpisMandatory" valuePropName="checked" initialValue={propsValues[13].isMandatory === "Y" ? true : false}>
                      <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>
              </Row>
              <br />
          </Card>
          </Col>
          <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
              <Row>
                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label ="Supplier" name="productSupplier" valuePropName="checked" initialValue={propsValues[14].enabledField === "Y" ? true : false}>
                  <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label="isMandatory" name="productSupplierisMandatory" valuePropName="checked" initialValue={propsValues[14].isMandatory === "Y" ? true : false}>
                      <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>
              </Row>
              <br />
          </Card>
          </Col>
          <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
              <Row>
                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label ="Purchase Price" name="productPurchasePrice" valuePropName="checked" initialValue={propsValues[15].enabledField === "Y" ? true : false}>
                  <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={2} />
                  <Col className="gutter-row" span={10}>
                  <Form.Item label="isMandatory" name="productPurchasePriceisMandatory" valuePropName="checked" initialValue={propsValues[15].isMandatory === "Y" ? true : false}>
                      <Checkbox
                      style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                  </Form.Item>
                  </Col>
              </Row>
              <br />
          </Card>
          </Col>
        </Row>
        <br />
      </div>
      :null} 
    </div>
    
  );
};

export default Products;
