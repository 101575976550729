import React, { useEffect, useState } from "react";
import { Card, Menu, Dropdown, Form, Row, Col, Select, Button, InputNumber, Spin, message, Table, Image,Popover } from "antd";
import { getQualityTask } from "../../../services/custom";
import { useHistory } from "react-router";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { v4 as uuid } from "uuid";

import "antd/dist/antd.css";
import "../../../styles/app.css";

const { Option } = Select;


const PendingQualityTask = () => {
  const [loading,setLoading] = useState(false)
  const [taskData,setTaskData] = useState([])


  useEffect(() => {
    getData()
  }, [])

  const history = useHistory();

  const getData = async () =>{
    setLoading(true)
    const qualityTaskResponse = await getQualityTask()
    setTaskData(qualityTaskResponse !== null && qualityTaskResponse.length > 0 ? qualityTaskResponse :[])
    setLoading(false)
  }

  const selectRow = (record) => {
    return {
      onClick: (event) => {
        history.push(`/others/window/6666/${record.cWQInspectionTaskId}`);
      },
    };
  };

  const columns = [
    { title: "Task No", 
    dataIndex: "taskNo", 
    key: "taskNo", 
    width: 130, 
    },
    {
      title: "Business Unit",
      dataIndex: "SalesOrder",
      key: "SalesOrder",
      width: 130,
      render:(text,data) =>{
        return data.bUnit.name
      }
    },
    {
      title: "Product",
      dataIndex: "Customer_name",
      key: "Customer",
      width: 180,
      render:(text,data) =>{
        return data.product.name
      }
    },
    { title: "Batch",
     dataIndex: "batchNo",
    key: "batch1",
   width: 180,},
    { title: "Status", dataIndex: "status", key: "status", width: 220,},
  ];
 
  return (
     <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />} spinning={loading}>
      <div>
        <Row>
          <Col span={12}>
            <h2 style={{ fontWeight: "700", fontSize: "16px", color: "rgb(0 0 0 / 65%)", marginBottom: "8px", marginTop: "1%" }}>Pending QC Task</h2>
          </Col>
          <Col span={12} />
        </Row>
        <Table
          columns={columns}
          dataSource={taskData}
          size="small"
          scroll={{ y: "78.5vh", x: "100%"}}
          pagination={false}
          onRow={selectRow}
        />
      </div>
     </Spin>
  );
};

export default PendingQualityTask;
