import React, { useEffect, useState } from "react";
import { Card, Menu, Dropdown, Form, Row, Col, Select,Checkbox, Button,Input, InputNumber, Spin,DatePicker, message, Table, Image,Popover } from "antd";
import { getRoleBusinessUnit,getQualityTask } from "../../../services/custom";
import { LoadingOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";


import moment from "moment";
// import Axios from "axios";
// import { v4 as uuid } from "uuid";
// import { serverUrl, genericUrl } from "../../../constants/serverConfig";
// import DownArrow from "../../../assets/images/downArrow.svg";
// import Modal from "antd/lib/modal/Modal";

// import "antd/dist/antd.css";
// import "../../../styles/app.css";

const { Option } = Select;

const DetailsView = () => {
  const [bunitData, setBunitData] = useState([]);
  const [bUnitId, setBUnitId] = useState("");
  const [loading, setLoading] = useState(false);
  const [taskData,setTaskData] = useState([])
  const [headerData,setHeaderData] = useState([])
  const [headerform] = Form.useForm();
  const [parametersform] = Form.useForm();
  const {recordId} = useParams()
  console.log("useParams=============================>",recordId)

  
  const getBusinessUnit = async () => {
    const userData = JSON.parse(window.localStorage.getItem("userData"));
    const businessUnitResponse = await getRoleBusinessUnit(userData.user_id);
    headerform.setFieldsValue({
      businessUnit: businessUnitResponse.bUnitName,
    });
    setBunitData(businessUnitResponse.userBunit);

    setBUnitId(businessUnitResponse.defaultCsBunitId);
    localStorage.setItem('BUnitId', businessUnitResponse.defaultCsBunitId)
    // console.log(businessUnitResponse.defaultCsBunitId)
  };


  useEffect(() => {
    // getBusinessUnit();
    getData()
  }, []);

  const getData = async () =>{
    setLoading(true)
    const qualityTaskResponse = await getQualityTask()
    const Tdata = qualityTaskResponse !== null && qualityTaskResponse.length > 0 ? qualityTaskResponse :[]
    let newArray = []
    let newArray2 = []
    for (let index = 0; index < Tdata.length; index++) {
      const element = Tdata[index].cWQInspectionTaskId;
      if(element === recordId){
        newArray2.push(Tdata[index])
        newArray.push(Tdata[index].cWQInspectionTaskLine)
      }
    }
    setBUnitId(newArray2[0].bUnit.csBunitId)
    headerform.setFieldsValue({
      'businessUnit':newArray2[0].bUnit.name,
      'product':newArray2[0].product.name,
      'batch':newArray2[0].batchNo,
    })

    const lineDataToFormat = newArray[0]
    const newColumnGroups = []
    for (let index = 0; index < lineDataToFormat.length; index++) {
      const element = lineDataToFormat[index].refValues;
      if(element !== null){
        lineDataToFormat[index].newRefValues = lineDataToFormat[index].refValues. split(',')
      }
      newColumnGroups.push(lineDataToFormat[index].parameterGroupName)
    }
    const uniquenewColumnGroups = [...new Set(newColumnGroups)];

    const finalArray = []
    for (let index = 0; index < uniquenewColumnGroups.length; index++) {
      const element1 = uniquenewColumnGroups[index];
        for (let index2 = 0; index2 < lineDataToFormat.length; index2++) {
          const element2 = lineDataToFormat[index2].parameterGroupName;
            let newArr = []
            if(element1 === element2) {
              newArr.push(lineDataToFormat[index2])
              finalArray[element1] = newArr
                // console.log("element1==============================>",element1)
                // console.log("matched Data==========================>",lineDataToFormat[index2])
              
            }
            // console.log("newArr===============================>",newArr)

        }
    }
    // console.log("finalArray===============================>",finalArray)
    // console.log("newColumnGroups==========================>",newColumnGroups)

    // console.log("newArray[0]============================>",newArray[0])
    // console.log("uniquenewColumnGroups======================>",uniquenewColumnGroups)
    setHeaderData(newArray2)
    setTaskData(newArray[0])
    setLoading(false)
  }
  // console.log("newArray=======================>",taskData)


  const formItemLayout =
        {
          labelCol: {
            span: 8,
          },
          wrapperCol: {
            span: 4,
          },
        }
  
  const ProcessTask = () =>{
   const headerFormValues = headerform.getFieldsValue(true)
   const dyanamicFieldsValues = parametersform.getFieldsValue(true)
   console.log("headerFormValues==================>",headerFormValues)
   console.log("dyanamicFieldsValues===============>",dyanamicFieldsValues)
  }


  return (
    <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />} spinning={loading}>
      <div>
        <Row>
          <Col span={12}>
            <h2 style={{ fontWeight: "700", fontSize: "16px", color: "rgb(0 0 0 / 65%)", marginBottom: "0px", marginTop: "1%" }}>Pending QC Task</h2>
          </Col>
          <Col span={12}>
            <span style={{ float: "right" }}>
              <Button onClick = {ProcessTask} style={{ marginBottom: "8px", backgroundColor: "rgb(8 158 164)", color: "white", width: "93px", height: "33px" }}>
                Submit
              </Button>
            </span>
          </Col>
        </Row>
        <Card style={{ marginBottom: "8px" }}>
          <Form layout="vertical"  form={headerform} /* onFinish={onFinish} */>
            <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                <Form.Item
                  name="businessUnit"
                  label="Business unit"
                  style={{ marginBottom: "8px" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select business bunit!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onFocus={getBusinessUnit}
                    // onSelect={onSelectBusinessUnit}
                  >
                    {bunitData.map((data, index) => (
                      <Option key={data.csBunitId} value={data.csBunitId} title={data.bUnitName}>
                        {data.bUnitName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={6}>
                <Form.Item
                  name="product"
                  label="Product"
                  style={{ marginBottom: "8px" }}
                >
                   <Input />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={6}>
                <Form.Item name="batch" label="Batch" style={{ marginBottom: "8px" }}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <Row>
            <Col span={24}>
                <Card>
                    <h3 style={{fontWeight:'600'}}>Parameters</h3>
                    <Form layout="horizontal" {...formItemLayout} form={parametersform} /* onFinish={onFinish} */>
                    <Row>
                        <Col span={6}>Parameter Group 1</Col>
                        <Col style={{textAlign:'center'}} span={8}>Inspected Value</Col>
                    </Row>
                    <br />
                    {taskData.map((element) => {
                      {
                        switch (element.type) {
                          case "TX":
                          return (
                            <span>
                              <Form.Item label={element.parameterName} name={element.cWQInspectionTaskLineId} initialValue={element.value}>
                                <Input />
                              </Form.Item>
                              <br />
                              </span>
                          );
                          case "NM":
                          return (
                              <span>
                                <Form.Item initialValue={element.value} label={element.parameterName} name={element.cWQInspectionTaskLineId} >
                                  <InputNumber  style={{ width: "100%", marginBottom: "0px" }}/>
                                </Form.Item>
                                <br />
                              </span>
                                
                            );
                            case "LV":
                          return (
                            <span>
                              <Form.Item initialValue={element.value} label={element.parameterName} name={element.cWQInspectionTaskLineId}>
                                <Select maxTagCount="responsive" showSearch style={{ width: "100%", marginBottom: "0px" }}>
                                   {element.newRefValues.map((option, index) => (
                                    <Option key={`${index}-${element.cWQInspectionTaskLineId}`} value={option}>
                                      {option}
                                    </Option>
                                  ))} 
                                </Select>
                              </Form.Item>
                              <br />
                              </span>
                          );
                          case "DT":
                          return (
                            <span>
                              <Form.Item initialValue={element? moment(element.value) : null} /* initialValue={element? moment(element.value) : null} */ label={element.parameterName} name={element.cWQInspectionTaskLineId}>
                                <DatePicker  style={{width:'100%'}} format={"DD-MM-YYYY"} />
                              </Form.Item>
                            <br />
                            </span>
                          );
                          case "BO":
                          return (
                            <span>
                              <Form.Item valuePropName="checked"  initialValue={element.value === "false" ? false:true} label={element.parameterName} name={element.cWQInspectionTaskLineId}>
                                <Checkbox />
                              </Form.Item>
                            <br />
                            </span>
                          );
                          case "IM":
                          return (
                            <span>
                              <Form.Item  /* initialValue={} */ label={element.parameterName} name={element.cWQInspectionTaskLineId}>
                                <h2>Image</h2>
                              </Form.Item>
                            <br />
                            </span>
                          );
                          case "RB":
                          return (
                            <span>
                              <Form.Item  /* initialValue={} */ label={element.parameterName} name={element.cWQInspectionTaskLineId}>
                                <h2>Radio</h2>
                              </Form.Item>
                            <br />
                            </span>
                          );
                        }
                      }
                    })}
                    </Form> 
                </Card>
            </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default DetailsView;
