import React, { useState, Fragment } from "react";
import RoleLine from "./RoleLine";

const ListWindow = () => {
  return (
    <Fragment>
      <RoleLine />
    </Fragment>
  );
};

export default ListWindow;
