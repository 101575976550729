import React, { useState, useEffect } from "react";
import { Row, Col, Tabs, Card, Form, Table, message } from "antd";

import { useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

import {
  getPjDetailsData,
  getCustomerInfoTabData,
  getKycData,
  getTermsConditionsData,
  getRegulatoryData,
  getStockMixData,
  getOutletData,
  getDeleteData,
  getDeleteStockMixData,
  getDeleteTermsConditionsData,
  getDeleteOuttLetData,
  getDeleteKYCData,
} from "../../../services/custom";

import "antd/dist/antd.css";
import "../../../styles/antd.css";

const { TabPane } = Tabs;

const PJCustom = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);

  const [dataSource, setDataSource] = useState([]);

  const [visible, setVisible] = useState(true);
  const [recordToDelete, setRecordToDelete] = useState(1);
  const [recordToDeleteInForm, setRecordToDeleteInForm] = useState([]);
  const [customerInfoHeder, setcustomerInfoHeder] = useState();
  const [customerInfoTabHeder, setCustomerInfoTabHeder] = useState([]);
  const [termsAndConditiontab, setTermsAndConditionTab] = useState([]);
  const [RegulatoryTabHeader, setRegulatoryTabHeader] = useState([]);
  const [kycTabHeader, setKycTabHeader] = useState([]);
  const [stockMixHeader, setStockMixHeader] = useState([]);
  const [outletHeader, setOutletHeader] = useState([]);

  const [mainForm] = Form.useForm();

  const columnsdata = [
    {
      title: "Business Unit",
      dataIndex: "bUnitName",
    },
    {
      title: "PJ Code",
      dataIndex: "pjCode",
    },
    {
      title: "PJ Name",
      dataIndex: "pjName",
    },
    {
      title: "Customer Category",
      dataIndex: "customerCategory",
    },
    {
      title: "GST No",
      dataIndex: "gstNo",
    },
    {
      title: "Nick Name",
      dataIndex: "pjName",
    },
    {
      title: "PJ Group",
      dataIndex: "pjGroup",
    },
    {
      title: "Type",
      dataIndex: "pjtype",
    },
  ];



  const onFinishFailed = (errorInfo) => {
  
  };

  const getAllFormValuesTab = (event, values) => {
    console.log("event:", event);
    console.log("values:", values);
  };

  const onSelectChange = (e) => {
    setRecordToDelete(e);
  };

  const rowSelection = {
    onChange: onSelectChange,
  };

  useEffect(() => {
    getData();
    gettabData();
  }, []);

  const gettabData = async () => {
    const pjMasterId = localStorage.getItem("pjMasterId");
    const kycTabDataresponse = await getKycData(pjMasterId);
    const termsAndCondition = await getTermsConditionsData(pjMasterId);
    const Regulatoryresponse = await getRegulatoryData(pjMasterId);
    const StockMixDataresponse = await getStockMixData(pjMasterId);
    const outletHederResponse = await getOutletData(pjMasterId);

    setOutletHeader(outletHederResponse);
    

    setStockMixHeader(StockMixDataresponse);
  
    setTermsAndConditionTab(termsAndCondition);
  
    setRegulatoryTabHeader(Regulatoryresponse);
    
    setKycTabHeader(kycTabDataresponse);
 
  };

  const getData = async () => {
    setLoading(true);
    const pjMasterId = localStorage.getItem("pjMasterId");
    const getDataResponse = await getPjDetailsData();
    const customerInfoTabData = await getCustomerInfoTabData(pjMasterId);
    if (customerInfoTabData !== undefined) {
      if (customerInfoTabData.length > 0 || customerInfoTabData !== null) {
        setcustomerInfoHeder(customerInfoTabData);
        // console.log("table",getDataResponse)

        // mainForm.setFieldsValue({
        //   bUnitName : customerInfoTabData[0].bUnitName,
        //   pjcode: customerInfoTabData[0].pjCode,
        //   pjname:customerInfoTabData[0].pjName,
        //   customercategory:customerInfoTabData[0].customerCategory.name,
        //   gstno:customerInfoTabData[0].gstNo,
        //   currency:customerInfoTabData[0].currency.isoCode,
        //    nickname:customerInfoTabData[0].pjName,
        //   invoicingname:customerInfoTabData[0].currency.isoCode,
        //   invoicingaddress:customerInfoTabData[0].invoicingAddress,
        //   CSAlimit:customerInfoTabData[0].csaLimit,
        //   ASSLimit:customerInfoTabData[0].asslimit,
        //   ASSStartDate:moment(customerInfoTabData[0].assStartDate).format("YYYY-MM-DD"),
        //   ASSEndDate:moment(customerInfoTabData[0].assEndDate).format("YYYY-MM-DD"),
        //   totalconsignmentStock:customerInfoTabData[0].totalConsignmentStock,
        //   outrightstock:customerInfoTabData[0].outRightStock,
        //   TotalStock:customerInfoTabData[0].totalStock,
        //   paymentterms:customerInfoTabData[0].paymentTerms.name,
        //   customerInfopjtype:customerInfoTabData[0].pjtype,
        //   customerinfopjgroup:customerInfoTabData[0].pjGroup,
        //   customerInfoPJClosureDate:moment(customerInfoTabData[0].pjClosureDate).format("YYYY-MM-DD"),
        //   customerInfoPJOnboardingDate:moment(customerInfoTabData[0].pjOnboardingDate).format("YYYY-MM-DD"),
        //   customerInfoownername:customerInfoTabData[0].pjName,
        //   customerinfocity:customerInfoTabData[0].city,
        //   customerInfostate:customerInfoTabData[0].region.name,
        //   customerInfozone:customerInfoTabData[0].zone,
        //   customerInfoEmail:customerInfoTabData[0].email,
        //   customerinfomobileno:customerInfoTabData[0].mobileNo,
        //   customerInfoCountry:customerInfoTabData[0].country.name,
        //   customerInfopincode:customerInfoTabData[0].pincode,
        //   customerinfoWebSiteaddress:customerInfoTabData[0].websiteAddress,
        //   customerInfosolitairejewellery:customerInfoTabData[0].solitaireJewellery,
        //   customerinfosmalldiamondjewellery:customerInfoTabData[0].smallDiamondJewellery,
        //   customerInfoGoldJewellery:customerInfoTabData[0].goldJewellery,
        //   customerInfoluxurylifestyleitems:customerInfoTabData[0].luxuryLifestyle,
        //   customerInfoOthers:customerInfoTabData[0].others,
        //   customerInfoRegisteredWithDS:customerInfoTabData[0].registeredWithDs,
        //   customerInfoUnRegisteredWithDS:customerInfoTabData[0].unregisteredWithDs,

        // })
      }
    }

    if (getDataResponse) {
      for (let index = 0; index < getDataResponse.length; index++) {
        getDataResponse[index].key = getDataResponse[index].pjMasterId;
        getDataResponse[index].customerCategory = getDataResponse[index].customerCategory.name;
      }

      setDataSource([...getDataResponse]);

      setLoading(false);
    }
  };



 

  return (
    <div>
      <Row>
        <Col span={12}>
          <h2>PJ Custom</h2>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card>
            <Form layout="vertical" form={mainForm} name="control-pj" onValuesChange={getAllFormValuesTab} onFinishFailed={onFinishFailed}>
              <div>
                <Table
                  size="small"
                  columns={columnsdata}
                  dataSource={dataSource}
                  rowSelection={rowSelection}
                  pagination={false}
                  loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined className="spinLoader" style={{ fontSize: "52px" }} spin />,
                  }}
                  onRow={(row) => ({
                    onClick: () => {
                      localStorage.setItem("pjMasterId", row["pjMasterId"]);
                      history.push(`/others/window/PJCustomerDetails`);
                    },
                  })}
                />
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PJCustom;
