import React, { useEffect, useState } from "react";
import { Row, Col, Select, Spin, Table } from "antd";
import { getQualityReview } from "../../../services/custom";
import { LoadingOutlined } from "@ant-design/icons";

import "antd/dist/antd.css";
import "../../../styles/app.css";


const QualityReview = () => {
const [loading,setLoading] = useState(false)
const [data,setData] = useState([])

  useEffect(() => {
    getData()
  }, [])

  const getData = async () =>{
    setLoading(true)
    const userData = window.localStorage.getItem('userData')
    const parsedUserData = JSON.parse(userData)
    const qualityReview = await getQualityReview(parsedUserData.user_id)
    setData(qualityReview.length > 0 ? qualityReview :[])
    setLoading(false)
  }

  const columns = [
    {
      title: "Business Unit",
      dataIndex: "SalesOrder",
      key: "SalesOrder",
      width: 130,
      render:(text,data) =>{
        return data.bUnit.name
      }
    },
    {
      title: "Product",
      dataIndex: "Customer_name",
      key: "Customer",
      width: 180,
      render:(text,data) =>{
        return data.product.name
      }
    },
    { title: "Batch", dataIndex: "batchNo", key: "batchNo", width: 180,},
    { title: "Status", dataIndex: "status", key: "status", width: 220,},
    { title: "Date", dataIndex: "date", key: "date", width: 130 }
  ];
 
  return (
    <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />} spinning={loading}>
      <div>
        <Row>
          <Col span={12}>
            <h2 style={{ fontWeight: "700", fontSize: "16px", color: "rgb(0 0 0 / 65%)", marginBottom: "8px", marginTop: "1%" }}>Quality Review</h2>
          </Col>
          <Col span={12} />
        </Row>
        <Table
          columns={columns}
          dataSource={data}
          size="small"
          scroll={{ y: "64.5vh", x: "100%"}}
          pagination={false}
        />
      </div>
     </Spin>
  );
};

export default QualityReview;
