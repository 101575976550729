import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Row, Col, Card, Form, Checkbox, Image, Spin, Button, Input, Tabs, message, Select, Modal, Upload } from "antd";
import { useGlobalContext } from "../../../lib/storage";
import { getPjDetailsData } from "../../../services/custom";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { LoadingOutlined, EditOutlined,  UploadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import { serverUrl } from "../../../constants/serverConfig";
import Axios from "axios";
import {
  getCustomerInfoTabData,
  getKycData,
  getTermsConditionsData,
  getRegulatoryData,
  getStockMixData,
  getOutletData,
  getDeleteData,
  getDeleteStockMixData,
  getDeleteTermsConditionsData,
  getDeleteOuttLetData,
  getDeleteKYCData,
  // getupsetCustomerInfoData,
  getroleBunit,
} from "../../../services/custom";
import {
  getCustomerCategoryTypeData,
  getCurrencyDropDownData,
  getPaymentTermsDropDownData,
  getStateDropDownData,
  getCountryDropDownData,
  getkycStateDropDownData,
  getSalesRepDropDown,
  getkycCountryDropDownData,
  getCreditPeriodDropDown,
  getStateOutletDropDown,
  getInvoiceFormateDropDown,
} from "../../../services/generic";

const { TabPane } = Tabs;
const { Option } = Select;

const CustomWindowHeader = () => {
  const history = useHistory();
  const { globalStore } = useGlobalContext();
  const Themes = globalStore.userData.CW360_V2_UI;
  const [headerFormData, setHeaderFormData] = useState();

  const [loading, setLoading] = useState(false);

  const [customerInfoHeder, setcustomerInfoHeder] = useState();
  const [outletValue, setoutletValue] = useState([]);
  const [dvnclass, setdvnclass] = useState([]);
  const [dvnbankregulatory, setDVNBankRegulatory] = useState([]);
  const [dvnAddress, setdvnAddress] = useState([]);
  const [dvnCustomerKyc, setdvnCustomerKyc] = useState([]);
  const [kycTabHeader, setKycTabHeader] = useState([]);
  const [termsAndConditiontab, setTermsAndConditionTab] = useState([]);
  const [RegulatoryTabHeader, setRegulatoryTabHeader] = useState([]);
  const [stockMixHeader, setStockMixHeader] = useState([]);
  const [outletHeader, setOutletHeader] = useState([]);
  const [displayInput, setdisplayInput] = useState("block");
  const [displayInputTerms, setDisplayInputTerms] = useState("block");
  const [displayInputCustomerInfo, setDisplayInputCustomrInfo] = useState("block");
  const [displayInputRegulatory, setDisplayInputRegulatory] = useState("block");
  const [displayInputStockMix, setDisplayInputStockMix] = useState("block");
  const [displayInputOutlet, setDisplayInputOutlet] = useState("block");

 

  const pjMasterId = localStorage.getItem("pjMasterId");

  const [bUnitId, setBUnitId] = useState("");
  const [bunitData, setBunitData] = useState([]);
  const [customerCategoryData, setCustomerCategoryData] = useState([]);
  const [customerCategoryId, setCustomerCategoryId] = useState("");
  const [CurrencyData, setCurrencyData] = useState([]);
  const [currencyId, setCurrencyId] = useState("");
  const [paymentData, setPaymentData] = useState([]);
  const [paymentId, setPaymentId] = useState("");
  const [stateData, setStateData] = useState([]);
  const [stateId, setstateId] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [countryId, setCountryId] = useState("");
  const [stateKycData, setStateKYCData] = useState([]);
  const [stateKycId, setStateKYCId] = useState("");
  const [salesRepData, setSalesRepData] = useState([]);
  const [salesRepId, setsalesRepId] = useState("");
  const [countryKYCData, setCountryKYCData] = useState([]);
  const [countryKYCId, setCountryKYCId] = useState("");
  const [creditData, setCreditDataData] = useState([]);
  const [creditId, setCreditId] = useState("");
  const [stateOutletData, setStateOutlet] = useState([]);
  const [stateOutletId, setStateOutletId] = useState("");
  const [invoiceFormateData, setInvoiceFormateDate] = useState([]);

  const [isInvoicing, setISInvoicing] = useState(false);
  const [isTDSApplication, setIsTDSApplication] = useState(false);

  const [upsetservicescustomer, setUpsetServicesCustomer] = useState([]);

  const [activeStockMix, setActiveStockMix] = useState(null);

  const [visibleForKYCEdit, setVisibleForKYCEdit] = useState(false);
  const [visibleForStockEdit, setVisibleForStockEdit] = useState(false);
  const [visibleForRegulatoryEdit, setVisibleForRegulatoryEdit] = useState(false);
  const [visibleForTermsAndConditionEdit, setvisibleForTermsAndConditionEdit] = useState(false);
  const [visibleForOutletEdit, setvisibleForOutletEdit] = useState(false);
  const [visibleForCustomerInfoEdit, setVisibleForCustomerInfoEdit] = useState(false);
  const [dvnCustomerId, setDvnCustomerId] = useState(null);

  
  const [pjmasteridstockmix, setPjmasteridstockmix] = useState(null);

  const [pjmasterCustomerInfo, setpjmasterCustomerInfo] = useState(null);
  const [dOtherClassificationId, setDOtherClassificationId] = useState(null);
  const [cBankDetailsId, setCBankDetailsId] = useState(null);
  const [dvnCustomerAddressId, setDvnCustomerAddressId] = useState(null);
  const [customerContactId, setcustomerContactId] = useState(null);
  const [istdsAPPlication, setIstdsAPPlication] = useState(false);
  const [isGSTApplicable, setIsGSTApplicable] = useState(false);
  const [isIncentivePromptPayment, setIncentivePromptPayment] = useState(false);
  const [isJewellerLevelPromptPayment, setJewellerLevelPromptPayment] = useState(false);
  const [isAgreementSign, setIsAgreementSign] = useState(false);
  const [iskycoutlet, setiskycoutlet] = useState(false);
  const [isdisplayDone, setIsdisplayDone] = useState(false);
  const [isInKYCKYC, setIsInKYCKYC] = useState(false);
  const [isInsorInKyc, setIsInsorInKyc] = useState(false);

 
  const [form] = Form.useForm();
  const [mainForm] = Form.useForm();
  const [CustomerInfoForm] = Form.useForm();
  const [kycForm] = Form.useForm();
  const [stockmixForm] = Form.useForm();
  const [regulatoryForm] = Form.useForm();
  const [termsAndConditionForm] = Form.useForm();
  const [outletForm] = Form.useForm();
  
 

  useEffect(async () => {
    if (pjMasterId === "undefined") {
      setdisplayInput("none");
      setDisplayInputTerms("none");
    } else {
      gettabAndTableData();
      getData();
      gettabKYCToOutletData();
    }

    gettabAndTableData();
    getData();
    gettabKYCToOutletData();

    const userData = JSON.parse(window.localStorage.getItem("userData"));
    const businessUnitResponse = await getroleBunit(userData.user_id);
    setBunitData(businessUnitResponse.userBunit);
  
    const CustomerCategoryResponse = await getCustomerCategoryTypeData();
    setCustomerCategoryData(CustomerCategoryResponse.searchData);
 
    const CurrencyDataResponse = await getCurrencyDropDownData();
    setCurrencyData(CurrencyDataResponse.searchData);
    

    const paymentDataResponse = await getPaymentTermsDropDownData();
    setPaymentData(paymentDataResponse.searchData);
    
    const stateDataResponse = await getStateDropDownData();
    setStateData(stateDataResponse.searchData);
  
    const countryDataResponse = await getCountryDropDownData();
    setCountryData(countryDataResponse.searchData);
  
    const InvoiceFormateDropDownresponse = await getInvoiceFormateDropDown();
    setInvoiceFormateDate(InvoiceFormateDropDownresponse.searchData);

    const stateDatakycResponse = await getkycStateDropDownData();
    setStateKYCData(stateDatakycResponse.searchData);
  

    const countryKycDataResponse = await getkycCountryDropDownData();
    setCountryKYCData(countryKycDataResponse.searchData);
   

    const salesRepDataResponse = await getSalesRepDropDown();

    setSalesRepData(salesRepDataResponse.searchData);
  }, []);

  const getData = async () => {
    setLoading(true);
    const pjMasterId = localStorage.getItem("pjMasterId");

    const getDataResponse = await getPjDetailsData(pjMasterId);
    if (getDataResponse !== undefined) {
      if (getDataResponse.length > 0 || getDataResponse !== null) {
         setHeaderFormData(getDataResponse);
        setoutletValue(getDataResponse[0].dvnContact);
        setdvnclass(getDataResponse[0].dvnclassification);
        setDVNBankRegulatory(getDataResponse[0].dvnBankDetails);
        setdvnAddress(getDataResponse[0].dvncustAddress);
        setdvnCustomerKyc(getDataResponse[0].dvnCustomer);


        for (let index = 0; index < getDataResponse.length; index++) {
          getDataResponse[index].key = getDataResponse[index].pjMasterId;
        }
      }
    }

    setLoading(false);
  };
  const gettabKYCToOutletData = async () => {
    setLoading(true);
    const pjMasterId = localStorage.getItem("pjMasterId");
    const kycTabDataresponse = await getKycData(pjMasterId);
    const termsAndCondition = await getTermsConditionsData(pjMasterId);
    const Regulatoryresponse = await getRegulatoryData(pjMasterId);
    const StockMixDataresponse = await getStockMixData(pjMasterId);
    const outletHederResponse = await getOutletData(pjMasterId);
    setLoading(false);
    for (let i = 0; i < kycTabDataresponse.length; i++) {
      kycTabDataresponse[i].kycflag = "N";
    }
    setKycTabHeader(kycTabDataresponse);
    console.log("kyctab", kycTabDataresponse);
    
    for (let i = 0; i < StockMixDataresponse.length; i++) {
      StockMixDataresponse[i].flag = "N";
    }
    setTermsAndConditionTab(termsAndCondition);
    for (let i = 0; i < termsAndCondition.length; i++) {
      termsAndCondition[i].teamflag = "N";
    }
    console.log("termsAndConditiontabpjDeteails", termsAndConditiontab);
    for (let i = 0; i < Regulatoryresponse.length; i++) {
      Regulatoryresponse[i].regulatoryflag = "N";
    }
    setRegulatoryTabHeader(Regulatoryresponse);
    console.log("Regulatoryresponse", Regulatoryresponse);
    setStockMixHeader(StockMixDataresponse);
    console.log("StockMixDataresponsePJdetails", StockMixDataresponse);
    for (let i = 0; i < outletHederResponse.length; i++) {
      outletHederResponse[i].outletflag = "N";
    }
    setOutletHeader(outletHederResponse);
    console.log("outletHederResponse", outletHederResponse);
    setLoading(false);
  };
 
  const onChangeCheckBOx = (e) => {
    setISInvoicing(e.target.checked);
    setIsTDSApplication(e.target.checked);
    console.log("e", e);
  };

  const gettabAndTableData = async () => {
    setLoading(true);
    const pjMasterId = localStorage.getItem("pjMasterId");

    const customerInfoTabData = await getCustomerInfoTabData(pjMasterId);
    console.log("customerInfoTabDatadetails==>", customerInfoTabData);
 

    if (customerInfoTabData !== undefined) {
      if (customerInfoTabData.length > 0 || customerInfoTabData !== null) {
     
        setcustomerInfoHeder(customerInfoTabData);

        mainForm.setFieldsValue({
          bUnitName: customerInfoTabData[0]["csBunitId"],
          pjcode: customerInfoTabData[0].pjCode,
          pjName: customerInfoTabData[0].pjName,
          customercategory: customerInfoTabData[0].customerCategory["sCustomerCategoryId"],
          gstno: customerInfoTabData[0].gstNo,
          currency: customerInfoTabData[0].currency["csCurrencyId"],
          nickname: customerInfoTabData[0].pjName,
          invoicingname: customerInfoTabData[0].currency.isoCode,
          invoicingaddress: customerInfoTabData[0].invoicingAddress,
          CSAlimit: customerInfoTabData[0].csaLimit,
          ASSLimit: customerInfoTabData[0].asslimit,
          ASSStartDate: moment(customerInfoTabData[0].assStartDate).format("YYYY-MM-DD"),
          ASSEndDate: moment(customerInfoTabData[0].assEndDate).format("YYYY-MM-DD"),
          totalconsignmentStock: customerInfoTabData[0].totalConsignmentStock,
          outrightstock: customerInfoTabData[0].outRightStock,
          TotalStock: customerInfoTabData[0].totalStock,
          paymentterms: customerInfoTabData[0].paymentTerms["csPaymenttermID"],
          customerInfopjtype: customerInfoTabData[0].pjtype,
          customerinfopjgroup: customerInfoTabData[0].pjGroup,
          customerInfoPJClosureDate: moment(customerInfoTabData[0].pjClosureDate).format("YYYY-MM-DD"),
          customerInfoPJOnboardingDate: moment(customerInfoTabData[0].pjOnboardingDate).format("YYYY-MM-DD"),
          customerInfoownername: customerInfoTabData[0].pjName,
          customerinfocity: customerInfoTabData[0].city,
          customerInfostate: customerInfoTabData[0].region["csRegionID"],
          customerInfozone: customerInfoTabData[0].zone,
          customerInfoEmail: customerInfoTabData[0].email,
          customerinfomobileno: customerInfoTabData[0].mobileNo,
          customerInfoCountry: customerInfoTabData[0].country["csCountryID"],
          customerInfopincode: customerInfoTabData[0].pincode,
          customerinfoWebSiteaddress: customerInfoTabData[0].websiteAddress,
          customerInfosolitairejewellery: customerInfoTabData[0].solitaireJewellery,
          customerinfosmalldiamondjewellery: customerInfoTabData[0].smallDiamondJewellery,
          customerInfoGoldJewellery: customerInfoTabData[0].goldJewellery,
          customerInfoluxurylifestyleitems: customerInfoTabData[0].luxuryLifestyle,
          customerInfoOthers: customerInfoTabData[0].others,
          customerInfoRegisteredWithDS: customerInfoTabData[0].registeredWithDs,
          customerInfoUnRegisteredWithDS: customerInfoTabData[0].unregisteredWithDs,
          invoiceFormate: customerInfoTabData[0].csDocType["csDoctypeId"],
        });
      }
    }

    setISInvoicing(customerInfoHeder !== undefined ? (customerInfoHeder[0].isInvoicing === "Y" ? true : false) : "");
    setLoading(false);
  };


  const editFieldsInFrom = (data) => {
    console.log(data.country.name);
    console.log(data.region.name);
    kycForm.setFieldsValue({
      KycpjName: data.pjName,
      kycNickName: data.nickName,
      kycOnboarding_Date: data.pjOnboardingDate === undefined || data.pjOnboardingDate === null ? null : moment(data.pjOnboardingDate).format("YYYY-MM-DD"),
      Kycpj_ClosureDate: moment(data.pjClosureDate).format("YYYY-MM-DD"),
      Kycpj_Address: data.pjAddress,
      kyc_city: data.city,
      kyc_state: data.region["csRegionID"],
      kyc_zone: data.zone,
      Country_Kyc: data.country["csCountryID"],
      kycpin_code: data.pincode,
      kyc_pjsalesRep: data.salesRep["salesRepresentId"],
      kycpj_SalesRepStartDate: data.pjSalesRepStartDate === undefined || data.pjSalesRepStartDate === null ? null : moment(data.pjSalesRepStartDate).format("YYYY-MM-DD"),
      kycpjSalesRepEndDate: moment(data.pjSalesRepEndDate).format("YYYY-MM-DD"),
      kycpjOwnerName: data.pjName,
      kycmobileNo: data.mobileNo,
      kycemail: data.email,
      kyc_Birth_Date: data.birthDate === undefined || data.birthDate === null ? null : moment(data.birthDate).format("YYYY-MM-DD"),
      kyc_Anniversary_Date: data.anniversaryDate === undefined || data.anniversaryDate === null ? null : moment(data.anniversaryDate).format("YYYY-MM-DD"),
      Kyc_Address: data.pjAddress,
      KycOwnerPic: data.ownerPic,
      date_of_Establishement: data.dateofEstablishement === undefined || data.dateofEstablishement === null ? null : moment(data.dateofEstablishement).format("YYYY-MM-DD"),
      kyccompmany_Logo: data.compmanyLogo,
    });
    setVisibleForKYCEdit(true);
    setIsInKYCKYC(data.kyc === "Y" ? true : false);
    setDvnCustomerId(data.dvnCustomerId);
    setIsInsorInKyc(data.sor === "Y" ? true : false);
  };

  const handleCancelAllModal = () => {
    setVisibleForCustomerInfoEdit(false);
    setVisibleForKYCEdit(false);
    setVisibleForStockEdit(false);
    setVisibleForRegulatoryEdit(false);
    setvisibleForTermsAndConditionEdit(false);
    setvisibleForOutletEdit(false);
    setvisibleForOutletEdit(false);
  };
  
  const editFieldsInCustomeTabNew = async () => {
    const customerInfoTabData = await getCustomerInfoTabData(pjMasterId);
    if (customerInfoTabData.length > 0 || customerInfoTabData !== null) {
     
      setcustomerInfoHeder(customerInfoTabData);
     
      CustomerInfoForm.setFieldsValue({
        
        bUnitName: customerInfoTabData[0]["csBunitId"],
        pjcode: customerInfoTabData[0].pjCode,
        pjName: customerInfoTabData[0].pjName,
        customercategory: customerInfoTabData[0].customerCategory["sCustomerCategoryId"],
        gstno: customerInfoTabData[0].gstNo,
        currency: customerInfoTabData[0].currency["csCurrencyId"],
        nickname: customerInfoTabData[0].pjName,
        invoicingname: customerInfoTabData[0].currency.isoCode,
        invoicingaddress: customerInfoTabData[0].invoicingAddress,
        CSAlimit: customerInfoTabData[0].csaLimit,
        ASSLimit: customerInfoTabData[0].asslimit,
        ASSStartDate: moment(customerInfoTabData[0].assStartDate).format("YYYY-MM-DD"),
        ASSEndDate: moment(customerInfoTabData[0].assEndDate).format("YYYY-MM-DD"),
        customertotalconsignmentStock: customerInfoTabData[0].totalConsignmentStock,
        customeroutrightstock: customerInfoTabData[0].outRightStock,
        customerInfoTotalStock: customerInfoTabData[0].totalStock,
        paymentterms: customerInfoTabData[0].paymentTerms["csPaymenttermID"],
        customerInfopjtype: customerInfoTabData[0].pjtype,
        customerinfopjgroup: customerInfoTabData[0].pjGroup,
        customerInfoPJClosureDate: moment(customerInfoTabData[0].pjClosureDate).format("YYYY-MM-DD"),
        customerInfoPJOnboardingDate: moment(customerInfoTabData[0].pjOnboardingDate).format("YYYY-MM-DD"),
        customerInfoownername: customerInfoTabData[0].pjName,
        customerinfocity: customerInfoTabData[0].city,
        customerInfostate: customerInfoTabData[0].region["csRegionID"],
        customerInfozone: customerInfoTabData[0].zone,
        customerInfoEmail: customerInfoTabData[0].email,
        customerinfomobileno: customerInfoTabData[0].mobileNo,
        customerInfoCountry: customerInfoTabData[0].country["csCountryID"],
        customerInfopincode: customerInfoTabData[0].pincode,
        customerinfoWebSiteaddress: customerInfoTabData[0].websiteAddress,
        customerInfosolitairejewellery: customerInfoTabData[0].solitaireJewellery,
        customerinfosmalldiamondjewellery: customerInfoTabData[0].smallDiamondJewellery,
        customerInfoGoldJewellery: customerInfoTabData[0].goldJewellery,
        customerInfoluxurylifestyleitems: customerInfoTabData[0].luxuryLifestyle,
        customerInfoOthers: customerInfoTabData[0].others,
        customerInfoRegisteredWithDS: customerInfoTabData[0].registeredWithDs,
        customerInfoUnRegisteredWithDS: customerInfoTabData[0].unregisteredWithDs,
        invoiceFormate: customerInfoTabData[0].csDocType["csDoctypeId"],
        customerInfoCompanyLogo: customerInfoTabData[0].companyLogo,
        customerInfownerPic: customerInfoTabData[0].ownerPic,
      });
     
    }
    setISInvoicing(customerInfoTabData !== undefined ? (customerInfoTabData[0].isInvoicing === "Y" ? true : false) : "");
    setVisibleForCustomerInfoEdit(true);
    setpjmasterCustomerInfo(customerInfoTabData !== undefined ? customerInfoTabData[0].pjMasterId : "");
  };
  const editFieldsInFromTerms = (data) => {
    termsAndConditionForm.setFieldsValue({
      margin: data.margin,
      depositCommited: data.depositCommited,
      creditPeriod: data.creditPeriod,
      Projection_JwellerWiseTarget: data.projectionJwellerWiseTarget,
      store_WiseTarget: data.storeWiseTarget,
      storeWise_PromptPayment: data.storeWisePromptPayment,
      agreement_Date: moment(data.agreementDate).format("YYYY-MM-DD"),
    });
    setDvnCustomerAddressId(data.dvnCustomerAddressId);
    setvisibleForTermsAndConditionEdit(true);
    setIsAgreementSign(data.agreementSign === "Y" ? true : false);
  };

  const editFieldsInFromRegulatory = (data) => {
    
    regulatoryForm.setFieldsValue({
      BankAccountNo: data.bankAccNumber,
      bank_Name: data.bankname,
      bra_nch: data.branch,
      ifsc_Code: data.ifscCode,
      Gst_No: data.gstNo,
      panNo: data.panNo,
    });
    setVisibleForRegulatoryEdit(true);
    setCBankDetailsId(data.cBankDetailsId);
    setIstdsAPPlication(data.tdsApplicable === "Y" ? true : false);
    setIsGSTApplicable(data.gstApplicable === "Y" ? true : false);
    setIncentivePromptPayment(data.incentivePromptPayment === "Y" ? true : false);
    setJewellerLevelPromptPayment(data.jwellerlevelPromtPayment === "Y" ? true : false);
  };

  const editFieldsInFromStockMix = (data) => {
    stockmixForm.setFieldsValue({
      small: data.small,
      medium: data.medium,
      Large: data.large,
      ExLarge: data.exLarge,
      DEFVVS: data.defVvs,
      DEFVS: data.defVs,
      DEFSI: data.defSi,
      GHVVS: data.ghVvs,
      GHVS: data.ghVs,
      GHSI: data.ghSi,
      IJKVVS: data.ijkVvs,
      IJKVS: data.ijkVs,
      IJKSI: data.ijksi,
      DSD: data.dsd,
      DSJ: data.dsj,
    });
    setVisibleForStockEdit(true);
    setDOtherClassificationId(data.dOtherClassificationId);
    setPjmasteridstockmix(data.pjMasterId);
  };

  const editFieldsInFromOutlet = (data) => {
   
    outletForm.setFieldsValue({
      outlet_Name: data.outletName,
      outlet_nickName: data.nickName,
      outlet_addressLine1: data.addressLine1,
      outlet_City: data.outletCity,
      outlet_pinCode: data.pinCode,
      stateNameoutlet: data.stateName,
      outlet_zone: data.zone,
      outlet_tier: data.tier,
      outlet_mobileNo: data.mobileNo,
      outlet_sarea: data.area,
      outlet_email: data.email,
      outlet_MarketName: data.outletName,
      outlet_storeContactPersonName: data.storeContactPersonName,
      outlet_storeContactPersonNo: data.storeContactPersonNo,
      outlet_weeklyOff: data.weeklyOff,
      outlet_gstNo: data.gstNo,
      outlet_OnboardingDate: moment(data.outletOnboardingDate).format("YYYY-MM-DD"),
      outlet_ClosureDate: moment(data.outletClosureDate).format("YYYY-MM-DD"),
      outlet_salesRep: moment(data.salesRepStartDate).format("YYYY-MM-DD"),
      outlet_salesRepStartDate: moment(data.salesRepStartDate).format("YYYY-MM-DD"),
      outlet_salesRepEndDate: moment(data.salesRepEndDate).format("YYYY-MM-DD"),
      pricelist_HandoverContact_PersonName: data.pricelistHandoverContactPersonName,
      pHandover_ContactPersonNo: data.pHandoverContactPersonNo,
      outlet_stockConfirmationContactName: data.stockConfirmationContactName,
      outlet_stockConfirmationContactNo: data.stockConfirmationContactNo,
      outlet_trialFromPeriod: moment(data.trialFromPeriod).format("YYYY-MM-DD"),
      outlet_trialToPeriod: moment(data.trialToPeriod).format("YYYY-MM-DD"),
      outlet_totalStock: data.totalStock,
      outlet_projectionJwellerWiseTarget: data.projectionJwellerWiseTarget,
      outlet_storeWiseTarget: data.storeWiseTarget,
      OutletPic_outlet: data.outletPic,
    });
    setiskycoutlet(data.kyc === "Y" ? true : false);
    setcustomerContactId(data.customerContactId);
    setIsdisplayDone(data.displayDone === "Y" ? true : false);
    setvisibleForOutletEdit(true);
  };

  const openaddAddressModal = () => {
    setVisibleForStockEdit(true);
    stockmixForm.resetFields()
  };

  const openaddAddressModalRegulatory = () => {
    setVisibleForRegulatoryEdit(true);
    regulatoryForm.resetFields()
  };

  const openaddAddressModalTeamsAndCondition = () => {
    setvisibleForTermsAndConditionEdit(true);
    termsAndConditionForm.resetFields()
  };
  const openaddAddressModaloutlet = () => {
    setvisibleForOutletEdit(true);
    outletForm.resetFields()
  };

  const openaddAddressModalkyc = () => {
    setVisibleForKYCEdit(true);
    kycForm.resetFields()
  };

  const openaddAddressModalCustomerInfo = () => {
    setVisibleForCustomerInfoEdit(true); 
    CustomerInfoForm.resetFields()
  };

  const onChangechecbox = (event) => {

    setIstdsAPPlication(event.target.checked);
    setiskycoutlet(event.target.checked);
    setIsdisplayDone(event.target.checked);
    setIsInKYCKYC(event.target.checked);
    setIsInsorInKyc(event.target.checked);
  };
  const OnGSTApplicable = (e) => {
    setIsGSTApplicable(e.target.checked);
  };

  const onIncentivePromptPayment = (e) => {
    setIncentivePromptPayment(e.target.checked);
  };
  const onJewellerLevelPromptPayment = (e) => {
    setJewellerLevelPromptPayment(e.target.checked);
  };

  const agreementSign = (e) => {
    setIsAgreementSign(e.target.checked);
  };

  const onFinish = async (values) => {
  
    // const upsetcustum = await getupsetCustomerInfoData(values);
    // setUpsetServicesCustomer(upsetcustum);
    // let uniqueId = uuid().replace(/-/g, "").toUpperCase();

  
  };

  const getFinish = (values) => {
    const newToken = JSON.parse(localStorage.getItem("authTokens"));
    const pjMasterId = localStorage.getItem("pjMasterId");
    form.validateFields().then((values) => {
      mainForm.submit();
    });
    const fieldValues = CustomerInfoForm.getFieldsValue();
   
    const uniqueId = uuid().replace(/-/g, "").toUpperCase();

    const customsaveButton = {
      query: `mutation {
        upsertPjMaster(dvnPjMasters: { dvnPjMaster: [{
            pjMasterId: "${pjmasterCustomerInfo === null || pjmasterCustomerInfo === undefined ? uniqueId : `${pjmasterCustomerInfo}`}"
            csBunitId: "${fieldValues.bUnitName}"
            pjCode: ${fieldValues.pjcode === null || fieldValues.pjcode === undefined ? null : `"${fieldValues.pjcode}"`}
            pjName: ${fieldValues.pjName === null || fieldValues.pjName === undefined ? null : `"${fieldValues.pjName}"`}
            gstNo: ${fieldValues.gstno === null || fieldValues.gstno === undefined ? null : `"${fieldValues.gstno}"`}
            customerCategoryId: ${fieldValues.customercategory === null || fieldValues.customercategory === undefined ? null : `"${fieldValues.customercategory}"`}
            cscurrencyId: "${fieldValues.currency}"
            isInvoicing: "${isInvoicing === true ? "Y" : "N"}"
            invoicingAddress: ${fieldValues.invoicingAddress === null || fieldValues.invoicingAddress === undefined ? null : `"${fieldValues.invoicingAddress}"`}
            pjGroup:  ${fieldValues.customerinfopjgroup === null || fieldValues.customerinfopjgroup === undefined ? null : `"${fieldValues.customerinfopjgroup}"`}
            csaLimit: ${fieldValues.CSAlimit === null || fieldValues.CSAlimit === undefined ? null : `${fieldValues.CSAlimit}`}
            asslimit: ${fieldValues.ASSLimit === null || fieldValues.ASSLimit === undefined ? null : `${fieldValues.ASSLimit}`}
            aStartDate: ${fieldValues.ASSStartDate === null || fieldValues.ASSStartDate === undefined ? null : `"${fieldValues.ASSStartDate}"`}
            aEndDate: ${fieldValues.ASSEndDate === null || fieldValues.ASSEndDate === undefined ? null : `"${fieldValues.ASSEndDate}"`}
            totalConsignmentStock: ${
              fieldValues.customertotalconsignmentStock === null || fieldValues.customertotalconsignmentStock === undefined ? null : `${fieldValues.customertotalconsignmentStock}`
            }
            outRightStock: ${fieldValues.customeroutrightstock === null || fieldValues.customeroutrightstock === undefined ? null : fieldValues.customeroutrightstock}
            totalStock: ${fieldValues.customerInfoTotalStock === null || fieldValues.customerInfoTotalStock === undefined ? null : `${fieldValues.customerInfoTotalStock}`} 
            csPaymentId:"${fieldValues.paymentterms}"
            websiteAddress:${
              fieldValues.customerinfoWebSiteaddress === null || fieldValues.customerinfoWebSiteaddress === undefined ? null : `"${fieldValues.customerinfoWebSiteaddress}"`
            }
            companyLogo: ${fieldValues.customerInfoCompanyLogo === null || fieldValues.customerInfoCompanyLogo === undefined ? null : `"${fieldValues.customerInfoCompanyLogo}"`}
            ownerPic: ${fieldValues.customerInfownerPic === null || fieldValues.customerInfownerPic === undefined ? null : `"${fieldValues.customerInfownerPic}"`}
            registeredWithDs: ${
              fieldValues.customerInfoRegisteredWithDS === null || fieldValues.customerInfoRegisteredWithDS === undefined ? null : `${fieldValues.customerInfoRegisteredWithDS}`
            } 
            unregisteredWithDs: ${
              fieldValues.customerInfoUnRegisteredWithDS === null || fieldValues.customerInfoUnRegisteredWithDS === undefined
                ? null
                : `${fieldValues.customerInfoUnRegisteredWithDS}`
            } 
            solitaireJewellery: ${
              fieldValues.customerInfosolitairejewellery === null || fieldValues.customerInfosolitairejewellery === undefined
                ? null
                : `${fieldValues.customerInfosolitairejewellery}`
            }
            smallDiamondJewellery:${
              fieldValues.customerinfosmalldiamondjewellery === null || fieldValues.customerinfosmalldiamondjewellery === undefined
                ? null
                : `${fieldValues.customerinfosmalldiamondjewellery}`
            } 
            goldJewellery: ${
              fieldValues.customerInfoGoldJewellery === null || fieldValues.customerInfoGoldJewellery === undefined ? null : `${fieldValues.customerInfoGoldJewellery}`
            }
            luxuryLifestyle:${
              fieldValues.customerInfoluxurylifestyleitems === null || fieldValues.customerInfoluxurylifestyleitems === undefined
                ? null
                : `${fieldValues.customerInfoluxurylifestyleitems}`
            }
            others: ${fieldValues.customerInfoOthers === null || fieldValues.customerInfoOthers === undefined ? null : `${fieldValues.customerInfoOthers}`}
            pOnboardingDate: ${
              fieldValues.customerInfoPJOnboardingDate === null || fieldValues.customerInfoPJOnboardingDate === undefined ? null : `"${fieldValues.customerInfoPJOnboardingDate}"`
            }
            pClosureDate:${
              fieldValues.customerInfoPJClosureDate === null || fieldValues.customerInfoPJClosureDate === undefined ? null : `"${fieldValues.customerInfoPJClosureDate}"`
            }
            pjtype: ${fieldValues.customerInfopjtype === null || fieldValues.customerInfopjtype === undefined ? null : `${fieldValues.customerInfopjtype}`}
            ownerName:"${fieldValues.customerInfoownername}"
            city: "${fieldValues.customerinfocity}"
            zone: "${fieldValues.customerInfozone}"
            csRegionId: "${fieldValues.customerInfostate}"
            mobileNo:${fieldValues.customerinfomobileno === null || fieldValues.customerinfomobileno === undefined ? null : `${fieldValues.customerinfomobileno}`}
            email: ${fieldValues.customerInfoEmail === null || fieldValues.customerInfoEmail === undefined ? null : `"${fieldValues.customerInfoEmail}"`}
            pincode: ${fieldValues.customerInfopincode === null || fieldValues.customerInfopincode === undefined ? null : `${fieldValues.customerInfopincode}`}
            csCountryId:"${fieldValues.customerInfoCountry}"
            docTypId:"${fieldValues.invoiceFormate}"
          }]
           })
          { 
          status
          message  
          recordsId 
          }
          }
       `,
    };
    console.log("customsaveButton", customsaveButton);
    Axios({
      url: serverUrl,
      method: "POST",
      data: customsaveButton,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      },
    }).then((response) => {
      console.log(response);
      const Status = response.data.data.upsertPjMaster.status;
    

      if (Status === "200") {
        const messageForSuccess = response.data.data.upsertPjMaster.message;
       
        message.success(messageForSuccess);
        setVisibleForCustomerInfoEdit(false);
        CustomerInfoForm.resetFields();
        setTimeout(() => {
          gettabAndTableData();
        }, 500);
        setDisplayInputCustomrInfo("block");
      } else {
        message.error("getting error while creating lines");
        setVisibleForCustomerInfoEdit(false);
        setLoading(false);
      }
    });
  };

  const getoutletUpset = (values) => {

    const newToken = JSON.parse(localStorage.getItem("authTokens"));
    form.validateFields().then((values) => {
      mainForm.submit();
    });
    const pjMasterId = localStorage.getItem("pjMasterId");
    const fieldValues = outletForm.getFieldsValue();
  
    const uniqueId = uuid().replace(/-/g, "").toUpperCase();
    const outletsaveButton = {
      query: `mutation {
        upsertDvnOutlet(dvnOutlet: { dvnCustomerOutlet: [{
          customerContactId:"${customerContactId === null || customerContactId === undefined ? uniqueId : customerContactId}"
          pjMasterId:  ${pjMasterId === null || pjMasterId === undefined ? null : `"${pjMasterId}"`}
          csBunitId: "0"
          outletName: ${fieldValues.outlet_Name === null || fieldValues.outlet_Name === undefined ? null : `"${fieldValues.outlet_Name}"`}
          nickName: ${fieldValues.outlet_nickName === null || fieldValues.outlet_nickName === undefined ? null : `"${fieldValues.outlet_nickName}"`}
          ownerName: "Abhushan"
          addressLine1: ${fieldValues.outlet_addressLine1 === null || fieldValues.outlet_addressLine1 === undefined ? null : `"${fieldValues.outlet_addressLine1}"`}
          addressLine2: "Hyderabad"
          outletCity: ${fieldValues.outlet_City === null || fieldValues.outlet_City === undefined ? null : `"${fieldValues.outlet_City}"`}
          state: "79A31DC318C145128AD78AACBC7F8363"
          outletCountry: "793E5D98D9C347B7A450B812625EF382"
          outletFullAddress: "57, 58 Budhwar Peth"
          pjSalesRepresentative: "2917B21F999345158CBE7FBEE833468F"
          salesStartDate: ${
            fieldValues.outlet_salesRepStartDate === null || fieldValues.outlet_salesRepStartDate === undefined ? null : `"${fieldValues.outlet_salesRepStartDate}"`
          }
          salesEndDate: ${fieldValues.outlet_salesRepEndDate === null || fieldValues.outlet_salesRepEndDate === undefined ? null : `"${fieldValues.outlet_salesRepEndDate}"`}
          birthdayDate: "2022-04-29"
          dateOfAnniversary: "2022-04-29"
          dateOfOutletOnboard: ${fieldValues.outlet_OnboardingDate === null || fieldValues.outlet_OnboardingDate === undefined ? null : `"${fieldValues.outlet_OnboardingDate}"`}
          dateOfClosureDate: ${fieldValues.outlet_ClosureDate === null || fieldValues.outlet_ClosureDate === undefined ? null : `"${fieldValues.outlet_ClosureDate}"`}
          tFromPeriod:${fieldValues.outlet_trialFromPeriod === null || fieldValues.outlet_trialFromPeriod === undefined ? null : `"${fieldValues.outlet_trialFromPeriod}"`}
          tToPeriod: ${fieldValues.outlet_trialToPeriod === null || fieldValues.outlet_trialToPeriod === undefined ? null : `"${fieldValues.outlet_trialToPeriod}"`}
          gstNo: ${fieldValues.outlet_gstNo === null || fieldValues.outlet_gstNo === undefined ? null : `"${fieldValues.outlet_gstNo}"`}
          mobileNo: ${fieldValues.outlet_mobileNo === null || fieldValues.outlet_mobileNo === undefined ? null : `${fieldValues.outlet_mobileNo}`}
          advertisement:"Y" 
          email:  ${fieldValues.outlet_email === null || fieldValues.outlet_email === undefined ? null : `"${fieldValues.outlet_email}"`}
          area: ${fieldValues.outlet_sarea === null || fieldValues.outlet_sarea === undefined ? null : `"${fieldValues.outlet_sarea}"`}
          displayDone: "${isdisplayDone === true ? "Y" : "N"}"  
          outletLongitude: "50.897"
          outletLatitude: "56.98878"
          pricelistHandoverContactPersonName: ${
            fieldValues.pricelist_HandoverContact_PersonName === null || fieldValues.pricelist_HandoverContact_PersonName === undefined
              ? null
              : `"${fieldValues.pricelist_HandoverContact_PersonName}"`
          }
          stockConfirmationContactName:${
            fieldValues.outlet_stockConfirmationContactName === null || fieldValues.outlet_stockConfirmationContactName === undefined
              ? null
              : `"${fieldValues.outlet_stockConfirmationContactName}"`
          }
          storeContactPersonName: ${
            fieldValues.outlet_storeContactPersonName === null || fieldValues.outlet_storeContactPersonName === undefined ? null : `"${fieldValues.outlet_storeContactPersonName}"`
          }
          zone: ${fieldValues.outlet_zone === null || fieldValues.outlet_zone === undefined ? null : `"${fieldValues.outlet_zone}"`}
          tier: ${fieldValues.outlet_tier === null || fieldValues.outlet_tier === undefined ? null : `"${fieldValues.outlet_tier}"`}
          outletPic: "${fieldValues.OutletPic_outlet}"
          promotionAllowed: "Y"
          storeWiseTarget :  ${fieldValues.outlet_storeWiseTarget === null || fieldValues.outlet_storeWiseTarget === undefined ? null : `"${fieldValues.outlet_storeWiseTarget}"`}
          projectionJwellerWiseTarget: ${
            fieldValues.outlet_projectionJwellerWiseTarget === null || fieldValues.outlet_projectionJwellerWiseTarget ? null : `${fieldValues.outlet_projectionJwellerWiseTarget}`
          }
          storeContactPersonNo: ${
            fieldValues.outlet_storeContactPersonNo === null || fieldValues.outlet_storeContactPersonNo === undefined ? null : `${fieldValues.outlet_storeContactPersonNo}`
          }
          stockConfirmationContactNo: ${
            fieldValues.outlet_stockConfirmationContactNo === null || fieldValues.outlet_stockConfirmationContactNo === undefined
              ? null
              : `${fieldValues.outlet_stockConfirmationContactNo}`
          }
          pHandoverContactPersonNo:${
            fieldValues.pHandover_ContactPersonNo === null || fieldValues.pHandover_ContactPersonNo === undefined ? null : `${fieldValues.pHandover_ContactPersonNo}`
          }
          pinCode: ${fieldValues.outlet_pinCode === null || fieldValues.outlet_pinCode === undefined ? null : `${fieldValues.outlet_pinCode}`}
          totalStock:${fieldValues.outlet_totalStock === null || fieldValues.outlet_totalStock === undefined ? null : `${fieldValues.outlet_totalStock}`}
         }
          ]
         }) { 
      status
      message 
      recordsId
      }
      }
      
      
      
      `,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: outletsaveButton,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      },
    }).then((response) => {
      console.log("response", response);
      console.log("response====", response.data.data.upsertDvnOutlet.status);
      const status = response.data.data.upsertDvnOutlet.status;

      console.log("Status Status ", status);

      if (status === "200") {
        const messageForSuccess = response.data.data.upsertDvnOutlet.message;
        console.log("messageForSuccess", messageForSuccess);
        message.success(messageForSuccess);
        setvisibleForOutletEdit(false);
        outletForm.resetFields();
        setTimeout(() => {
          gettabKYCToOutletData();
        }, 500);
        setDisplayInputOutlet("block");
      } else {
        message.error("getting error while creating lines");

        setLoading(false);
      }
    });
  };

  const getStockMixUpset = (data) => {
  
    const newToken = JSON.parse(localStorage.getItem("authTokens"));
    form.validateFields().then((values) => {
      mainForm.submit();
    });
    const fieldValues = stockmixForm.getFieldsValue();
  
    const pjMasterId = localStorage.getItem("pjMasterId");
    
 
   

    const uniqueId = uuid().replace(/-/g, "").toUpperCase();
    const stockMixsaveButton = {
      query: `mutation {
        upsertDvnClassification(dvnClassification: { dvnclassification: [{
          dOtherClassificationId: "${dOtherClassificationId === null || dOtherClassificationId === undefined ? uniqueId : dOtherClassificationId}"
          pjMasterId:  ${pjMasterId === null || pjMasterId === undefined ? null : `"${pjMasterId}"`}
          csBunitId: "0" 
          small: ${fieldValues.small === null || fieldValues.small === undefined ? null : `${fieldValues.small}`}
          medium: ${fieldValues.medium === null || fieldValues.medium === undefined ? null : `${fieldValues.medium}`}
          large: ${fieldValues.Large === null || fieldValues.Large === undefined ? null : `${fieldValues.Large}`}
          exLarge: ${fieldValues.ExLarge === null || fieldValues.ExLarge === undefined ? null : `${fieldValues.ExLarge}`}
          defVvs: ${fieldValues.DEFVVS === null || fieldValues.DEFVVS === undefined ? null : `${fieldValues.DEFVVS}`}
          defVs: ${fieldValues.DEFVS === null || fieldValues.DEFVS === undefined ? null : `${fieldValues.DEFVS}`}
          defSi: ${fieldValues.DEFSI === null || fieldValues.DEFSI === undefined ? null : `${fieldValues.DEFSI}`}
          ghVvs: ${fieldValues.GHVVS === null || fieldValues.GHVVS === undefined ? null : `${fieldValues.GHVVS}`}
          ghVs:${fieldValues.GHVS === null || fieldValues.GHVS === undefined ? null : `${fieldValues.GHVS}`}
          ghSi: ${fieldValues.GHSI === null || fieldValues.GHVS === undefined ? null : `${fieldValues.GHVS}`}
          ijkVvs: ${fieldValues.IJKVVS === null || fieldValues.IJKVVS === undefined ? null : `${fieldValues.IJKVVS}`}
          ijkVs: ${fieldValues.IJKVS === null || fieldValues.IJKVS === undefined ? null : `${fieldValues.IJKVS}`}
          ijksi: ${fieldValues.IJKSI === null || fieldValues.IJKSI === undefined ? null : `${fieldValues.IJKSI}`}
          dsd: ${fieldValues.DSD === null || fieldValues.DSD === undefined ? null : `${fieldValues.DSD}`}
          dsj:${fieldValues.DSJ === null || fieldValues.DSJ === undefined ? null : `${fieldValues.DSJ}`}
         }
          ]
         }) { 
      status
      message 
      recordsId
      }
      }`,
    };

 
    Axios({
      url: serverUrl,
      method: "POST",
      data: stockMixsaveButton,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      },
    }).then((response) => {

      const status = response.data.data.upsertDvnClassification.status;

      console.log("Status Status ", status);

      if (status === "200") {
        const messageForSuccess = response.data.data.upsertDvnClassification.message;
    
        message.success(messageForSuccess);
        stockmixForm.resetFields();
        setVisibleForStockEdit(false);
        setDOtherClassificationId(null);
        setTimeout(() => {
          gettabKYCToOutletData();
        }, 500);
        setDisplayInputStockMix("block");
      } else {
        message.error("getting error while creating lines");
        setVisibleForStockEdit(false);
        setLoading(false);
      }
    });
  };

  const getRegulatoryupset = (values) => {

    const newToken = JSON.parse(localStorage.getItem("authTokens"));
    form.validateFields().then((values) => {
      mainForm.submit();
    });
    const pjMasterId = localStorage.getItem("pjMasterId");
    const fieldValues = regulatoryForm.getFieldsValue();
 

    const uniqueId = uuid().replace(/-/g, "").toUpperCase();
    const regulatorysaveButton = {
      query: `mutation {
         upsertDvnCustbankdetails(dvnCustbankdetails: { dvnCustomerBankdetails: [{
           cBankDetailsId:"${cBankDetailsId === null || cBankDetailsId === undefined ? uniqueId : cBankDetailsId}"
           pjMasterId:${pjMasterId === null || pjMasterId === undefined ? null : `"${pjMasterId}"`}
           csBunitId:"0" 
           bankAccNumber:${fieldValues.BankAccountNo === null || fieldValues.BankAccountNo === undefined ? null : `"${fieldValues.BankAccountNo}"`}
           bankname: ${fieldValues.bank_Name === null || fieldValues.bank_Name === undefined ? null : `"${fieldValues.bank_Name}"`}
           ifscCode: ${fieldValues.ifsc_Code === null || fieldValues.ifsc_Code === undefined ? null : `"${fieldValues.ifsc_Code}"`}
           gstNo: ${fieldValues.Gst_No === null || fieldValues.Gst_No === undefined ? null : `"${fieldValues.Gst_No}"`}
           branch: ${fieldValues.bra_nch === null || fieldValues.Gst_No === undefined ? null : `"${fieldValues.Gst_No}"`}
           panNo: ${fieldValues.panNo === null || fieldValues.panNo === undefined ? null : `"${fieldValues.panNo}"`}
           tdsApplicable: ${istdsAPPlication === true ? `"Y"` : `"N"`}
           gstApplicable:${isGSTApplicable === true ? `"Y"` : `"N"`}
           incentivePromptPayment: ${isIncentivePromptPayment === true ? `"Y"` : `"N"`}
           jwellerlevelPromtPayment: ${isJewellerLevelPromptPayment === true ? `"Y"` : `"N"`}
          }
           ]
          }) { 
       status
       message 
       recordsId
       }
       }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: regulatorysaveButton,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      },
    }).then((response) => {
      console.log("response", response);
      console.log("response====", response.data.data.upsertDvnCustbankdetails.status);
      const status = response.data.data.upsertDvnCustbankdetails.status;

      console.log("Status Status ", status);

      if (status === "200") {
        const messageForSuccess = response.data.data.upsertDvnCustbankdetails.message;
        console.log("messageForSuccess", messageForSuccess);
        message.success(messageForSuccess);
        setVisibleForRegulatoryEdit(false);
        regulatoryForm.resetFields();
        setCBankDetailsId(null);
        setTimeout(() => {
          gettabKYCToOutletData();
        }, 500);
        setDisplayInputRegulatory("block");
      } else {
        message.error("getting error while creating lines");

        setLoading(false);
      }
    });
  };

  const getTermsConditionsupset = (values) => {

    const newToken = JSON.parse(localStorage.getItem("authTokens"));
    form.validateFields().then((values) => {
      mainForm.submit();
    });
    const pjMasterId = localStorage.getItem("pjMasterId");
    const fieldValues = termsAndConditionForm.getFieldsValue();
   

    const uniqueId = uuid().replace(/-/g, "").toUpperCase();
    const TermsConditionButton = {
      query: `mutation {
        upsertDvnCustaddress(dvnCustAddress: { dvnCustomerAddress: [{
          dvnCustomerAddressId:"${dvnCustomerAddressId === null || dvnCustomerAddressId === undefined ? uniqueId : dvnCustomerAddressId}" 
          pjMasterId:${pjMasterId === null || pjMasterId === undefined ? null : `"${pjMasterId}"`}
          csBunitId:"0"   
          faxno:"FAX10097"
          description:"Abhushan"
          addresssLine1:"Hyderabad"
          addresssLine2:"Hyderabad"
          dateOfAgreement:${fieldValues.agreement_Date === null || fieldValues.agreement_Date === undefined ? null : `"${fieldValues.agreement_Date}"`}  
          agreementSign:"${isAgreementSign === true ? "Y" : "N"}"  
          depositCommited:${fieldValues.depositCommited === null || fieldValues.depositCommited === undefined ? null : `"${fieldValues.depositCommited}"`}  
          depositReceivedValue:3000
          storeWisePromptPayment:${
            fieldValues.storeWise_PromptPayment === null || fieldValues.storeWise_PromptPayment === undefined ? null : `${fieldValues.storeWise_PromptPayment}`
          }  
          projectionJwellerWiseTarget:${fieldValues.Projection_JwellerWiseTarget} 	
          storeWiseTarget:${
            fieldValues.Projection_JwellerWiseTarget == null || fieldValues.Projection_JwellerWiseTarget === undefined ? null : `${fieldValues.Projection_JwellerWiseTarget}`
          }   
          margin:${fieldValues.margin === null || fieldValues.margin === undefined ? null : `${fieldValues.margin}`}  
          sor:"Y"
          ownerPic:"https://exceloid-image-master.s3.us-east-1.amazonaws.com/3A3869DE38234C1AB601603985808D68-outlet Image.jpg"
          companyLogo:"https://exceloid-image-master.s3.us-east-1.amazonaws.com/3A3869DE38234C1AB601603985808D68-outlet Image.jpg"
          email:"nikunj@dpabu.com"
          establishementDate:"2022-04-29"
          creditPeriod:"${fieldValues.creditPeriod}"   
          weeklyOff:"SAT"
         }
          ]
         }) { 
      status
      message 
      recordsId
      }
      }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: TermsConditionButton,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      },
    }).then((response) => {
   
      const status = response.data.data.upsertDvnCustaddress.status;
      if (status === "200") {
        const messageForSuccess = response.data.data.upsertDvnCustaddress.message;
     
        message.success(messageForSuccess);
        setvisibleForTermsAndConditionEdit(false);
        termsAndConditionForm.resetFields();
        setTimeout(() => {
          gettabKYCToOutletData();
        }, 500);
        setDisplayInputTerms("block");
      } else {
        message.error("getting error while creating lines");

        setLoading(false);
      }
    });
  };

  const getKYCupset = (data) => {
 
    const newToken = JSON.parse(localStorage.getItem("authTokens"));
    form.validateFields().then((values) => {
      mainForm.submit();
    });
  
    const pjMasterId = localStorage.getItem("pjMasterId");
    const fieldValues = kycForm.getFieldsValue();

    for (let index = 0; index < kycTabHeader.length; index++) {
      const element = kycTabHeader[index];
    }

  
    for (let index = 0; index < kycTabHeader.length; index++) {
      const element = kycTabHeader[index];
   
    }
  
    const uniqueId = uuid().replace(/-/g, "").toUpperCase();
    const kycSaveButton = {
      query: `mutation {
          upsertDvnCustomer(dvnCustomers: { dvnCustomer: [{
            dvnCustomerId:"${dvnCustomerId === null || dvnCustomerId === undefined ? uniqueId : dvnCustomerId}"
            pjMasterId:${pjMasterId === null || pjMasterId === undefined ? null : `"${pjMasterId}"`}
            csBunitId:"0"
            value:"Abhushan Ltd."
            pjName:${fieldValues.KycpjName === null || fieldValues.KycpjName === undefined ? null : `"${fieldValues.KycpjName}"`}
            gstNumber:"HT7655CWR"
            nickName:${fieldValues.kycNickName === null || fieldValues.kycNickName === undefined ? null : `"${fieldValues.kycNickName}"`}
            websiteAddress: "www.dpabhushan.com"
            ownerName:"Abhushan"
            city:"Hyderabad"
            zone:"South"
            mobileNo:"9870070071"
            outletPic:"https://exceloid-image-master.s3.us-east-1.amazonaws.com/3A3869DE38234C1AB601603985808D68-outlet Image.jpg"
            csCountryId:${fieldValues.Country_Kyc === null || fieldValues.Country_Kyc === undefined ? null : `"${fieldValues.Country_Kyc}"`}
            closerDate:"2022-04-29"
            dateOfBirth:${fieldValues.kyc_Birth_Date === null || fieldValues.kyc_Birth_Date === undefined ? null : `"${fieldValues.kyc_Birth_Date}"`}
            dateOfAnniversary:${fieldValues.kyc_Anniversary_Date === null || fieldValues.kyc_Anniversary_Date === undefined ? null : `"${fieldValues.kyc_Anniversary_Date}"`}
            sor:"${isInsorInKyc === true ? "Y" : "N"}"
            establishementDate:${fieldValues.date_of_Establishement === null || fieldValues.date_of_Establishement === undefined ? null : `"${fieldValues.date_of_Establishement}"`}
            kyc:"${isInKYCKYC === true ? "Y" : "N"}"
            compmanyLogo:"${fieldValues.kyccompmany_Logo}"
            pincode:${fieldValues.kycpin_code === null || fieldValues.kycpin_code === undefined ? null : `"${fieldValues.kycpin_code}"`}
            pjAddress:${fieldValues.Kycpj_Address === null || fieldValues.Kycpj_Address === undefined ? null : `"${fieldValues.Kycpj_Address}"`}
            pSalesRepStartDate:${
              fieldValues.kycpj_SalesRepStartDate === null || fieldValues.kycpj_SalesRepStartDate === undefined ? null : `"${fieldValues.kycpj_SalesRepStartDate}"`
            }
            pSalesRepEndDate:${fieldValues.kycpjSalesRepEndDate === null || fieldValues.kycpjSalesRepEndDate === undefined ? null : `"${fieldValues.kycpjSalesRepEndDate}"`}
            ownerPic:"${fieldValues.KycOwnerPic}"
            pjSalesrep:"${fieldValues.kyc_pjsalesRep}"
            invoicingName:"Zaveri Bazar"
            invoicingAddress: "Y"
            pjGroup: "DP"
            creditLimit: 200.0
            csaLimit: 300.0
            asslimit: 600.0
            totalConsignmentStock: 100
            outRightStock: 100.0
            totalStock: 200
            pOnboardingDate: ${fieldValues.kycOnboardingDate === null || fieldValues.kycOnboardingDate === undefined ? null : `"${fieldValues.kycOnboardingDate}"`}
            pClosureDate: ${fieldValues.Kycpj_ClosureDate === null || fieldValues.Kycpj_ClosureDate === undefined ? null : `"${fieldValues.Kycpj_ClosureDate}"`}
            websiteAddress: "www.dpabhushan.com"
            ownerName: ${fieldValues.kycpjOwnerName === null || fieldValues.kycpjOwnerName === undefined ? null : `"${fieldValues.kycpjOwnerName}"`}
            city: ${fieldValues.kyc_city === null || fieldValues.kyc_city === undefined ? null : `"${fieldValues.kyc_city}"`}
            zone:${fieldValues.kyc_zone === null || fieldValues.kyc_zone === undefined ? null : `"${fieldValues.kyc_zone}"`}
            mobileNo:${fieldValues.kycmobileNo === null || fieldValues.kycmobileNo === undefined ? null : `"${fieldValues.kycmobileNo}"`}
            email: ${fieldValues.kycemail === null || fieldValues.kycemail === undefined ? null : `"${fieldValues.kycemail}"`}
            outletPic: "https://exceloid-image-master.s3.us-east-1.amazonaws.com/8A5A914B3DAB4A95AFCA741E0DBAC93B-outlet Image.jpg"
            registeredWithDs: "Zaveri Bazar"
            unregisteredWithDs: "Bhuleshwar road , Jubliee hills"
            type: "PVT"
            csCurrencyId: "744C9A8D885841CABE28EA35569D3814"
            csPaymenttermId: "5700A64D22694566ADBDAE7094535891"
            csDoctypeId: "49A8018F235F4ED497CA5619DCFAC7D4"
            csRegionId: "${fieldValues.kyc_state}"
            dvnCustomerCategoryId: "173E281CF0C5497E8C95ABACE8183F3C"

           }
            ]
           }) {
        status
        message
        recordsId
        }
        }`,
    };
  
    Axios({
      url: serverUrl,
      method: "POST",
      data: kycSaveButton,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      },
    }).then((response) => {
  
      const status = response.data.data.upsertDvnCustomer.status;

 

      if (status === "200") {
        const messageForSuccess = response.data.data.upsertDvnCustomer.message;
        
        message.success(messageForSuccess);
        setVisibleForKYCEdit(false);
        kycForm.resetFields();
        setTimeout(() => {
          gettabKYCToOutletData();
        }, 500);
        setdisplayInput("block");
      } else {
        message.error("getting error while creating lines");

        setLoading(false);
      }
    });
  };

  const deleteKYCTab = async (data) => {
    const deleteKYcResponse = await getDeleteKYCData(data);
  

    if (deleteKYcResponse.status === "200") {
      message.success(deleteKYcResponse.message);
     
      gettabKYCToOutletData();
    } else {
      message.error(deleteKYcResponse.message);
    }
  };

  const deleteTeamsConditiondata = async (data) => {
    const responseteamsCondition = await getDeleteTermsConditionsData(data);
    
    if (responseteamsCondition.status === "200") {
      message.success(responseteamsCondition.message);
    
      gettabKYCToOutletData();
    } else {
      message.error(responseteamsCondition.message);
     
    }
  
  };

  const deleteRegulatoryForm = async (data) => {
    console.log(data);
    const deletefun = await getDeleteData(data);
  
    if (deletefun.status === "200") {
      message.success(deletefun.message);
    
      gettabKYCToOutletData();
    } else {
      message.error(deletefun.message);
    }
  };

  const deletestockmixtab = async (data) => {
    const delectresponse = await getDeleteStockMixData(data);
   
    if (delectresponse.status === "200") {
      message.success(delectresponse.message);
   
      gettabKYCToOutletData();
    } else {
      message.error(delectresponse.message);
    }
  };
  const deleteOutlet = async (data) => {
    const deleteOutletResponse = await getDeleteOuttLetData(data);
   
    if (deleteOutletResponse.status === "200") {
      message.success(deleteOutletResponse.message);
      console.log("==", deleteOutletResponse.message);
      gettabKYCToOutletData();
    } else {
      message.error(deleteOutletResponse.message);
    }
  };

  const getBusinessUnit = async () => {
    const userData = JSON.parse(window.localStorage.getItem("userData"));
    const businessUnitResponse = await getroleBunit(userData.user_id);
    
    setBunitData(businessUnitResponse.userBunit);
    setBUnitId(businessUnitResponse.defaultCsBunitId);
  };
  const onSelectBusinessUnit = (e) => {
    setBUnitId(e);

  };

  const getcustomgeneric = async () => {
    const CustomerCategoryResponse = await getCustomerCategoryTypeData();
    setCustomerCategoryData(CustomerCategoryResponse.searchData);
    
  };
  const OnCustomerCategory = (e, data) => {
    setCustomerCategoryId(e);
  };

  const getCurrencyData = async () => {
    const CurrencyDataResponse = await getCurrencyDropDownData();
    setCurrencyData(CurrencyDataResponse.searchData);
  
  };

  const onCurrencySelect = (e) => {
    setCurrencyId(e);
  };
  const paymentDropDownData = async () => {
    const paymentDataResponse = await getPaymentTermsDropDownData();
    setPaymentData(paymentDataResponse.searchData);
  
  };

  const onPaymentSelect = (e) => {
    setPaymentId(e);
  
  };

  const getStateDropData = async () => {
    const stateDataResponse = await getStateDropDownData();
    setStateData(stateDataResponse.searchData);
  
  };

  const onSelectState = (e) => {
    setstateId(e);
  };

  const getCountryData = async () => {
    const countryDataResponse = await getCountryDropDownData();
    setCountryData(countryDataResponse.searchData);
   
  };

  const onSelectCountry = (e) => {
    setCountryId(e);
  };

  const getkycStateData = async () => {
    const stateDataResponse = await getkycStateDropDownData();
    setStateKYCData(stateDataResponse.searchData);
   
  };

  const onSelectKycState = (e) => {
    setStateKYCId(e);
  };

  const getkycCountryData = async () => {
    const countryKycDataResponse = await getkycCountryDropDownData();
    setCountryKYCData(countryKycDataResponse.searchData);
  
  };

  const onSelectKycCountry = (e) => {
    setCountryKYCId(e);

  };
  const getSalesRepData = async () => {
    const salesRepDataResponse = await getSalesRepDropDown();
  
    setSalesRepData(salesRepDataResponse.searchData);
  };

  const onSelectSalesrep = (e) => {
    setsalesRepId(e);
  };

  const getCreditData = async () => {
    const creditDataResponse = await getCreditPeriodDropDown();
    setCreditDataData(creditDataResponse.searchData);

  };

  const onSelectCredit = (e) => {
    setCreditId(e);
  };

  const getStateOutletData = async () => {
    const stateOutletDataResponse = await getStateOutletDropDown();
    setStateOutlet(stateOutletDataResponse.searchData);

  };

  const onSelectStateOutlet = (e) => {
    setStateOutletId(e);
  };

 


  return (
    <div>
      <Row>
        <Col span={12}>
          <h2>PJ Custom</h2>
        </Col>

     
      </Row>
      <Row>
        <Col span={24}>
          <Card>
            <Form
              layout="vertical"
              form={mainForm}
              name="mainForm"
              
            >
              <Tabs
                tabPosition="left"
                defaultActiveKey={"All"}
              >
                <TabPane tab="All" key="All">
                  <Row>
                  
                  </Row>
                  <div>
                    <div>
                      <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>
                        <Card style={{ height: "70vh", overflowY: "scroll", border: "none" }}>
                          <Form layout="vertical" name="control-hooks" form={form}>
                            <Row>
                              <Col span={24}>
                                <span style={{ float: "right" }}>
                                  <Button
                                    type="default"
                                    onClick={() => {
                                      history.push(`/others/window/7452`);
                                    }}
                                    style={{
                                      height: "2rem",
                                      width: "5.4rem",
                                      backgroundColor: "rgb(8 158 164)",
                                      border: "0.25px solid rgb(7, 136, 141)",
                                      borderRadius: "2px",
                                      fontSize: "14px",
                                      color: "rgb(255, 255, 255)",
                                      fontWeight: "500",
                                      fontFamily: "Open Sans",
                                      opacity: "1",
                                    }}
                                  >
                                    Back
                                  </Button>
                                </span>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={6}>
                                <Form.Item name="bname" label="Business Unit*" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].bUnitName : ""}</span>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <span>
                                  <Form.Item label="PJ Code*" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                    <span>{headerFormData !== undefined ? headerFormData[0].pjCode : ""}</span>
                                  </Form.Item>
                                </span>
                              </Col>
                              <Col span={6}>
                                <span>
                                  <Form.Item label="PJ Name*" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                    <span>{headerFormData !== undefined ? headerFormData[0].pjName : ""}</span>
                                  </Form.Item>
                                </span>
                              </Col>
                              <Col span={6}>
                                <span>
                                  <Form.Item label="Customer Category" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                    <span>{headerFormData !== undefined ? headerFormData[0].customerCategory.name : ""}</span>
                                  </Form.Item>
                                </span>
                              </Col>
                            </Row>
                            <p style={{ marginBottom: "20px" }} />
                            <Row gutter={16}>
                              <Col span={6}>
                                <Form.Item label="GST No" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].gstNo : ""}</span>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item label="Currency" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].currency.isoCode : ""}</span>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item label="Nick Name" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].pjName : ""}</span>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item label="Invoicing Name" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].pjName : ""}</span>
                                </Form.Item>
                              </Col>
                            </Row>
                            <p style={{ marginBottom: "20px" }} />
                            <Row gutter={16}>
                              <Col span={6}>
                                <Form.Item label="Invoicing(Y/N)" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <Checkbox checked={headerFormData !== undefined ? (headerFormData[0].displayDone === "Y" ? true : false) : ""}></Checkbox>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <span>
                                  <Form.Item label="Invoicing Address" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                    <span>{headerFormData !== undefined ? headerFormData[0].invoicingAddress : ""}</span>
                                  </Form.Item>
                                </span>
                              </Col>
                              <Col span={6}>
                                <Form.Item label="CSA Limit" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].csaLimit : ""}</span>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item label="ASS Limit" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].asslimit : ""}</span>
                                </Form.Item>
                              </Col>
                            </Row>
                            <p style={{ marginBottom: "20px" }} />
                            <Row gutter={16}>
                              <Col span={6}>
                                <Form.Item label="ASS Start Date" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? moment(headerFormData[0].assStartDate).format("YYYY-MM-DD") : ""}</span>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item label="ASS End Date" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? moment(headerFormData[0].assEndDate).format("YYYY-MM-DD") : ""}</span>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item label="Total Consignment Stock (TCS)" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>
                                    {headerFormData !== undefined
                                      ? headerFormData[0].dvnCustomer !== undefined && headerFormData[0].dvnCustomer.length > 0
                                        ? headerFormData[0].dvnCustomer[0].totalConsignmentStock
                                        : ""
                                      : ""}
                                  </span>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item label="Outright Stock (OS)" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>
                                    {headerFormData !== undefined
                                      ? headerFormData[0].dvnCustomer !== undefined && headerFormData[0].dvnCustomer.length > 0
                                        ? headerFormData[0].dvnCustomer[0].outRightStock
                                        : ""
                                      : ""}
                                  </span>
                                </Form.Item>
                              </Col>
                            </Row>
                            <p style={{ marginBottom: "20px" }} />
                            <Row gutter={16}>
                              <Col span={6}>
                                <Form.Item label="Total  Stock" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>
                                    {headerFormData !== undefined
                                      ? headerFormData[0].dvnCustomer !== undefined && headerFormData[0].dvnCustomer.length > 0
                                        ? headerFormData[0].dvnCustomer[0].totalStock
                                        : ""
                                      : ""}
                                  </span>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item label="Payment Terms" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].paymentTerms.name : ""}</span>
                                </Form.Item>
                              </Col>
                            </Row>
                            <p style={{ marginBottom: "20px" }} />
                            <h4>More Information</h4>
                            <Row gutter={16}>
                              <Col span={6}>
                                <Form.Item label="PJ Type" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].pjtype : null}</span>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item label="PJ Group" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].pjGroup : ""}</span>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item label="PJ Closure Date" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? moment(headerFormData[0].pjClosureDate).format("YYYY-MM-DD") : ""}</span>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item label="PJ Onboarding Date" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? moment(headerFormData[0].pjOnboardingDate).format("YYYY-MM-DD") : ""}</span>
                                </Form.Item>
                              </Col>
                            </Row>
                            <p style={{ marginBottom: "20px" }} />
                            <Row gutter={16}>
                              <Col span={6}>
                                <Form.Item label="City" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].city : ""}</span>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item label="State" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].region.name : ""}</span>
                                </Form.Item>
                              </Col>
                            </Row>
                            <p style={{ marginBottom: "20px" }} />
                            <Row gutter={16}>
                              <Col span={6}>
                                <Form.Item label="Zone" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].zone : ""}</span>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item label="Email" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].email : ""}</span>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item label="Mobile No" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].mobileNo : ""}</span>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item label="Country" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].country.name : ""}</span>
                                </Form.Item>
                              </Col>
                            </Row>
                            <p style={{ marginBottom: "20px" }} />
                            <Row gutter={16}>
                              <Col span={6}>
                                <Form.Item label="Pincode" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].pincode : ""}</span>
                                </Form.Item>
                              </Col>
                              <Row />
                              <Col span={6}>
                                <Form.Item label="WebSite Address" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].websiteAddress : ""}</span>
                                </Form.Item>
                              </Col>
                            </Row>
                            <p />
                            <Row gutter={16}>
                              <Col span={6}>
                                <Form.Item label="Company Logo" name="companyLogo">
                                  <Image width={100} src={headerFormData !== undefined ? headerFormData[0].companyLogo : ""} />
                                </Form.Item>
                              </Col>

                              <Col span={6}>
                                <Form.Item label="Owner Pic" name="ownerPic">
                                  <Image width={100} src={headerFormData !== undefined ? headerFormData[0].ownerPic : ""} />
                                </Form.Item>
                              </Col>
                            </Row>

                            <p style={{ marginBottom: "20px" }} />
                            <h4>PJ Sales Distribution In(%)</h4>
                            <Row gutter={16}>
                              <Col span={6}>
                                <Form.Item label="Solitaire Jewellery" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].solitaireJewellery : ""}</span>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item label="Small Diamond Jewellery" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].smallDiamondJewellery : ""}</span>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item label="Gold Jewellery" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].goldJewellery : ""}</span>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item label="Luxury & Lifestyle Items" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].luxuryLifestyle : ""}</span>
                                </Form.Item>
                              </Col>
                            </Row>
                            <p style={{ marginBottom: "20px" }} />
                            <Row gutter={16}>
                              <Col span={6}>
                                <Form.Item label="Others" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].others : ""}</span>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item label="Registered With DS" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].registeredWithDs : ""}</span>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item label="Un-Registered With DS" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].unregisteredWithDs : ""}</span>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item label="Invoicing Format" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                  <span>{headerFormData !== undefined ? headerFormData[0].csDocType.name : ""}</span>
                                </Form.Item>
                              </Col>
                            </Row>
                            <p style={{ marginBottom: "20px" }} />

                            <hr />
                            <h3>KYC</h3>

                            {dvnCustomerKyc.map((data) => (
                              <div>
                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="PJ Name" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.pjName}</span>
                                    </Form.Item>
                                  </Col>

                                  <Col span={6}>
                                    <span>
                                      <Form.Item label="Nick Name" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                        <span>{data.nickname}</span>
                                      </Form.Item>
                                    </span>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Invoicing Name" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.invoicingName}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="PJ Onboarding Date" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                     
                                      <span>{data.pjOnboardingDate === undefined || data.pjOnboardingDate === null ? null : moment(data.pjOnboardingDate).format("YYYY-MM-DD")}</span>

                                    </Form.Item>
                                  </Col>
                                </Row>
                                <p style={{ marginBottom: "20px" }} />
                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="PJ Closure Date" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{moment(data.pjClosureDate).format("YYYY-MM-DD")}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="PJ Sales Rep" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.salesRep.name}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Sales Rep Start Date" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.pjSalesRepStartDate ===null || data.pjSalesRepStartDate===undefined ?null: moment(data.pjSalesRepStartDate).format("YYYY-MM-DD")}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Sales Rep End Date" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.pjSalesRepEndDate===null ||data.pjSalesRepEndDate===undefined?null: moment(data.pjSalesRepEndDate).format("YYYY-MM-DD")}</span>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <p style={{ marginBottom: "20px" }} />
                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="Owner Name" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.ownerName}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Mobile No" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.mobileNo}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Email" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.email}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Birth Date" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{moment(data.birthDate).format("YYYY-MM-DD")}</span>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <p style={{ marginBottom: "20px" }} />
                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="Anniversary Date" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{moment(data.anniversaryDate).format("YYYY-MM-DD")}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Address" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}></Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Owner Pic" name="ownerpic">
                                      <Image width={100} src={data.outletPic} />
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="SOR">
                                      <Checkbox checked={data.sor === "Y" ? true : false}></Checkbox>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <p style={{ marginBottom: "20px" }} />
                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="KYC">
                                      <Checkbox checked={data.kyc === "Y" ? true : false}></Checkbox>
                                    </Form.Item>
                                  </Col>

                                  <Col span={6}>
                                    <Form.Item label="Website Address" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.websiteAddress}</span>
                                    </Form.Item>
                                  </Col>

                                  <Col span={6}>
                                    <Form.Item label="Company Logo" name="companyLogo">
                                      <Image width={100} src={data.compmanyLogo} />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <p style={{ marginBottom: "20px" }} />
                                <hr />
                              </div>
                            ))}
                            <h3>Terms & Conditions</h3>
                            {dvnAddress.map((data) => (
                              <div>
                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="Payment Terms" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.paymenttermName}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Margin" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.margin}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Deposit Commited" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.depositCommited}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Credit Limit" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.pjCreditLimit}</span>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <p style={{ marginBottom: "20px" }} />
                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="CreditPeriod" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.paymenttermName}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Projection Jeweller Wise Target" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.projectionJwellerWiseTarget}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Store wise target" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.storeWiseTarget}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="store wise prompt payment" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      {" "}
                                      {/* <span>{headerFormData!== undefined ? (headerFormData[0].dvncustAddress[0]!==undefined && headerFormData[0].dvncustAddress.length>0 ?(headerFormData[0].dvncustAddress[0].storeWisePromptPayment):'') :''}</span> */}
                                      <span>{data.storeWisePromptPayment}</span>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <p style={{ marginBottom: "20px" }} />
                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="Agreement Date" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>
                                        {headerFormData !== undefined
                                          ? headerFormData[0].dvncustAddress[0] !== undefined && headerFormData[0].dvncustAddress.length > 0
                                            ? moment(headerFormData[0].dvncustAddress[0].agreementDate).format("YYYY-MM-DD")
                                            : ""
                                          : ""}
                                      </span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Agreement Sign" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <Checkbox checked={data.agreementSign === "Y" ? true : false} style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <p style={{ marginBottom: "20px" }} />
                                <hr />
                              </div>
                            ))}
                            <h3>Regulatory</h3>
                            {dvnbankregulatory.map((data) => (
                              <div>
                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="Bank Account No*" style={{ color: "#080707", fontWeight: "300", fontSize: "15px" }}>
                                      <span>{data.bankAccNumber}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Bank Name*" style={{ color: "#080707", fontWeight: "300", fontSize: "15px" }}>
                                      <span>{data.bankname}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Branch" style={{ color: "#080707", fontWeight: "300", fontSize: "15px" }}>
                                      <span>{data.branch}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="IFSC Code" style={{ color: "#080707", fontWeight: "300", fontSize: "15px" }}>
                                      <span>{data.ifscCode}</span>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <p style={{ marginBottom: "20px" }} />
                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="GST No" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.gstNo}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="PAN No" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.panNo}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="TDS Applicable" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <Checkbox checked={data.tdsApplicable === "Y" ? true : false}></Checkbox>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="GST Applicable" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <Checkbox checked={data.gstApplicable === "Y" ? true : false}></Checkbox>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <p style={{ marginBottom: "20px" }} />
                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="Incentive Prompt Payment" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <Checkbox checked={data.incentivePromptPayment === "Y" ? true : false}></Checkbox>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Jeweller Level Prompt Payment" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <Checkbox checked={data.jwellerlevelPromtPayment === "Y" ? true : false}></Checkbox>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <p style={{ marginBottom: "20px" }} />
                                <hr />
                              </div>
                            ))}

                            <h3>Stock Mix</h3>
                            {dvnclass.map((data) => (
                              <div>
                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="Small%*" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.small}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Medium %*" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.medium}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Large %*" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.large}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Ex.Large %*" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.exLarge}</span>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <p style={{ marginBottom: "20px" }} />
                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="DEF VVS %*" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.defVvs}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="DEF VS%*" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.defVs}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="DEF SI%*" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.defSi}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="GH VVS%*" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.ghVvs}</span>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <p style={{ marginBottom: "20px" }} />

                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="GH VS%*" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.ghVs}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="GH SI%*" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.ghSi}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="IJK VVS%*" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.ijkVvs}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="IJK VS%*" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.ijkVs}</span>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <p style={{ marginBottom: "20px" }} />
                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="IJK SI%*" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.ijksi}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="DSD %*" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.dsd}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="DSJ %*" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.dsj}</span>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <p style={{ marginBottom: "20px" }} />
                                <hr />
                              </div>
                            ))}
                            <h3>OutLet</h3>
                            {outletValue.map((data) => (
                              <div>
                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="Outlet Name" style={{ color: "#080707", fontWeight: "300", fontSize: "15px" }}>
                                      <span>{data.outletName}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Nick  Name" style={{ color: "#080707", fontWeight: "300", fontSize: "15px" }}>
                                      <span>{data.nickName}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="outletaddress" style={{ color: "#080707", fontWeight: "300", fontSize: "15px" }}>
                                      <span>{data.outletFullAddress}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="City" style={{ color: "#080707", fontWeight: "300", fontSize: "15px" }}>
                                      <span>{data.outletCity}</span>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <p style={{ marginBottom: "20px" }} />
                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="Pin" style={{ color: "#080707", fontWeight: "300", fontSize: "15px" }}>
                                      <span>{data.pinCode}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="State" style={{ color: "#080707", fontWeight: "300", fontSize: "15px" }}>
                                      <span>{data.stateName}</span>
                                    </Form.Item>
                                  </Col>
                                  <p />
                                  <Col span={6}>
                                    <Form.Item label="Zone" style={{ color: "#080707", fontWeight: "300", fontSize: "15px" }}>
                                      <span>{data.zone}</span>
                                    </Form.Item>
                                  </Col>

                                  <Col span={6}>
                                    <Form.Item label="Tier" style={{ color: "#080707", fontWeight: "300", fontSize: "15px" }}>
                                      <span>{data.tier}</span>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <p style={{ marginBottom: "20px" }} />
                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="Phone No" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.mobileNo}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Area" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.area}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Email" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.email}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Market Name" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.outletName}</span>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <p style={{ marginBottom: "20px" }} />
                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="Store Contact Person Name" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.storeContactPersonName}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Store Contact Person No" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.storeContactPersonNo}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Weekly Off" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.weeklyOff}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="GST No" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.gstNo}</span>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <p style={{ marginBottom: "20px" }} />
                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="Outlet Onboarding Date" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.outletOnboardingDate===null || data.outletOnboardingDate===undefined?null: moment(data.outletOnboardingDate).format("YYYY-MM-DD")}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Outlet Closure Date" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{ data.outletClosureDate===null ||data.outletClosureDate===undefined ?null:  moment(data.outletClosureDate).format("YYYY-MM-DD")}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Outlet Sales Rep" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{ data.salesRepStartDate===null|| data.salesRepStartDate===undefined?null: moment(data.salesRepStartDate).format("YYYY-MM-DD")}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Sales  Rep  Start Date" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.salesRepStartDate ===null||data.salesRepStartDate===undefined?null: moment(data.salesRepStartDate).format("YYYY-MM-DD")}</span>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <p style={{ marginBottom: "20px" }} />
                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="Sales Rep End Date" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.salesRepStartDate===null ||data.salesRepStartDate===undefined?null: moment(data.pjSalesRepEndDate).format("YYYY-MM-DD")}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Price List Handover Contact Person Name" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.stockConfirmationContactName}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Price List Hand Over Contact No" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.pHandoverContactPersonNo}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="stock Confirmation  Contact Person Name" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.pricelistHandoverContactPersonName}</span>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <p style={{ marginBottom: "20px" }} />
                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="Stock Confirmation Contact Person No" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.stockConfirmationContactNo}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="KYC">
                                      <Checkbox checked={data.kyc === "Y" ? true : false}></Checkbox>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Authorised Outlet" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <Checkbox></Checkbox>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Promotion Allowed" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <Checkbox checked={data.promotionAllowed === "Y" ? true : false}></Checkbox>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <p style={{ marginBottom: "20px" }} />
                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="Outlet Longitude" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.outletLongitude}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Outlet Latitude" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.outletLatitude}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Outlet Pic">
                                      <Image width={100} src={data.outletPic} />
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Display Done">
                                      <Checkbox checked={data.displayDone === "Y" ? true : false}></Checkbox>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <p style={{ marginBottom: "20px" }} />
                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="Trial Period  From  Date" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{moment(data.trialFromPeriod).format("YYYY-MM-DD")}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Trial Period To Date" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{moment(data.trialToPeriod).format("YYYY-MM-DD")}</span>
                                    </Form.Item>
                                  </Col>

                                  <Col span={6}>
                                    <Form.Item label="Total Stock" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.totalStock}</span>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item label="Projection Jweller Wise Target" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.projectionJwellerWiseTarget}</span>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <p style={{ marginBottom: "20px" }} />
                                <Row gutter={16}>
                                  <Col span={6}>
                                    <Form.Item label="Store Wise Target" style={{ color: "#080707", fontWeight: "300", fontSize: "13px" }}>
                                      <span>{data.storeWiseTarget}</span>
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <p />
                                <hr />
                              </div>
                            ))}
                          </Form>
                        </Card>
                      </Spin>
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Customer Info" key="CustomerInfo">
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>
                    <div style={{ padding: "8px" }}>
                      <h3>Customer Info</h3>
                      <Row>
                        <Col span={24} style={{ textAlign: "right", marginTop: "0.5%", marginBottom: "0.5%" }}>
                          <Button
                            size="small"
                            style={{
                              color: "white",
                              background: "#3E7A86",
                              fontSize: "12px",
                              float: "right",
                              marginBottom: "7.5px",
                            }}
                            onClick={() => {
                              openaddAddressModalCustomerInfo();
                            }}
                          >
                            ADD +
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} style={{ textAlign: "right", marginTop: "0.5%", marginBottom: "0.5%" }}>
                          <span style={{ float: "right" }}>
                          
                            &nbsp;
                            <Button
                             
                              onClick={editFieldsInCustomeTabNew}
                              style={{ height: "30px", marginRight: "8px" }}
                            >
                              <EditOutlined style={{ fontWeight: "600" }} />
                            </Button>
                          </span>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="bUnitname" label="Business Unit*" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].bUnitName : ""}</span>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="pj_code" label="PJ Code*" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].pjCode : ""}</span>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="pj_Name" label="PJ Name*" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].pjName : ""}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customer_category" label="Customer Category" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].customerCategory.name : ""}</span>
                          </Form.Item>
                        </Col>
                      </Row>
                      <p style={{ marginBottom: "10px" }} />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="gst_no" label="GST No" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].gstNo : ""}</span>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="curr_ency" label="Currency" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].currency.isoCode : ""}</span>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="nick_name" label="Nick Name" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].currency.pjName : ""}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="invoicing_name" label="Invoicing Name" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].currency.isoCode : ""}</span>
                          </Form.Item>
                        </Col>
                      </Row>
                      <p style={{ marginBottom: "10px" }} />

                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="Invoi_cing" label="Invoicing (Y/N)" style={{ marginBottom: "8px" }}>
                            {/* <span> {(customerInfoHeder!== undefined ? (customerInfoHeder[0].isInvoicing === "Y" ? true : false) : "-")}</span> */}
                            <Checkbox checked={isInvoicing} onChange={onChangeCheckBOx} style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="invoicing_address" label="Invoicing Address" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].invoicingAddress : ""}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="CSA_limit" label="CSA Limit" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].csaLimit : ""}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="ASS_Limit" label="ASS Limit" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].asslimit : ""}</span>
                          </Form.Item>
                        </Col>
                      </Row>
                      <p style={{ marginBottom: "10px" }} />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="ASSStart_Date" label="ASS Start Date" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? moment(customerInfoHeder[0].assStartDate).format("YYYY-MM-DD") : ""}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="ASSEnd_Date" label="ASS End Date" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? moment(customerInfoHeder[0].assEndDate).format("YYYY-MM-DD") : ""}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="totalconsignment_Stock" label="Total Consignment Stock (TCS)" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].totalConsignmentStock : ""}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outright_stock" label="Outright Stock (OS)" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].outRightStock : ""}</span>
                          </Form.Item>
                        </Col>
                      </Row>
                      <p style={{ marginBottom: "10px" }} />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="Total_Stock" label="Total  Stock" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].totalStock : ""}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="paymen_tterms" label="Payment Terms" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].paymentTerms.name : ""}</span>
                          </Form.Item>
                        </Col>
                      </Row>
                      <p style={{ marginBottom: "10px" }} />
                      <h3>More Information</h3>
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customer_Infopjtype" label="PJ Type" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].pjtype : ""}</span>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customer_infopjgroup" label="PJ Group" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].pjGroup : ""}</span>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoPJClosureDate" label="PJ Closure Date" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? moment(customerInfoHeder[0].pjClosureDate).format("YYYY-MM-DD") : ""}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoPJOnboardingDate" label="PJ Onboarding Date" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? moment(customerInfoHeder[0].pjOnboardingDate).format("YYYY-MM-DD") : ""}</span>
                          </Form.Item>
                        </Col>
                      </Row>
                      <p style={{ marginBottom: "10px" }} />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInf_oownername" label="Owner Name" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].pjName : ""}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customer_infocity" label="City" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].city : ""}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfo_state" label="State" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].region.name : ""}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfo_zone" label="Zone" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].zone : ""}</span>
                          </Form.Item>
                        </Col>
                      </Row>
                      <p style={{ marginBottom: "10px" }} />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfo_Email" label="Email" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].email : ""}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerinfo_mobileno" label="Mobile No" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].mobileNo : ""}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfo_Country" label="Country" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].country.name : ""}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="invoice_Formate" label="invoice Formate" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].csDocType.name : ""}</span>
                          </Form.Item>
                        </Col>
                      </Row>
                      <p style={{ marginBottom: "10px" }} />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfo_pincode" label="Pincode" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].pincode : ""}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerinfoWebSit_eaddress" label="WebSite Address" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].websiteAddress : ""}</span>
                          </Form.Item>
                        </Col>
                      </Row>
                      <p />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfo_CompanyLogo" label="Company Logo" style={{ marginBottom: "8px" }}>
                            <Image width={100} src={customerInfoHeder !== undefined ? customerInfoHeder[0].companyLogo : ""} />
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customer_InfoCountry" label="Owner Pic" style={{ marginBottom: "8px" }}>
                            <Image width={100} src={customerInfoHeder !== undefined ? customerInfoHeder[0].ownerPic : ""} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <p style={{ marginBottom: "10px" }} />
                      <h3>PJ Sales Distribution In(%)</h3>
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfosolitaire_jewellery" label="Solitaire Jewellery" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].solitaireJewellery : ""}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerinfosmalldiamond_jewellery" label="Small Diamond Jewellery" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].smallDiamondJewellery : ""}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfo_GoldJewellery" label="Gold Jewellery" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].goldJewellery : ""}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoluxury_lifestyleitems" label="Luxury & Lifestyle Items" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].luxuryLifestyle : ""}</span>
                          </Form.Item>
                        </Col>
                      </Row>
                      <p style={{ marginBottom: "10px" }} />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfo_Others" label="Others" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].others : ""}</span>
                          </Form.Item>
                        </Col>
                      </Row>
                      <h3>Other Showroom Details (Mention Market Name)</h3>
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfo_RegisteredWithDS" label="Registered With DS" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].registeredWithDs : ""}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfo_UnRegisteredWithDS" label="Un-Registered With DS" style={{ marginBottom: "8px" }}>
                            <span>{customerInfoHeder !== undefined ? customerInfoHeder[0].unregisteredWithDs : ""}</span>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </Spin>
                </TabPane>
                <TabPane tab="KYC" key="KYC">
                  <h3>KYC</h3>  
                  <div style={{ padding: "8px" }}>
                  <Row>
                    <Col span={24} style={{ textAlign: "right", marginTop: "0.5%", marginBottom: "0.5%" }}>
                      <Button
                        style={{
                          color: "white",
                          background: "#3E7A86",
                          fontSize: "12px",
                          float: "right",
                          marginBottom: "7.5px",
                        }}
                        onClick={() => {
                          openaddAddressModalkyc();
                        }}
                      >
                        ADD +
                      </Button>
                    </Col>
                  </Row>
                  </div>
                  {kycTabHeader.map((data) => (
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>
                      <div style={{ padding: "8px" }}>
                        <Row>
                          <Col span={24} style={{ textAlign: "right", marginTop: "0.5%", marginBottom: "0.5%" }}>
                            <span style={{ float: "right" }}>
                              <Button onClick={() => editFieldsInFrom(data)} style={{ height: "30px", marginRight: "8px" }}>
                                <EditOutlined style={{ fontWeight: "600" }} />
                              </Button>
                              &nbsp;
                              <Button
                                style={{ height: "30px", marginRight: "8px" }}
                                onClick={() => {
                                  deleteKYCTab(data.dvnCustomerId);
                                }}
                              >
                                <i style={{ fontWeight: "600" }} className="lnr lnr-trash" />
                              </Button>
                            </span>
                          </Col>
                        </Row>

                        <Row gutter={16}>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="KYCPjName" label="PJ Name">
                              <span>{data.pjName}</span>
                            </Form.Item>
                          </Col>

                          <Col className="gutter-row" span={6}>
                            <span>
                              <Form.Item name="KYCnickName" label="Nick Name">
                                <span>{data.nickName}</span>
                              </Form.Item>
                            </span>
                          </Col>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="KYCpjOnboardingDate" label="PJ Onboarding Date">
                              <span>{data.pjOnboardingDate === undefined || data.pjOnboardingDate === null ? null : moment(data.pjOnboardingDate).format("YYYY-MM-DD")}</span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="KYCpjClosureDate" label="PJ Closure Date" style={{ marginBottom: "8px" }}>
                              <span>{moment(data.pjClosureDate).format("YYYY-MM-DD")}</span>
                            </Form.Item>
                          </Col>
                        </Row>
                        <p />
                        <Row gutter={16}>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="KYCpjAddress" label="PJ Address">
                              <span>{data.pjAddress}</span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={6}>
                            <Form.Item label="City" name="KYCcity">
                              <span>{data.city}</span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={6}>
                            <Form.Item label="Statekyc" name="state">
                              <span>{data.region.name}</span>
                            </Form.Item>
                          </Col>

                          <Col className="gutter-row" span={6}>
                            <Form.Item name="KYCzone" label="Zone" style={{ marginBottom: "8px" }}>
                              <span>{data.zone} </span>
                            </Form.Item>
                          </Col>
                        </Row>
                        <p />
                        <Row gutter={16}>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="KYCcountry" label="Country" style={{ marginBottom: "8px" }}>
                              <span>{data.country.name}</span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="KYCpincode" label="PinCode" style={{ marginBottom: "8px" }}>
                              <span>{data.pincode}</span>
                            </Form.Item>
                          </Col>
                        </Row>
                        <p />
                        <h3>Owner Details (Field Group)</h3>
                        <Row gutter={16}>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="KYCPJSalesRep" label="PJ Sales Rep" style={{ marginBottom: "8px" }}>
                              <span>{data.salesRep.name}</span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="KYCSalesRepStartDate" label="Sales Rep Start Date	" style={{ marginBottom: "8px" }}>
                              <span>
                                {data.pjSalesRepStartDate === undefined || data.pjSalesRepStartDate === null ? null : moment(data.pjSalesRepStartDate).format("YYYY-MM-DD")}
                              </span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="KYCSalesRepEndDate" label="Sales Rep End Date	" style={{ marginBottom: "8px" }}>
                              <span>{moment(data.pjSalesRepEndDate).format("YYYY-MM-DD")}</span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="KYCOwnerName" label="Owner Name" style={{ marginBottom: "8px" }}>
                              <span>{data.pjName}</span>
                            </Form.Item>
                          </Col>
                        </Row>
                        <br />
                        <Row gutter={16}>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="mobileno" label="Mobile No" style={{ marginBottom: "8px" }}>
                              <span>{data.mobileNo}</span>
                            </Form.Item>
                          </Col>

                          <Col className="gutter-row" span={6}>
                            <Form.Item name="email" label="Email" style={{ marginBottom: "8px" }}>
                              <span>{data.email}</span>
                            </Form.Item>
                          </Col>

                          <Col className="gutter-row" span={6}>
                            <Form.Item name="KYCbirthDate" label="Birth Date" style={{ marginBottom: "8px" }}>
                              <span>{data.birthDate === undefined || data.birthDate === null ? null : moment(data.birthDate).format("YYYY-MM-DD")} </span>
                            </Form.Item>
                          </Col>

                          <Col className="gutter-row" span={6}>
                            <Form.Item name="KYCAnniversaryDate" label="Anniversary Date" style={{ marginBottom: "8px" }}>
                              <span>{data.anniversaryDate === undefined || data.anniversaryDate === null ? null : moment(data.anniversaryDate).format("YYYY-MM-DD")}</span>
                            </Form.Item>
                          </Col>
                        </Row>
                        <br />
                        <Row gutter={16}>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="KYCpjAddress" label="Address" style={{ marginBottom: "8px" }}>
                              <span>{data.pjAddress}</span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="KYCOwnerPic" label="Owner Pic">
                              <Image width={100} src={data.ownerPic} />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={6}>
                            <Form.Item
                              name="kyc_sor"
                              label="SOR"
                              style={{ marginBottom: "8px" }}
                            >
                              <span>{data.sor}</span>
                              
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="KYCdateofEstablishement" label="Date of Establishment" style={{ marginBottom: "8px" }}>
                              <span>
                                {data.dateofEstablishement === undefined || data.dateofEstablishement === null ? null : moment(data.dateofEstablishement).format("YYYY-MM-DD")}
                              </span>
                            </Form.Item>
                          </Col>
                        </Row>
                        <br />
                        <Row gutter={16}>
                          <Col className="gutter-row" span={6}>
                            <Form.Item
                              name="kycIn_Kyc"
                             
                              label="KYC"
                              style={{ marginBottom: "8px" }}
                            >
                              <span>{data.kyc}</span>
                          
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="KYCcompmany_Logo" label="Company Logo" style={{ marginBottom: "8px" }}>
                              <Image width={100} src={data.compmanyLogo} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <br />
                        {kycTabHeader.length > 1 ? <hr /> : null}
                      </div>
                    </Spin>
                  ))}
                </TabPane>

                <TabPane tab="Terms & Conditions" key="Terms & Conditions">
                  <h3>Terms & Conditions</h3>
                  <div style={{ padding: "8px" }}>
                  <Row>
                    <Col span={24} style={{ textAlign: "right", marginTop: "0.5%", marginBottom: "0.5%" }}>
                      <Button
                        size="small"
                        style={{
                          color: "white",
                          background: "#3E7A86",
                          fontSize: "12px",
                          float: "right",
                          marginBottom: "7.5px",
                        }}
                        onClick={() => {
                          openaddAddressModalTeamsAndCondition();
                        }}
                      >
                        ADD +
                      </Button>
                    </Col>
                  </Row> 
                  </div>
                  {termsAndConditiontab.map((data) => (
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>

                    <div style={{ padding: "8px" }}>
                      <Row>
                        <Col span={24} style={{ textAlign: "right", marginTop: "0.5%", marginBottom: "0.5%" }}>
                          <span style={{ float: "right" }}>
                            <Button onClick={() => editFieldsInFromTerms(data)} style={{ height: "30px", marginRight: "8px" }}>
                              <EditOutlined style={{ fontWeight: "600" }} />
                            </Button>
                            &nbsp;
                            <Button
                              style={{ height: "30px", marginRight: "8px" }}
                              onClick={() => {
                                deleteTeamsConditiondata(data.dvnCustomerAddressId);
                              }}
                            >
                              <i style={{ fontWeight: "600" }} className="lnr lnr-trash" />
                            </Button>
                          </span>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="Margin" label="Margin" style={{ marginBottom: "8px" }}>
                            <span>{data.margin}</span>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="DepositCommited" label="Deposit Commited" style={{ marginBottom: "8px" }}>
                            <span>{data.depositCommited} </span>
                          </Form.Item>
                        </Col>
                      

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="CreditPeriod" label="Credit Period" style={{ marginBottom: "8px" }}>
                            <span>{data.creditPeriod}</span>
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="ProjectionJwellerWiseTarget" label="Projection Jeweller Wise Target" style={{ marginBottom: "8px" }}>
                            <span>{data.projectionJwellerWiseTarget}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="storeWiseTarget" label="Store wise target" style={{ marginBottom: "8px" }}>
                            <span>{data.storeWiseTarget}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item label="Store Wise Prompt Payment	" style={{ marginBottom: "8px" }}>
                            <span>{data.storeWisePromptPayment}</span>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="agreementDate" label="Agreement Date" style={{ marginBottom: "8px" }}>
                            <span>{moment(data.agreementDate).format("YYYY-MM-DD")}</span>
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            name="Agreement_Sign"
                            valuePropName="checked"
                            label="Agreement Sign"
                            style={{ marginBottom: "8px" }}
                          >
                            <span>{data.agreementSign}</span>
                         
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      {termsAndConditiontab.length > 1 ? <hr /> : null}
                    </div>
                   </Spin>
                  ))}
                </TabPane>
                <TabPane tab="Regulatory" key="Regulatory">
                  <h3>Regulatory</h3> 
                  <div style={{ padding: "8px" }}>
                  <Row>
                    <Col span={24} style={{ textAlign: "right", marginTop: "0.5%", marginBottom: "0.5%" }}>
                      <Button
                        size="small"
                        style={{
                          color: "white",
                          background: "#3E7A86",
                          fontSize: "12px",
                          float: "right",
                          marginBottom: "7.5px",
                        }}
                        onClick={() => {
                          openaddAddressModalRegulatory();
                        }}
                      >
                        ADD +
                      </Button>
                    </Col>
                  </Row>
                  </div>
                  {RegulatoryTabHeader.map((data) => (
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>

                    <div style={{ padding: "8px" }}>
                    
                      <Row>
                        <Col span={24} style={{ textAlign: "right", marginTop: "0.5%", marginBottom: "0.5%" }}>
                          <span style={{ float: "right" }}>
                            <Button onClick={() => editFieldsInFromRegulatory(data)} style={{ height: "30px", marginRight: "8px" }}>
                              <EditOutlined style={{ fontWeight: "600" }} />
                            </Button>
                            &nbsp;
                            <Button
                              style={{ height: "30px", marginRight: "8px" }}
                              onClick={() => {
                                deleteRegulatoryForm(data.cBankDetailsId);
                              }}
                            >
                              <i style={{ fontWeight: "600" }} className="lnr lnr-trash" />
                            </Button>
                          </span>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item label="Bank Account No*" name="bankAccNumber" style={{ marginBottom: "8px" }}>
                            <span>{data.bankAccNumber}</span>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item label="Bank Name*" name="bankName" style={{ marginBottom: "8px" }}>
                            <span>{data.bankname}</span>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item label="Branch" name="branch" style={{ marginBottom: "8px" }}>
                            <span>{data.branch}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item label="IFSC Code" name="ifscCode" style={{ marginBottom: "8px" }}>
                            <span>{data.ifscCode}</span>
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item label="GST No" name="gstNo" style={{ marginBottom: "8px" }}>
                            <span>{data.gstNo}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item label="PAN No" name="pan_No" style={{ marginBottom: "8px" }}>
                            <span>{data.panNo}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            name="tdsApplicable"
                            label="TDS Applicable"
                            style={{ marginBottom: "8px" }}
                          >
                            <span>{data.tdsApplicable}</span>
                          
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            valuePropName="checked"
                            name="gstApplicable"
                            label="GST Applicable"
                            style={{ marginBottom: "8px" }}
                          >
                            <span>{data.gstApplicable}</span>
                            
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            valuePropName="checked"
                            name="incentivePromptPayment"
                            label="Incentive Prompt Payment	"
                            style={{ marginBottom: "8px" }}
                          >
                            <span>{data.incentivePromptPayment}</span>
                           
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            valuePropName="checked"
                            name="jwellerlevelPromtPayment"
                            label="Jeweller Level Prompt Payment"
                            style={{ marginBottom: "8px" }}
                          >
                            <span>{data.jwellerlevelPromtPayment}</span>
                           
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      {RegulatoryTabHeader.length > 1 ? <hr /> : null}
                    </div>
                    </Spin>
                  ))}
                </TabPane>
                <TabPane tab="Stock  Mix" key="Stock  Mix">
                  <h3>Stock Mix</h3>
                  <div style={{ padding: "8px" }}>
                  <Row>
                    <Col span={24} style={{ textAlign: "right", marginTop: "0.5%", marginBottom: "0.5%" }}>
                      <Button
                        size="small"
                        style={{
                          color: "white",
                          background: "#3E7A86",
                          fontSize: "12px",
                          float: "right",
                          marginBottom: "7.5px",
                        }}
                        onClick={() => {
                          openaddAddressModal();
                        }}
                      >
                        ADD +
                      </Button>
                    </Col>
                  </Row>
                  </div>
                  {stockMixHeader.map((data) => (
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>

                    <div style={{ padding: "8px" }}>
                    
                      <Row>
                        <Col span={24} style={{ textAlign: "right", marginTop: "0.5%", marginBottom: "0.5%" }}>
                          {/* {isStockEdit === 'Y' ? ( */}
                          {/* {displayInputStockMix ==="blook"?( */}
                          <span style={{ float: "right" }}>
                            <Button onClick={() => editFieldsInFromStockMix(data)} style={{ height: "30px", marginRight: "8px" }}>
                              <EditOutlined style={{ fontWeight: "600" }} />
                            </Button>
                            &nbsp;
                            <Button
                              style={{ height: "30px", marginRight: "8px" }}
                              onClick={() => {
                                deletestockmixtab(data.dOtherClassificationId);
                              }}
                            >
                              <i style={{ fontWeight: "600" }} className="lnr lnr-trash" />
                            </Button>
                          </span>
                          {/* ):null} */}
                          {/* ):null} */}
                        </Col>
                      </Row>

                      <div>
                        <Row gutter={16}>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="StockMixSmall" label="Small %*" style={{ marginBottom: "8px" }}>
                              <span>{data.small}</span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="StockMixMedium" label="Medium %*" style={{ marginBottom: "8px" }}>
                              <span>{data.medium}</span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="StockmixLarge" label="Large %*" style={{ marginBottom: "8px" }}>
                              <span>{data.large}</span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="stockmixExLarge" label="Ex.Large %*" style={{ marginBottom: "8px" }}>
                              <span>{data.exLarge}</span>
                            </Form.Item>
                          </Col>
                        </Row>
                        <br />
                        <Row gutter={16}>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="stockmixDEFVVS" label="DEF VVS %*" style={{ marginBottom: "8px" }}>
                              <span>{data.defVvs} </span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="stockmixDEFVS" label="DEF VS%*" style={{ marginBottom: "8px" }}>
                              <span>{data.defVs}</span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="stockMixDEFSI" label="DEF SI%*" style={{ marginBottom: "8px" }}>
                              <span>{data.defSi}</span>
                            </Form.Item>
                          </Col>

                          <Col className="gutter-row" span={6}>
                            <Form.Item name="stockmixGHVVS" label="GH VVS%*" style={{ marginBottom: "8px" }}>
                              <span>{data.defSi}</span>
                            </Form.Item>
                          </Col>
                        </Row>
                        <br />
                        <Row gutter={16}>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="stockMixGHVS" label="GH VS%*" style={{ marginBottom: "8px" }}>
                              <span>{data.ghVs}</span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={6}>
                            <Form.Item label="GH SI%*" name="stockmixGHSI" style={{ marginBottom: "8px" }}>
                              <span>{data.ghSi}</span>
                            </Form.Item>
                          </Col>

                          <Col className="gutter-row" span={6}>
                            <Form.Item name="stoclkmixIJKVVS" label="IJK VVS%*" style={{ marginBottom: "8px" }}>
                              <span>{data.ijkVvs}</span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="stockmixIJKVS" label="IJK VS%*" style={{ marginBottom: "8px" }}>
                              <span>{data.ijkVs}</span>
                            </Form.Item>
                          </Col>
                        </Row>
                        <br />
                        <Row gutter={16}>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="stockmixIJKSI" label="IJK SI%*" style={{ marginBottom: "8px" }}>
                              <span>{data.ijksi}</span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="stockmixDSD" label="DSD %*" style={{ marginBottom: "8px" }}>
                              <span>{data.dsd}</span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={6}>
                            <Form.Item name="stockmixDSJ" label="DSJ %*" style={{ marginBottom: "8px" }}>
                              <span>{data.dsj}</span>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                      <br />
                      {stockMixHeader.length > 1 ? <hr /> : null}
                    </div>
                   </Spin>
                  ))}
                </TabPane>

                <TabPane tab="Outlet" key="Outlet">
                  <h3>Outlet</h3>
                  <div style={{ padding: "8px" }}>
                  <Row>
                    <Col span={24} style={{ textAlign: "right", marginTop: "0.5%", marginBottom: "0.5%" }}>
                      <Button
                        size="small"
                        style={{
                          color: "white",
                          background: "#3E7A86",
                          fontSize: "12px",
                          float: "right",
                          marginBottom: "7.5px",
                        }}
                        onClick={() => {
                          openaddAddressModaloutlet();
                        }}
                      >
                        ADD +
                      </Button>
                    </Col>
                  </Row>
                  </div>
                  {outletHeader.map((data) => (
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>

                    <div style={{ padding: "8px" }}>
                      <Row>
                        <Col span={24} style={{ textAlign: "right", marginTop: "0.5%", marginBottom: "0.5%" }}>
                          <span style={{ float: "right" }}>
                            <Button onClick={() => editFieldsInFromOutlet(data)} style={{ height: "30px", marginRight: "8px" }}>
                              <EditOutlined style={{ fontWeight: "600" }} />
                            </Button>
                            &nbsp;
                            <Button
                              style={{ height: "30px", marginRight: "8px" }}
                              onClick={() => {
                                deleteOutlet(data.customerContactId);
                              }}
                            >
                              <i style={{ fontWeight: "600" }} className="lnr lnr-trash" />
                            </Button>
                          </span>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outletName" label="Outlet Name" style={{ marginBottom: "8px" }}>
                            <span>{data.outletName} </span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="NickName" label="Nick  Name" style={{ marginBottom: "8px" }}>
                            <span>{data.nickName} </span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="OutletAddress" label="Outlet Address" style={{ marginBottom: "8px" }}>
                            <span>{data.addressLine1}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outletcity" label="City" style={{ marginBottom: "8px" }}>
                            <span>{data.outletCity}</span>
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item label="Pincode" name="outlet_pinCode" style={{ marginBottom: "8px" }}>
                            <span>{data.pinCode}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outletState" label="State" style={{ marginBottom: "8px" }}>
                            <span>{data.stateName}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item label="Zone" style={{ marginBottom: "8px" }}>
                            <span>{data.zone}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item label="Tier" style={{ marginBottom: "8px" }}>
                            <span>{data.tier}</span>
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outletmobileNo" label="Phone No" style={{ marginBottom: "8px" }}>
                            <span>{data.mobileNo}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outletsarea" label="Area" style={{ marginBottom: "8px" }}>
                            <span>{data.area}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outletemail" label="Email" style={{ marginBottom: "8px" }}>
                            <span>{data.email}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item label="Market Name" name="outletMarketName" style={{ marginBottom: "8px" }}>
                            <span>{data.outletName}</span>
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="OutletStoreContactPersonName" label="Store Contact Person Name	" style={{ marginBottom: "8px" }}>
                            <span>{data.storeContactPersonName}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="OutletStoreContactPersonNo" label="Store Contact Person No	" style={{ marginBottom: "8px" }}>
                            <span>{data.storeContactPersonNo}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item label="Weekly Off" name="outletweeklyOff" style={{ marginBottom: "8px" }}>
                            <span>{data.weeklyOff}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item label="GST No" name="OutletgstNo" style={{ marginBottom: "8px" }}>
                            <span>{data.gstNo}</span>
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="OutletOnboardingDate" label="Outlet Onboarding Date" style={{ marginBottom: "8px" }}>
                            <span>{moment(data.outletOnboardingDate).format("YYYY-MM-DD")}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="OutletClosureDate" label="Outlet Closure Date " style={{ marginBottom: "8px" }}>
                            <span>{moment(data.outletClosureDate).format("YYYY-MM-DD")}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="Outlet Sales Rep" label="Outlet Sales Rep" style={{ marginBottom: "8px" }}>
                            <span>{moment(data.salesRepStartDate).format("YYYY-MM-DD")}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outletSalesRepStartDate" label="Sales  Rep  Start Date" style={{ marginBottom: "8px" }}>
                            <span>{moment(data.salesRepStartDate).format("YYYY-MM-DD")}</span>
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outletSalesRepEndDate" label="Sales Rep End Date" style={{ marginBottom: "8px" }}>
                            <span>{moment(data.salesRepEndDate).format("YYYY-MM-DD")}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6} style={{ marginTop: "-16px" }}>
                          <Form.Item name="pricelistHandoverContactPersonName" label="Price List Handover Contact Person Name" style={{ marginBottom: "8px" }}>
                            <span>{data.pricelistHandoverContactPersonName}</span>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="PHandoverContactPersonNo" label="Price List Hand Over Contact No" style={{ marginBottom: "8px" }}>
                            <span>{data.pHandoverContactPersonNo}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6} style={{ marginTop: "-16px" }}>
                          <Form.Item name="outletStockConfirmationContactName" label="Stock Confirmation  Contact Person Name" style={{ marginBottom: "8px" }}>
                            <span>{data.stockConfirmationContactName}</span>
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6} style={{ marginTop: "-18px" }}>
                          <Form.Item name="OutletStockConfirmationContactNo" label="Stock Confirmation Contact Person No" style={{ marginBottom: "8px" }}>
                            <span>{data.stockConfirmationContactNo}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            valuePropName="checked"
                          
                            name="outlet_kyc"
                            label="KYC"
                            style={{ marginBottom: "8px" }}
                          >
                            <span>{data.kyc}</span>
                           
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="OUTLETAuthorisedOutlet" label="Authorised Outlet" style={{ marginBottom: "8px" }}>
                            <Checkbox style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="OutletPic_outlet" label="Outlet Pic" style={{ marginBottom: "8px" }}>
                            <Image width={100} src={data.outletPic} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            valuePropName="checked"
                            //initialValue={data.displayDone === "Y" ? true : false}
                            name="outlet_displayDone"
                            label="Display Done"
                            style={{ marginBottom: "8px" }}
                          >
                            <span>{data.displayDone}</span>
                            {/* <Checkbox
                              // checked={data.displayDone==="Y"?true:false}

                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox> */}
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="OutletTrialFromPeriod" label="Trial Period  From  Date" style={{ marginBottom: "8px" }}>
                            <span>{moment(data.trialFromPeriod).format("YYYY-MM-DD")}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="OutletTrialToPeriod" label="Trial Period To Date" style={{ marginBottom: "8px" }}>
                            <span>{moment(data.trialToPeriod).format("YYYY-MM-DD")}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="OutletTotalStock" label="Total Stock" style={{ marginBottom: "8px" }}>
                            <span>{data.totalStock}</span>
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="OutletProjectionJwellerWiseTarget" label="Projection Jweller Wise Target" style={{ marginBottom: "8px" }}>
                            <span>{data.projectionJwellerWiseTarget}</span>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item label="Store Wise Target" name="OutletStoreWiseTarget" style={{ marginBottom: "8px" }}>
                            <span>{data.storeWiseTarget}</span>
                          </Form.Item>
                        </Col>
                      </Row>
                      {outletHeader.length > 1 ? <hr /> : null}
                    </div>
                   </Spin>
                  ))}
                </TabPane>
              </Tabs>

              <Modal
                visible={visibleForKYCEdit}
                title="Edit KYC"
                width="80%"
                onCancel={handleCancelAllModal}
                footer={[
                  <span>
                    <Button style={{ background: "#E9E9E9", color: "#384147", fontSize: "14px" }} onClick={handleCancelAllModal}>
                      Cancel
                    </Button>
                    <Button style={{ background: "#3E7A86", color: "#ffffff", fontSize: "14px" }} key="submit" onClick={getKYCupset}>
                      Confirm
                    </Button>
                  </span>,
                ]}
              >
                <Form
                  form={kycForm}
                  name="dynamic_form_kyc"
                  layout="vertical"
               
                  autoComplete="off"
                >
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>
                    <div style={{ padding: "8px" }}>
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="KycpjName" label="PJ Name" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="kycNickName" label="Nick Name" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="kycOnboarding_Date" label="PJ Onboarding Date" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="Kycpj_ClosureDate" label="PJ Closure Date" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <p />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="Kycpj_Address" label="PJ Address" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="kyc_city" label="City" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="kyc_state" label="State" style={{ marginBottom: "8px" }}>
                            <Select
                              allowClear
                              showSearch
                              placeholder="state"
                              dropdownMatchSelectWidth={false}
                              dropdownStyle={{ width: 228 }}
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              style={{ width: "100%" }}
                            >
                              {stateKycData.map((option, index) => (
                                <Option key={`${index}-${option.Name}`} value={option.RecordID}>
                                  {option.Name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="kyc_zone" label="Zone" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <p />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="Country_Kyc" label="Country" style={{ marginBottom: "8px" }}>
                            <Select onSelect={onSelectKycCountry} onFocus={getkycCountryData} style={{ borderLeft: "none", borderTop: "none", borderRight: "none" }}>
                              {/* {console.log("countryKYCData",countryKYCData)} */}

                              {countryKYCData.map((data, index) => (
                                <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                                  {data.Name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="kycpin_code" label="PinCode" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <p />
                      <h3>Owner Details (Field Group)</h3>
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="kyc_pjsalesRep" label="PJ Sales Rep" style={{ marginBottom: "8px" }}>
                            <Select
                              allowClear
                              showSearch
                              placeholder="state"
                              dropdownMatchSelectWidth={false}
                              dropdownStyle={{ width: 228 }}
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              style={{ width: "100%" }}
                             
                            >
                              {salesRepData.map((option, index) => (
                                <Option key={`${index}-${option.Name}`} value={option.RecordID}>
                                  {option.Name}
                                </Option>
                              ))}
                            </Select>
                           
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="kycpj_SalesRepStartDate" label="Sales Rep Start Date	" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="kycpjSalesRepEndDate" label="Sales Rep End Date	" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="kycpjOwnerName" label="Owner Name" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="kycmobileNo" label="Mobile No" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="kycemail" label="Email" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="kyc_Birth_Date" label="Birth Date" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="kyc_Anniversary_Date" label="Anniversary Date" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="Kyc_Address" label="Address" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="KycOwnerPic" label="Owner Pic" style={{ marginBottom: "8px" }}>
                            <Image />
                            <Upload
                              action="https://sapp.mycw.in/image-manager/uploadImage"
                              listType="picture"
                              name="image"
                              maxCount={1}
                            >
                              <Button icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="kyc_sor" label="SOR" style={{ marginBottom: "8px" }}>
                            <Checkbox checked={isInsorInKyc} onChange={onChangechecbox} style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="date_of_Establishement" label="Date of Establishment" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="kyc_InKyc" label="KYC" style={{ marginBottom: "8px" }}>
                            <Checkbox checked={isInKYCKYC} onChange={onChangechecbox} style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="kyccompmany_Logo" label="Company Logo" style={{ marginBottom: "8px" }}>
                           
                            <Image />
                            <Upload
                              action="https://sapp.mycw.in/image-manager/uploadImage"
                              listType="picture"
                              name="image"
                              maxCount={1}
                            >
                              <Button icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                    </div>
                  </Spin>
                </Form>
              </Modal>
              <Modal
                visible={visibleForStockEdit}
                title="Edit Stock"
                width="80%"
                onCancel={handleCancelAllModal}
                footer={[
                  <span>
                    <Button style={{ background: "#E9E9E9", color: "#384147", fontSize: "14px" }} onClick={handleCancelAllModal}>
                      Cancel
                    </Button>
                    <Button style={{ background: "#3E7A86", color: "#ffffff", fontSize: "14px" }} key="submit" onClick={getStockMixUpset}>
                      Confirm
                    </Button>
                  </span>,
                ]}
              >
                <Form form={stockmixForm} name="basic" layout="vertical" autoComplete="off">
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>
                    <div>
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="small" label="Small %*" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="medium" label="Medium %*" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="Large" label="Large %*" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="ExLarge" label="Ex.Large %*" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="DEFVVS" label="DEF VVS %*" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="DEFVS" label="DEF VS%*" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="DEFSI" label="DEF SI%*" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="GHVVS" label="GH VVS%*" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="GHVS" label="GH VS%*" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="GHSI" label="GH SI%*" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="IJKVVS" label="IJK VVS%*" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="IJKVS" label="IJK VS%*" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="IJKSI" label="IJK SI%*" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="DSD" label="DSD %*" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="DSJ" label="DSJ %*" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>

                      <br />
                    </div>
                  </Spin>
                </Form>
              </Modal>

              <Modal
                visible={visibleForRegulatoryEdit}
                title="Edit Regulatory"
                width="80%"
                onCancel={handleCancelAllModal}
                footer={[
                  <span>
                    <Button style={{ background: "#E9E9E9", color: "#384147", fontSize: "14px" }} onClick={handleCancelAllModal}>
                      Cancel
                    </Button>
                    <Button style={{ background: "#3E7A86", color: "#ffffff", fontSize: "14px" }} key="submit" onClick={getRegulatoryupset}>
                      Confirm
                    </Button>
                  </span>,
                ]}
              >
                <Form form={regulatoryForm} name="basic" layout="vertical" autoComplete="off">
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>
                    <div>
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="BankAccountNo" label="Bank Account No*" rules={[{ required: true, message: "Missing Bank Account No" }]} style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="bank_Name" label="Bank Name*" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="bra_nch" label="Branch" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="ifsc_Code" label="IFSC Code" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="Gst_No" label="GST No" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="panNo" label="PAN No" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            name="tdsApplicable"
                            label="TDS Applicable"
                            style={{ marginBottom: "8px" }}
                          >
                            <Checkbox checked={istdsAPPlication} onChange={onChangechecbox} style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            name="gstApplicable"
                            label="GST Applicable"
                            style={{ marginBottom: "8px" }}
                          >
                            <Checkbox checked={isGSTApplicable} onChange={OnGSTApplicable} style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            name="incentivePromptPayment"
                            label="Incentive Prompt Payment	"
                            style={{ marginBottom: "8px" }}
                          >
                            <Checkbox
                              checked={isIncentivePromptPayment}
                              onChange={onIncentivePromptPayment}
                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            name="jwellerlevelPromtPayment"
                            label="Jeweller Level Prompt Payment"
                            style={{ marginBottom: "8px" }}
                          >

                            <Checkbox
                              checked={isJewellerLevelPromptPayment}
                              onChange={onJewellerLevelPromptPayment}
                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </Spin>
                </Form>
              </Modal>
              <Modal
                visible={visibleForTermsAndConditionEdit}
                title="Edit Terms & Conditions"
                width="80%"
                onCancel={handleCancelAllModal}
                footer={[
                  <span>
                    <Button style={{ background: "#E9E9E9", color: "#384147", fontSize: "14px" }} onClick={handleCancelAllModal}>
                      Cancel
                    </Button>
                    <Button style={{ background: "#3E7A86", color: "#ffffff", fontSize: "14px" }} key="submit" onClick={getTermsConditionsupset}>
                      Confirm
                    </Button>
                  </span>,
                ]}
              >
                <Form form={termsAndConditionForm} name="basic" layout="vertical" autoComplete="off">
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>
                    <div>
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="margin" label="Margin" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="depositCommited" label="Deposit Commited" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="creditPeriod" label="Credit Period" style={{ marginBottom: "8px" }}>
                            <Select onSelect={onSelectCredit} onFocus={getCreditData} style={{ borderLeft: "none", borderTop: "none", borderRight: "none" }}>
                              {creditData.map((data, index) => (
                                <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                                  {data.Name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="Projection_JwellerWiseTarget" label="Projection Jeweller Wise Target" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="store_WiseTarget" label="Store wise target" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="storeWise_PromptPayment" label="Store Wise Prompt Payment	" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            name="agreement_Date"
                            label="Agreement Date"
                            style={{ marginBottom: "8px" }}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="Agreement_Sign" label="Agreement Sign" style={{ marginBottom: "8px" }}>
                            <Checkbox checked={isAgreementSign} onChange={agreementSign} style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </Spin>
                </Form>
              </Modal>
              <Modal
                visible={visibleForOutletEdit}
                title="Edit Outlet"
                width="80%"
                onCancel={handleCancelAllModal}
                footer={[
                  <span>
                    <Button style={{ background: "#E9E9E9", color: "#384147", fontSize: "14px" }} onClick={handleCancelAllModal}>
                      Cancel
                    </Button>
                    <Button style={{ background: "#3E7A86", color: "#ffffff", fontSize: "14px" }} key="submit" onClick={getoutletUpset}>
                      Confirm
                    </Button>
                  </span>,
                ]}
              >
                <Form form={outletForm} name="basic" layout="vertical" autoComplete="off">
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>
                    <div style={{ padding: "8px" }}>
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_Name" label="Outlet Name" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_nickName" label="Nick  Name" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_addressLine1" label="Outlet Address" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_City" label="City" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_pinCode" label="Pin" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="stateNameoutlet" label="State" style={{ marginBottom: "8px" }}>
                            <Select onSelect={onSelectStateOutlet} onFocus={getStateOutletData} style={{ borderLeft: "none", borderTop: "none", borderRight: "none" }}>
                              {/* {console.log("stateOutletData",stateOutletData)} */}
                              {stateOutletData.map((data, index) => (
                                <Option key={data.RecordID} value={data.RecordID} title={data.RecordID}>
                                  {data.Name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_zone" label="Zone" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_tier" label="Tier" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_mobileNo" label="Phone No" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_sarea" label="Area" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_email" label="Email" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_MarketName" label="Market Name" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_storeContactPersonName" label="Store Contact Person Name	" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_storeContactPersonNo" label="Store Contact Person No	" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_weeklyOff" label="Weekly Off" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_gstNo" label="GST No" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_OnboardingDate" label="Outlet Onboarding Date" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_ClosureDate" label="Outlet Closure Date " style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_salesRep" label="Outlet Sales Rep" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_salesRepStartDate" label="Sales  Rep  Start Date" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_salesRepEndDate" label="Sales Rep End Date" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6} style={{ marginTop: "-16px" }}>
                          <Form.Item name="pricelist_HandoverContact_PersonName" label="Price List Handover Contact Person Name" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="pHandover_ContactPersonNo" label="Price List Hand Over Contact No" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6} style={{ marginTop: "-16px" }}>
                          <Form.Item name="outlet_stockConfirmationContactName" label="Stock Confirmation  Contact Person Name" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6} style={{ marginTop: "-18px" }}>
                          <Form.Item name="outlet_stockConfirmationContactNo" label="Stock Confirmation Contact Person No" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_kyc" label="KYC" style={{ marginBottom: "8px" }}>
                            <Checkbox

                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="OUTLETAuthorisedOutlet" label="Authorised Outlet" style={{ marginBottom: "8px" }}>
                            <Checkbox style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="OutletPic_outlet" label="Outlet Pic" style={{ marginBottom: "8px" }}>
                            <Image />
                            <Upload
                              action="https://sapp.mycw.in/image-manager/uploadImage"
                              listType="picture"
                              name="image"
                              
                              maxCount={1}
                            >
                              <Button icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_displayDone" label="Display Done" style={{ marginBottom: "8px" }}>
                            <Checkbox checked={isdisplayDone} onChange={onChangechecbox} style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_trialFromPeriod" label="Trial Period  From  Date" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_trialToPeriod" label="Trial Period To Date" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_totalStock" label="Total Stock" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            name="outlet_projectionJwellerWiseTarget"
                            label="Projection Jweller Wise Target"
                            rules={[
                              {
                                required: true,
                                message: "Please Add Projection Jweller Wise Target !",
                              },
                            ]}
                            style={{ marginBottom: "8px" }}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outlet_storeWiseTarget" label="Store Wise Target" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </Spin>
                </Form>
              </Modal>
              <Modal
                visible={visibleForCustomerInfoEdit}
                title="CustomerInfo"
                width="80%"
                onCancel={handleCancelAllModal}
                footer={[
                  <span>
                    <Button style={{ background: "#E9E9E9", color: "#384147", fontSize: "14px" }} onClick={handleCancelAllModal}>
                      Cancel
                    </Button>
                    <Button style={{ background: "#3E7A86", color: "#ffffff", fontSize: "14px" }} key="submit" onClick={getFinish}>
                      Confirm
                    </Button>
                  </span>,
                ]}
              >
                <Form form={CustomerInfoForm} name="basic" layout="vertical" autoComplete="off">
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>
                    <div style={{ padding: "8px" }}>
                      <h3>Customer Info</h3>

                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="bUnitName" label="Business Unit*" style={{ marginBottom: "8px" }}>
                            <Select
                              allowClear
                              showSearch
                              placeholder="Business Unit"
                              dropdownMatchSelectWidth={false}
                              dropdownStyle={{ width: 228 }}
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              style={{ width: "100%" }}
                             
                            >
                           
                              {bunitData.map((option, index) => (
                                <Option key={`${index}-${option.bUnitName}`} value={option.csBunitId}>
                                  {option.bUnitName}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="pjcode" label="PJ Code*" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="pjName" label="PJ Name*" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customercategory" label="Customer Category" style={{ marginBottom: "8px" }}>
                            <Select
                              allowClear
                              showSearch
                              placeholder="customercategory"
                              dropdownMatchSelectWidth={false}
                              dropdownStyle={{ width: 228 }}
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              style={{ width: "100%" }}
                            >
                              {customerCategoryData.map((option, index) => (
                                <Option key={`${index}-${option.Name}`} value={option.RecordID}>
                                  {option.Name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <p style={{ marginBottom: "10px" }} />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="gstNo" label="GST No" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="currency" label="Currency" style={{ marginBottom: "8px" }}>
                            <Select
                              allowClear
                              showSearch
                              placeholder="Business Unit"
                              dropdownMatchSelectWidth={false}
                              dropdownStyle={{ width: 228 }}
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              style={{ width: "100%" }}
                            >
                              {CurrencyData.map((option, index) => (
                                <Option key={`${index}-${option.Name}`} value={option.RecordID}>
                                  {option.Name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="nickname" label="Nick Name" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="invoicingname" label="Invoicing Name" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <p style={{ marginBottom: "10px" }} />

                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="Invoicing" label="Invoicing (Y/N)" style={{ marginBottom: "8px" }}>
                            <Checkbox
                              checked={isInvoicing}
                              onChange={onChangeCheckBOx}
                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="invoicingaddress" label="Invoicing Address" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="CSAlimit" label="CSA Limit" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="ASSLimit" label="ASS Limit" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <p style={{ marginBottom: "10px" }} />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="ASSStartDate" label="ASS Start Date" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="ASSEndDate" label="ASS End Date" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customertotalconsignmentStock" label="Total Consignment Stock (TCS)" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customeroutrightstock" label="Outright Stock (OS)" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <p style={{ marginBottom: "10px" }} />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoTotalStock" label="Total  Stock" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="paymentterms" label="Payment Terms" style={{ marginBottom: "8px" }}>
                            <Select
                              allowClear
                              showSearch
                              placeholder="Business Unit"
                              dropdownMatchSelectWidth={false}
                              dropdownStyle={{ width: 228 }}
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              style={{ width: "100%" }}
                            >
                              {paymentData.map((option, index) => (
                                <Option key={`${index}-${option.Name}`} value={option.RecordID}>
                                  {option.Name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <p style={{ marginBottom: "10px" }} />
                      <h3>More Information</h3>
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfopjtype" label="PJ Type" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerinfopjgroup" label="PJ Group" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoPJClosureDate" label="PJ Closure Date" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoPJOnboardingDate" label="PJ Onboarding Date" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <p style={{ marginBottom: "10px" }} />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoownername" label="Owner Name" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerinfocity" label="City" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfostate" label="State" style={{ marginBottom: "8px" }}>
                            <Select
                              allowClear
                              showSearch
                              placeholder="state"
                              dropdownMatchSelectWidth={false}
                              dropdownStyle={{ width: 228 }}
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              style={{ width: "100%" }}
                            >
                              {stateData.map((option, index) => (
                                <Option key={`${index}-${option.Name}`} value={option.RecordID}>
                                  {option.Name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfozone" label="Zone" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <p style={{ marginBottom: "10px" }} />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoEmail" label="Email" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerinfomobileno" label="Mobile No" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoCountry" label="Country" style={{ marginBottom: "8px" }}>
                            <Select
                              allowClear
                              showSearch
                              placeholder="Country"
                              dropdownMatchSelectWidth={false}
                              dropdownStyle={{ width: 228 }}
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              style={{ width: "100%" }}

                            >
                              {countryData.map((option, index) => (
                                <Option key={`${index}-${option.Name}`} value={option.RecordID}>
                                  {option.Name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="invoiceFormate" label="invoice Formate" style={{ marginBottom: "8px" }}>
                            <Select
                              allowClear
                              showSearch
                              placeholder="invoice Formate"
                              dropdownMatchSelectWidth={false}
                              dropdownStyle={{ width: 228 }}
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              style={{ width: "100%" }}

                            >
                              {invoiceFormateData.map((option, index) => (
                                <Option key={`${index}-${option.Name}`} value={option.RecordID}>
                                  {option.Name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <p style={{ marginBottom: "10px" }} />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfopincode" label="Pincode" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerinfoWebSiteaddress" label="WebSite Address" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <p />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoCompanyLogo" label="Company Logo" style={{ marginBottom: "8px" }}>
                            <Image />
                            <Image />
                            <Upload action="https://sapp.mycw.in/image-manager/uploadImage" listType="picture" name="image" maxCount={1}>
                              <Button icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfownerPic" label="Owner Pic" style={{ marginBottom: "8px" }}>
                            <Image />
                            <Upload action="https://sapp.mycw.in/image-manager/uploadImage" listType="picture" name="image" maxCount={1}>
                              <Button icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                      <p style={{ marginBottom: "10px" }} />
                      <h3>PJ Sales Distribution In(%)</h3>
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfosolitairejewellery" label="Solitaire Jewellery" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerinfosmalldiamondjewellery" label="Small Diamond Jewellery" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoGoldJewellery" label="Gold Jewellery" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoluxurylifestyleitems" label="Luxury & Lifestyle Items" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <p style={{ marginBottom: "10px" }} />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoOthers" label="Others" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <h3>Other Showroom Details (Mention Market Name)</h3>
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoRegisteredWithDS" label="Registered With DS" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoUnRegisteredWithDS" label="Un-Registered With DS" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </Spin>
                </Form>
              </Modal>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CustomWindowHeader;
