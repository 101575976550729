import React, { useState, useEffect } from "react";
import { Card, Tabs, Row, Col, Form, Select, Button, message,Checkbox, Spin,Input,Space,Modal } from "antd";
import { LoadingOutlined ,MinusCircleOutlined, PlusOutlined,PlusCircleOutlined } from "@ant-design/icons";
import { v4 as uuid } from "uuid";
import { useGlobalContext } from "../../../lib/storage";
import Axios from "axios";

import { serverUrl } from "../../../constants/serverConfig";
import "antd/dist/antd.css";
import "../../../styles/antd.css";

const { TabPane } = Tabs;
const { Option } = Select;

const DataLoadNewConfig = () => {
  const { globalStore } = useGlobalContext();
  const Themes = globalStore.userData.CW360_V2_UI;
  // End products tab

  const [loading, setLoading] = useState(false);
  const [visible,setVisible] = useState(false)
  const [mainTabData,setMainTabData] = useState([])
  const [openAddToFieldsModal,setOpenAddToFieldsModal] = useState(false)
  const [activeTabId,setActiveTabId] = useState(null)

  const [mainForm] = Form.useForm();
  const [addTabForm] = Form.useForm();
  const [addFieldsForm] = Form.useForm();

  const saveData = () => {
    mainForm.submit();     
  };

  const onFinish = async () => { 
  
  };

  const addTabsModalVisible = () =>{
      setVisible(true)
  }

  const handleCancel = () =>{
    setVisible(false)
  }
  
  const handleOk = () =>{
    addTabForm.validateFields().then(values => {
        const tabName = values.tabName
        const uniqId = uuid()
          .replace(/-/g, "")
          .toUpperCase();
          const newObj = {
              tabName:tabName,
              tabKey :uniqId
          }
          setMainTabData([...mainTabData,newObj])
          addTabForm.resetFields()
          setVisible(false)
        // Do something with value
      });
    // console.log("tabFormData=====================>",tabFormData)
    // setVisible(false)
  }
  console.log("mainTabData===============>",mainTabData)

  const addFieldsToTabs = (tabId) =>{
    setOpenAddToFieldsModal(true)
  }

  const AddToFieldshandleOk = () =>{
    addFieldsForm.validateFields().then(values => {
        console.log("values========================>",values)
    })
  }

  const AddToFieldshandleCancel = () =>{

  }

  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: "52px" }} spin />} spinning={loading}>
      <div>
        <Row>
          <Col span={12}>
            <h2>Initial Data Load Configuration</h2>
          </Col>
          <Col span={10} />
          <Col span={2}>
            <Button style={Themes.contentWindow.ListWindowHeader.newButtonForlist} type="primary" onClick={saveData}>
              Save
            </Button>
            &nbsp;
          </Col>
         
        </Row>
        <Row>
          <Col span={24}>
            <Form layout="vertical" form={mainForm} name="control-hooks" /* onValuesChange={getAllFormValues} */ onFinish={onFinish} /* onFinishFailed={onFinishFailed} */>
              <Card>
                <Tabs tabBarExtraContent={<Button onClick={addTabsModalVisible} size ="small">Add</Button>} /* onChange={callBackTabs} */>
                  {mainTabData.map((data)=>(
                      <TabPane tab={data.tabName} key={data.tabKey}>
                       <Row>
                           <Col span={24} style={{textAlign:'right'}}>
                                <Button onClick={()=>addFieldsToTabs(data.tabKey)} size="small">Add Fields</Button>
                           </Col>
                       </Row>
                      <h2>This is ===>{data.tabName}</h2>
                     </TabPane>
                  ))}
                </Tabs>
              </Card>
            </Form>
          </Col>
        </Row>
      </div>
      <Modal title="Add Tabs" visible={visible} onOk={handleOk} onCancel={handleCancel}>
      <Card style={{padding:'0px',border:'none'}} bodyStyle={{padding:'0px'}}>
        <Form layout="vertical" form={addTabForm} name="addTab_hooks">
             <Row>
                 <Col className="gutter-row" span={24}>
                    <Form.Item label="Name" name="tabName" rules={[
                            {
                                required: true,
                                message: 'Please input tab name!',
                            },
                            ]}>
                        <Input />
                    </Form.Item>
                 </Col>
             </Row>
             <br />
        </Form>
      </Card>
      </Modal>
      <Modal title="Add Fields" visible={openAddToFieldsModal} onOk={AddToFieldshandleOk} onCancel={AddToFieldshandleCancel}>
      <Card style={{padding:'0px',border:'none'}} bodyStyle={{padding:'0px'}}>
        <Form layout="vertical" form={addFieldsForm} name="addFields_hooks">
             <Row gutter={16}>
                 <Col className="gutter-row" span={12}>
                    <Form.Item label="Field Name" name="fieldName" rules={[
                            {
                                required: true,
                                message: 'Please input field name!',
                            },
                            ]}>
                        <Input />
                    </Form.Item>
                 </Col>
                 <Col className="gutter-row" span={12}>
                    <Form.Item label="Field Reference" name="fieldReference" rules={[
                            {
                                required: true,
                                message: 'Please input field reference!',
                            },
                            ]}>
                        <Input />
                    </Form.Item>
                 </Col>
                 </Row>
                 <br />
                 <Row gutter={16}>
                 <Col className="gutter-row" span={12}>
                    <Form.Item label="Is Enable" name="isEnable" valuePropName="checked">
                        <Checkbox />
                    </Form.Item>
                 </Col>
                 <Col className="gutter-row" span={12}>
                 <Form.Item label="Is Mandetory" name="isMandetory" valuePropName="checked">
                        <Checkbox />
                    </Form.Item>
                 </Col>
                 </Row>
             <br />
        </Form>
      </Card>
      </Modal>
    </Spin>
  );
};

export default DataLoadNewConfig;
