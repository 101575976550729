import React from "react";
import {Row,Col,Card,Checkbox,Form,Input} from 'antd'

const PurchaseInvoice = (props) => {
  const propsValues = props.purchaseInvoiceValues;
  console.log("propsValues====================>",propsValues)
  return (
    <div style={{ padding: "8px" }}>
      <h3>Purchase Invoice</h3>
      {propsValues.length > 0 ?
      <div>
      <Row gutter={16}>
        {/* <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
            <Row>
              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="Business Unit" name="piBusinessUnit" valuePropName="checked" initialValue={propsValues[0].enabledField === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="isMandatory" name="piBusinessUnitisMandatory" valuePropName="checked" initialValue={propsValues[0].isMandatory === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <br />
          </Card>
        </Col> */}
        {/* <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
            <Row>
              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="Invoice Date" name="piInvoiceDate" valuePropName="checked" initialValue={propsValues[1].enabledField === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="isMandatory" name="piInvoiceDateisMandatory" valuePropName="checked" initialValue={propsValues[1].isMandatory === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <br />
          </Card>
        </Col> */}
        <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
            <Row>
              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="Supplier Code" name="piSupplierCode" valuePropName="checked" initialValue={propsValues[0].enabledField === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="isMandatory" name="piSupplierCodeisMandatory" valuePropName="checked" initialValue={propsValues[0].isMandatory === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <br />
          </Card>
        </Col>
        <Col className="gutter-row" span={6}>
          <Card style={{ border: "1px solid" }}>
            <Row>
              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="Outstanding" name="piOutstanding" valuePropName="checked" initialValue={propsValues[1].enabledField === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="isMandatory" name="piOutstandingisMandatory" valuePropName="checked" initialValue={propsValues[1].isMandatory === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <br />
          </Card>
        </Col>
        <Col className="gutter-row" span={12}>
          <Card style={{ border: "1px solid" }}>
            <Row>
              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="ProductId" name="mProductId" initialValue={propsValues[2].value}>
                  <Input  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }} />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={2} />
              <Col className="gutter-row" span={10}>
                <Form.Item label="isMandatory" name="mProductIdisMandatory" valuePropName="checked" initialValue={propsValues[2].isMandatory === "Y" ? true : false}>
                  <Checkbox  style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <br />
          </Card>
        </Col>
      </Row>
      
      </div>
      :null}
    </div>
    
  );
};

export default PurchaseInvoice;
