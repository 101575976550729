/* eslint-disable */
import React,{useState,useEffect} from 'react'
import {Card,Row,Col,Button,Form,Select,Spin,Tooltip,Table,message,Input,DatePicker} from 'antd'
import { LoadingOutlined,DownloadOutlined } from "@ant-design/icons";
import { ExportToCsv } from 'export-to-csv'
import moment from 'moment'
import Axios from 'axios'
import { useGlobalContext } from "../../../lib/storage";
import { getUpsertSupplier,getUpsertRetailCustomer,getUpsertCustomers,getUpsertProductCatalouge,getUpsertStockOpeningBalance,getRoleBusinessUnit,getUpsertImportedProducts,getUpsertPurchaseInvoice,getUpsertSalesInvoice } from "../../../services/custom";
import {serverUrl} from '../../../constants/serverConfig'
import "antd/dist/antd.css";
import "../../../styles/antd.css";

const {Option} = Select

const InitialDataLoad = (props) => {
const { globalStore } = useGlobalContext();
const Themes = globalStore.userData.CW360_V2_UI;
const [bunitData,setBunitData] = useState([])
const [loading,setLoading] = useState(false)
const [productConfigjson,setProductConfigjson] = useState({})
const [dataLoadType,setDataLoadType] = useState(null)
const [suppliersCols,setSuppliersCols] = useState([])
const [supplierData,setSupplierData] = useState([])
const [retailCustomerCols,setRetailCustomerCols] = useState([])
const [retailCustomerData,setRetailCustomerData] = useState([])
const [customerCols,setCustomerCols] = useState([])
const [customerData,setCustomerData] = useState([])
const [productCatalougeColumns,setProductCatalougeColumns] = useState([])
const [productCatalougeData,setProductCatalougeData] = useState([])
const [stockOpeningColumns,setStockOpeningColumns] = useState([])
const [stockOpeningData,setStockOpeningData] = useState([])
const [productColumns,setProductColumns] = useState([])
const [productData,setProductData] = useState([])
const [purchaseInvoiceColumns,setPurchaseInvoiceColumns] = useState([])
const [purchaseInvoiceData,setPurchaseInvoiceData] = useState([])
const [mProductIdForPurchaseInvoice,setMproductIdForPurchaseInvoice] = useState(null)
const [salesInvoiceColumns,setSalesInvoiceColumns] = useState([])
const [mproductIdForSalesInvoice,setMproductIdForSalesInvoice] = useState(null)
const [salesInvoiceData,setSalesInvoiceData] = useState([])


const [headerform] = Form.useForm()

useEffect(() => {
  getBusinessUnit()
 }, []);

 const getBusinessUnit = async () => {
  const userData = JSON.parse(window.localStorage.getItem("userData"));
  const businessUnitResponse = await getRoleBusinessUnit(userData.user_id);
  setBunitData(businessUnitResponse.userBunit);
};

 const onChageDataLoadType = (e,data) =>{
  setSuppliersCols([])
  setRetailCustomerCols([])
   const key = data.key
  if(e === "Products"){
    setDataLoadType(e)
    getConfigurations(key)
    // getProductsConfigJsonData()
  }else if(e ==="Suppliers"){
    setDataLoadType(e)
    getConfigurations(key)
  }else if(e === "Retail Customers"){
    setDataLoadType(e)
    getConfigurations(key)
  }else if(e === "Customers"){
    setDataLoadType(e)
    getConfigurations(key)
  }else if(e === "Product Catalogue"){
    setDataLoadType(e)

  const catalouge=  [
      {
        title: 'Sku',
        dataIndex: 'sku',
        width: 50,
        ellipsis: true,
      },
      {
        title: 'Catalouge Name',
        dataIndex: 'productCatalougeName',
        width: 150,
        ellipsis: true,
      }
    ]
    setProductCatalougeColumns(catalouge)
  }else if(e === "Stock Opening Balance"){
    setDataLoadType(e)
    getConfigurations(key)
  }else if(e === "Purchase Invoices"){
    setDataLoadType(e)
    getConfigurations(key)
  }else if(e === "Sales Invoices"){
    setDataLoadType(e)
    getConfigurations(key)
  }
 }

 const getProductsConfigJsonData = async () => {
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const getAppSetupMutation = {
        query: `query{
            getDataLoadConfig(dataLoad:"Prd")
            {
                dataLoadConfigId
                dataLoadType
                configJson
            }
          }`,
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      };

      const serverResponse = await Axios.post(serverUrl, getAppSetupMutation, { headers: headers }, { async: true }, { crossDomain: true });
      const appSetupResponse = serverResponse.data.data.getDataLoadConfig;  
      if (appSetupResponse.length > 0) {
        const appSetupRecord = appSetupResponse[0];
        const { configJson } = appSetupRecord;
        const productParsedConfigJSON = JSON.parse(configJson);
        setProductConfigjson(productParsedConfigJSON)
      }
 }

 const getConfigurations = async (key) =>{
  setLoading(true)
  const newToken = JSON.parse(localStorage.getItem("authTokens"));
  const getAppSetupMutation = {
    query: `query{
        getDataLoadConfig(dataLoad:"${key}")
        {
            dataLoadConfigId
            dataLoadType
            configJson
        }
      }`,
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `bearer ${newToken.access_token}`,
  };

  const serverResponse = await Axios.post(serverUrl, getAppSetupMutation, { headers: headers }, { async: true }, { crossDomain: true });
  const appSetupResponse = serverResponse.data.data.getDataLoadConfig;  
  if (appSetupResponse.length > 0) {
    const appSetupRecord = appSetupResponse[0];
    const { configJson } = appSetupRecord;
    const productParsedConfigJSON = JSON.parse(configJson);
    // setProductConfigjson(productParsedConfigJSON)
    if(key === "Sup"){
      let supplierColumns = []
    for (let index = 0; index < productParsedConfigJSON.length; index++) {
      const columnName = productParsedConfigJSON[index].columnName;
      const fieldName = productParsedConfigJSON[index].fieldName;
      const enabledField = productParsedConfigJSON[index].enabledField;
      const isMandetory = productParsedConfigJSON[index].isMandatory;
      supplierColumns.push({
        title: columnName,
        dataIndex: fieldName,
        width: 180,
        ellipsis: true,
        hidden:enabledField === "N" ? true:false,
        isMandetoryTitleAndColumn:`${columnName}${isMandetory==="Y" ?'*':''}`,
        isMandetoryTitle:isMandetory==="Y" ?'*':null
      });
    }
    const supColumns= supplierColumns.filter(item => !item.hidden);
    setSuppliersCols(supColumns)
    setLoading(false)
    }else if(key === "Rc"){
    let retailCustomerColumns = []
    for (let index = 0; index < productParsedConfigJSON.length; index++) {
      const columnName = productParsedConfigJSON[index].columnName;
      const fieldName = productParsedConfigJSON[index].fieldName;
      const enabledField = productParsedConfigJSON[index].enabledField;
      const isMandetory = productParsedConfigJSON[index].isMandatory;
      retailCustomerColumns.push({
        title: columnName,
        dataIndex: fieldName,
        width: 180,
        ellipsis: true,
        hidden:enabledField === "N" ? true:false,
        isMandetoryTitleAndColumn:`${columnName}${isMandetory==="Y" ?'*':''}`,
        isMandetoryTitle:isMandetory==="Y" ?'*':null
      });
    }
    const rcColumns= retailCustomerColumns.filter(item => !item.hidden);
    setRetailCustomerCols(rcColumns)
    setLoading(false)
    }else if(key === "Cus"){
      let customerColumns = []
    for (let index = 0; index < productParsedConfigJSON.length; index++) {
      const columnName = productParsedConfigJSON[index].columnName;
      const fieldName = productParsedConfigJSON[index].fieldName;
      const enabledField = productParsedConfigJSON[index].enabledField;
      const isMandetory = productParsedConfigJSON[index].isMandatory;
      customerColumns.push({
        title: columnName,
        dataIndex: fieldName,
        width: 180,
        ellipsis: true,
        hidden:enabledField === "N" ? true:false,
        isMandetoryTitleAndColumn:`${columnName}${isMandetory==="Y" ?'*':''}`,
        isMandetoryTitle:isMandetory==="Y" ?'*':null
      });
    }
    const rcColumns= customerColumns.filter(item => !item.hidden);
    setCustomerCols(rcColumns)
    setLoading(false)
    }else if(key === "SOB"){
      let sobColumns = []
      for (let index = 0; index < productParsedConfigJSON.length; index++) {
        const columnName = productParsedConfigJSON[index].columnName;
        const fieldName = productParsedConfigJSON[index].fieldName;
        const enabledField = productParsedConfigJSON[index].enabledField;
        const isMandetory = productParsedConfigJSON[index].isMandatory;
        sobColumns.push({
          title: columnName,
          dataIndex: fieldName,
          width: 180,
          ellipsis: true,
          hidden:enabledField === "N" ? true:false,
          isMandetoryTitleAndColumn:`${columnName}${isMandetory==="Y" ?'*':''}`,
          isMandetoryTitle:isMandetory==="Y" ?'*':null
        });
      }
      const rcColumns= sobColumns.filter(item => !item.hidden);
      setStockOpeningColumns(rcColumns)
      setLoading(false)
    }else if(key === "Prd"){
      let Cols = []
      for (let index = 0; index < productParsedConfigJSON.length; index++) {
        const columnName = productParsedConfigJSON[index].columnName;
        const fieldName = productParsedConfigJSON[index].fieldName;
        const enabledField = productParsedConfigJSON[index].enabledField;
        const isMandetory = productParsedConfigJSON[index].isMandatory;
        Cols.push({
          title: columnName,
          dataIndex: fieldName,
          width: 180,
          ellipsis: true,
          hidden:enabledField === "N" ? true:false,
          isMandetoryTitleAndColumn:`${columnName}${isMandetory==="Y" ?'*':''}`,
          isMandetoryTitle:isMandetory==="Y" ?'*':null
        });
      }
      const rcColumns= Cols.filter(item => !item.hidden);
      setProductColumns(rcColumns)
      setLoading(false)
    }else if (key === "Pi"){
      let purchaseInvoiceCols = []
      for (let index = 0; index < productParsedConfigJSON.length; index++) {
        const columnName = productParsedConfigJSON[index].columnName;
        const fieldName = productParsedConfigJSON[index].fieldName;
        const enabledField = productParsedConfigJSON[index].enabledField;
        const isMandetory = productParsedConfigJSON[index].isMandatory;
        const mproductId = productParsedConfigJSON[index].value;
        purchaseInvoiceCols.push({
          title: columnName,
          dataIndex: fieldName,
          width: 180,
          ellipsis: true,
          mproductId:mproductId,
          hidden:enabledField === "N" ? true:false,
          isMandetoryTitleAndColumn:`${columnName}${isMandetory==="Y" ?'*':''}`,
          isMandetoryTitle:isMandetory==="Y" ?'*':null
        });
      }
      const rcColumns= purchaseInvoiceCols.filter(item => !item.hidden);
      setMproductIdForPurchaseInvoice(productParsedConfigJSON[2].value)
      setPurchaseInvoiceColumns(rcColumns)
      setLoading(false)
    }else if (key === "Si"){
      let salesInvoiceCols = []
      for (let index = 0; index < productParsedConfigJSON.length; index++) {
        const columnName = productParsedConfigJSON[index].columnName;
        const fieldName = productParsedConfigJSON[index].fieldName;
        const enabledField = productParsedConfigJSON[index].enabledField;
        const isMandetory = productParsedConfigJSON[index].isMandatory;
        const mproductId = productParsedConfigJSON[index].value;
        salesInvoiceCols.push({
          title: columnName,
          dataIndex: fieldName,
          width: 180,
          ellipsis: true,
          mproductId:mproductId,
          hidden:enabledField === "N" ? true:false,
          isMandetoryTitleAndColumn:`${columnName}${isMandetory==="Y" ?'*':''}`,
          isMandetoryTitle:isMandetory==="Y" ?'*':null
        });
      }
      const rcColumns= salesInvoiceCols.filter(item => !item.hidden);
      setMproductIdForSalesInvoice(productParsedConfigJSON[2].value)
      setSalesInvoiceColumns(rcColumns)
      setLoading(false)
    }
  }
 }
 
 const downloadImportTemplateForProducts = () =>{
  const newArray = []
  for (let index = 0; index < productColumns.length; index++) {
    const element = productColumns[index].isMandetoryTitleAndColumn;
    newArray.push(element)
    
  }
  const options = {
    fieldSeparator: ',',
    filename: 'Products',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: false,
    headers:newArray
  }
  let newArray2 = []
  
  const obj = {};
  for (let i = 0; i < newArray.length; i++) {
    obj[newArray[i]] = '';
  }
  newArray2.push(obj)
  const csvExporter = new ExportToCsv(options)
    csvExporter.generateCsv(newArray2)
 }

 const readImportProducts = (evt) =>{
  const { files } = evt.target;
  const filename = files[0].name;
  const fileExt = filename.substr(filename.lastIndexOf("."), filename.length);
  if (fileExt !== ".csv") {
    message.error("Please select csv file");
  } else {
    const file = files[0];
      const reader = new FileReader();
      let fileTextData;
      reader.onload = (e) => {
        fileTextData = e.target.result;
        const rows = fileTextData.split(/\r?\n/);
        const newData = [];
        rows.forEach((element) => {
          if (element.trim() !== "") {
            newData.push(element);
          }
        });
        
        const dataArr = [];
        let flag = true
        for (let i = 1; i < newData.length; i += 1) {
          const cells = newData[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
          if(cells.length === productColumns.length){
            const jsonObject = {};
            for (let index = 0; index < productColumns.length; index += 1) {
              const element = productColumns[index];
              const isMandetory = productColumns[index].isMandetoryTitle
              if(isMandetory === '*'){
                const iscellsEmpty = cells[index].replace(/"/g, "")
                if(iscellsEmpty === "" || iscellsEmpty === undefined || iscellsEmpty === null){
                  flag = false
                }
              }
              jsonObject[element.dataIndex] = cells[index].replace(/"/g, "");
            }
            dataArr.push(jsonObject);
          }else{
            message.error("Header and data not matching")
            setProductData([])
            document.getElementById("choosefile").value = null;
          }
        }
        if(flag === false){
          message.error("Please add data in mandatory columns")
          document.getElementById("choosefile").value = null;
        }else{
          if(dataArr.length > 0){
            message.success("Data imported successfully");
            setProductData(dataArr)
          }
        }
      }
      reader.readAsText(file);
  }
 }

 const downloadImportTemplateForSuppliers = () =>{
  const newArray = []
  for (let index = 0; index < suppliersCols.length; index++) {
    const element = suppliersCols[index].isMandetoryTitleAndColumn;
    newArray.push(element)
    
  }
  const options = {
    fieldSeparator: ',',
    filename: 'Suppliers',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: false,
    headers:newArray
  }
  let newArray2 = []
  
  const obj = {};
  for (let i = 0; i < newArray.length; i++) {
    obj[newArray[i]] = '';
  }
  newArray2.push(obj)
  const csvExporter = new ExportToCsv(options)
    csvExporter.generateCsv(newArray2)
 }


 const readImportSuppliers = (evt) =>{
  const { files } = evt.target;
  const filename = files[0].name;
  const fileExt = filename.substr(filename.lastIndexOf("."), filename.length);
  if (fileExt !== ".csv") {
    message.error("Please select csv file");
  } else {
    const file = files[0];
      const reader = new FileReader();
      let fileTextData;
      reader.onload = (e) => {
        fileTextData = e.target.result;
        const rows = fileTextData.split(/\r?\n/);
        const newData = [];
        rows.forEach((element) => {
          if (element.trim() !== "") {
            newData.push(element);
          }
        });
        
        const dataArr = [];
        let importCount = 1;
        let flag = true
        for (let i = 1; i < newData.length; i += 1) {
          const cells = newData[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
          if(cells.length === suppliersCols.length){
            const jsonObject = {};
            importCount += 1;
            for (let index = 0; index < suppliersCols.length; index += 1) {
              const element = suppliersCols[index];
              const isMandetory = suppliersCols[index].isMandetoryTitle
              if(isMandetory === '*'){
                const iscellsEmpty = cells[index].replace(/"/g, "")
                if(iscellsEmpty === "" || iscellsEmpty === undefined || iscellsEmpty === null){
                  flag = false
                }
              }
              jsonObject[element.dataIndex] = cells[index].replace(/"/g, "");
            }
            dataArr.push(jsonObject);
          }else{
            message.error("Header and data not matching")
            setSupplierData([])
            document.getElementById("choosefile").value = null;
          }
        }
        if(flag === false){
          message.error("Please add data in mandatory columns")
          document.getElementById("choosefile").value = null;
        }else{
          if(dataArr.length > 0){
            message.success("Data imported successfully");
            setSupplierData(dataArr)
          }
        }
      }
      reader.readAsText(file);
  }
 }

 const downloadImportTemplateForRetailCustomers = () =>{
  const newArray = []
  for (let index = 0; index < retailCustomerCols.length; index++) {
    const element = retailCustomerCols[index].isMandetoryTitleAndColumn;
    newArray.push(element)
    
  }
  const options = {
    fieldSeparator: ',',
    filename: 'RetailCustomer',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: false,
    headers:newArray
  }
  let newArray2 = []
  
  const obj = {};
  for (let i = 0; i < newArray.length; i++) {
    obj[newArray[i]] = '';
  }
  newArray2.push(obj)
  const csvExporter = new ExportToCsv(options)
    csvExporter.generateCsv(newArray2)
  }

  const downloadImportTemplateForCustomers = () =>{
    const newArray = []
    for (let index = 0; index < customerCols.length; index++) {
      const element = customerCols[index].isMandetoryTitleAndColumn;
      newArray.push(element)
      
    }
    const options = {
      fieldSeparator: ',',
      filename: 'Customers',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers:newArray
    }
    let newArray2 = []
    
    const obj = {};
    for (let i = 0; i < newArray.length; i++) {
      obj[newArray[i]] = '';
    }
    newArray2.push(obj)
    const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(newArray2)
    }

    const downloadImportTemplateForPi = () =>{
    const newArray = []
    for (let index = 0; index < purchaseInvoiceColumns.length; index++) {
      const element = purchaseInvoiceColumns[index].isMandetoryTitleAndColumn;
      newArray.push(element)
      
    }
    const options = {
      fieldSeparator: ',',
      filename: 'purchaseInvoice',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers:newArray
    }
    let newArray2 = []
    
    const obj = {};
    for (let i = 0; i < newArray.length; i++) {
      obj[newArray[i]] = '';
    }
    newArray2.push(obj)
    const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(newArray2)
    }

    const readImportProductPi = (evt) =>{
    const { files } = evt.target;
    const filename = files[0].name;
    const fileExt = filename.substr(filename.lastIndexOf("."), filename.length);
    if(mProductIdForPurchaseInvoice === null || mProductIdForPurchaseInvoice === undefined || mProductIdForPurchaseInvoice === ""){
      message.error("Please Add Product Id in configuration")
      document.getElementById("choosefile").value = null;
    }else{
      if (fileExt !== ".csv") {
        message.error("Please select csv file");
      } else {
        const file = files[0];
          const reader = new FileReader();
          let fileTextData;
          reader.onload = (e) => {
            fileTextData = e.target.result;
            const rows = fileTextData.split(/\r?\n/);
            const newData = [];
            rows.forEach((element) => {
              if (element.trim() !== "") {
                newData.push(element);
              }
            });
            const dataArr = [];
            let flag = true
            for (let i = 1; i < newData.length; i += 1) {
              const cells = newData[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
              if(cells.length === purchaseInvoiceColumns.length){
                const jsonObject = {};
                for (let index = 0; index < purchaseInvoiceColumns.length; index += 1) {
                  const element = purchaseInvoiceColumns[index];
                  const isMandetory = purchaseInvoiceColumns[index].isMandetoryTitle
                  if(isMandetory === '*'){
                    const iscellsEmpty = cells[index].replace(/"/g, "")
                    if(iscellsEmpty === "" || iscellsEmpty === undefined || iscellsEmpty === null){
                      flag = false
                    }
                  }
                  jsonObject[element.dataIndex] = cells[index].replace(/"/g, "");
                }
                dataArr.push(jsonObject);
              }else{
                message.error("Header and data not matching")
                setPurchaseInvoiceData([])
                document.getElementById("choosefile").value = null;
              }
            }
            if(flag === false){
              message.error("Please add data in mandatory columns")
              document.getElementById("choosefile").value = null;
            }else{
              if(dataArr.length > 0){
                message.success("Data imported successfully");
                setPurchaseInvoiceData(dataArr)
              }
            }
          }
          reader.readAsText(file);
      } 
    }
  }

  const downloadImportTemplateForSi = () =>{
    const newArray = []
    for (let index = 0; index < salesInvoiceColumns.length; index++) {
      const element = salesInvoiceColumns[index].isMandetoryTitleAndColumn;
      newArray.push(element)
      
    }
    const options = {
      fieldSeparator: ',',
      filename: 'salesInvoice',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers:newArray
    }
    let newArray2 = []
    
    const obj = {};
    for (let i = 0; i < newArray.length; i++) {
      obj[newArray[i]] = '';
    }
    newArray2.push(obj)
    const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(newArray2)
  }

  const readImportProductSi = (evt) =>{
    const { files } = evt.target;
    const filename = files[0].name;
    const fileExt = filename.substr(filename.lastIndexOf("."), filename.length);
    if(mproductIdForSalesInvoice === null || mproductIdForSalesInvoice === undefined || mproductIdForSalesInvoice === ""){
      message.error("Please Add Product Id in configuration")
      document.getElementById("choosefile").value = null;
    }else{
      if (fileExt !== ".csv") {
        message.error("Please select csv file");
      } else {
        const file = files[0];
          const reader = new FileReader();
          let fileTextData;
          reader.onload = (e) => {
            fileTextData = e.target.result;
            const rows = fileTextData.split(/\r?\n/);
            const newData = [];
            rows.forEach((element) => {
              if (element.trim() !== "") {
                newData.push(element);
              }
            });
            const dataArr = [];
            let flag = true
            for (let i = 1; i < newData.length; i += 1) {
              const cells = newData[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
              if(cells.length === salesInvoiceColumns.length){
                const jsonObject = {};
                for (let index = 0; index < salesInvoiceColumns.length; index += 1) {
                  const element = salesInvoiceColumns[index];
                  const isMandetory = salesInvoiceColumns[index].isMandetoryTitle
                  if(isMandetory === '*'){
                    const iscellsEmpty = cells[index].replace(/"/g, "")
                    if(iscellsEmpty === "" || iscellsEmpty === undefined || iscellsEmpty === null){
                      flag = false
                    }
                  }
                  jsonObject[element.dataIndex] = cells[index].replace(/"/g, "");
                }
                dataArr.push(jsonObject);
              }else{
                message.error("Header and data not matching")
                setSalesInvoiceData([])
                document.getElementById("choosefile").value = null;
              }
            }
            if(flag === false){
              message.error("Please add data in mandatory columns")
              document.getElementById("choosefile").value = null;
            }else{
              if(dataArr.length > 0){
                message.success("Data imported successfully");
                setSalesInvoiceData(dataArr)
              }
            }
          }
          reader.readAsText(file);
      } 
    }
  }
    const downloadImportTemplateForSob = () =>{
      const newArray = []
    for (let index = 0; index < stockOpeningColumns.length; index++) {
      const element = stockOpeningColumns[index].isMandetoryTitleAndColumn;
      newArray.push(element)
      
    }
    const options = {
      fieldSeparator: ',',
      filename: 'stockOpeningBalance',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers:newArray
    }
    let newArray2 = []
    
    const obj = {};
    for (let i = 0; i < newArray.length; i++) {
      obj[newArray[i]] = '';
    }
    newArray2.push(obj)
    const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(newArray2)
    }

    const readImportProductSob = (evt) =>{
      const { files } = evt.target;
    const filename = files[0].name;
    const fileExt = filename.substr(filename.lastIndexOf("."), filename.length);
    if (fileExt !== ".csv") {
      message.error("Please select csv file");
    } else {
      const file = files[0];
        const reader = new FileReader();
        let fileTextData;
        reader.onload = (e) => {
          fileTextData = e.target.result;
          const rows = fileTextData.split(/\r?\n/);
          const newData = [];
          rows.forEach((element) => {
            if (element.trim() !== "") {
              newData.push(element);
            }
          });
          const dataArr = [];
          let importCount = 1;
          let flag = true
          for (let i = 1; i < newData.length; i += 1) {
            const cells = newData[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
            if(cells.length === stockOpeningColumns.length){
              const jsonObject = {};
              importCount += 1;
              for (let index = 0; index < stockOpeningColumns.length; index += 1) {
                const element = stockOpeningColumns[index];
                const isMandetory = stockOpeningColumns[index].isMandetoryTitle
                if(isMandetory === '*'){
                  const iscellsEmpty = cells[index].replace(/"/g, "")
                  if(iscellsEmpty === "" || iscellsEmpty === undefined || iscellsEmpty === null){
                    flag = false
                  }
                }
                jsonObject[element.dataIndex] = cells[index].replace(/"/g, "");
              }
              dataArr.push(jsonObject);
            }else{
              message.error("Header and data not matching")
              setCustomerData([])
              document.getElementById("choosefile").value = null;
            }
          }
          if(flag === false){
            message.error("Please add data in mandatory columns")
            document.getElementById("choosefile").value = null;
          }else{
            if(dataArr.length > 0){
              message.success("Data imported successfully");
              setStockOpeningData(dataArr)
            }
          }
        }
        reader.readAsText(file);
    } 
    }



    const downloadImportTemplateForProductCatalouge = () =>{
      const options = {
        fieldSeparator: ',',
        filename: 'productCatalouge',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers:['Sku'] 
      }
      const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv([{'Sku':''}]) 
    }

    const readImportProductCatalouge = (evt) =>{
      const fieldValues = headerform.getFieldsValue(true)
      if(fieldValues.productCatalougeName === undefined || fieldValues.productCatalougeName === null || fieldValues.productCatalougeName === ""){
        message.error("Please Add Catalouge Name")
        document.getElementById("choosefile").value = null;
      }else{
      const catalougeName = fieldValues.productCatalougeName
      const { files } = evt.target;
      const filename = files[0].name;
      const fileExt = filename.substr(filename.lastIndexOf("."), filename.length);
      if (fileExt !== ".csv") {
        message.error("Please select csv file");
      } else {
        const file = files[0];
          const reader = new FileReader();
          let fileTextData;
          reader.onload = (e) => {
            fileTextData = e.target.result;
            const rows = fileTextData.split(/\r?\n/);
            var dataArr = []
            for (var i = 1; i < rows.length - 1; i++) {
              var cells = rows[i].split(',')
              if (cells.length === 1) {
                dataArr.push({
                  sku: cells[0],
                  productCatalougeName:catalougeName
                })
              }
            }
            setProductCatalougeData(dataArr)
          }

          reader.readAsText(file);
      }
      }
    }

  const readImportRetailCustomers = (evt) => {
    const { files } = evt.target;
  const filename = files[0].name;
  const fileExt = filename.substr(filename.lastIndexOf("."), filename.length);
  if (fileExt !== ".csv") {
    message.error("Please select csv file");
  } else {
    const file = files[0];
      const reader = new FileReader();
      let fileTextData;
      reader.onload = (e) => {
        fileTextData = e.target.result;
        const rows = fileTextData.split(/\r?\n/);
        const newData = [];
        rows.forEach((element) => {
          if (element.trim() !== "") {
            newData.push(element);
          }
        });
        const dataArr = [];
        let importCount = 1;
        let flag = true
        for (let i = 1; i < newData.length; i += 1) {
          const cells = newData[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
          if(cells.length === retailCustomerCols.length){
            const jsonObject = {};
            importCount += 1;
            for (let index = 0; index < retailCustomerCols.length; index += 1) {
              const element = retailCustomerCols[index];
              const isMandetory = retailCustomerCols[index].isMandetoryTitle
              if(isMandetory === '*'){
                const iscellsEmpty = cells[index].replace(/"/g, "")
                if(iscellsEmpty === "" || iscellsEmpty === undefined || iscellsEmpty === null){
                  flag = false
                }
              }
              jsonObject[element.dataIndex] = cells[index].replace(/"/g, "");
            }
            dataArr.push(jsonObject);
          }else{
            message.error("Header and data not matching")
            setRetailCustomerData([])
            document.getElementById("choosefile").value = null;
          }
        }
        if(flag === false){
          message.error("Please add data in mandatory columns")
          document.getElementById("choosefile").value = null;
        }else{
          if(dataArr.length > 0){
            message.success("Data imported successfully");
            setRetailCustomerData(dataArr)
          }
        }
      }
      reader.readAsText(file);
  }
  }

  const readImportCustomers = (evt) =>{
    const { files } = evt.target;
    const filename = files[0].name;
    const fileExt = filename.substr(filename.lastIndexOf("."), filename.length);
    if (fileExt !== ".csv") {
      message.error("Please select csv file");
    } else {
      const file = files[0];
        const reader = new FileReader();
        let fileTextData;
        reader.onload = (e) => {
          fileTextData = e.target.result;
          const rows = fileTextData.split(/\r?\n/);
          const newData = [];
          rows.forEach((element) => {
            if (element.trim() !== "") {
              newData.push(element);
            }
          });
          const dataArr = [];
          let importCount = 1;
          let flag = true
          for (let i = 1; i < newData.length; i += 1) {
            const cells = newData[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
            if(cells.length === customerCols.length){
              const jsonObject = {};
              importCount += 1;
              for (let index = 0; index < customerCols.length; index += 1) {
                const element = customerCols[index];
                const isMandetory = customerCols[index].isMandetoryTitle
                if(isMandetory === '*'){
                  const iscellsEmpty = cells[index].replace(/"/g, "")
                  if(iscellsEmpty === "" || iscellsEmpty === undefined || iscellsEmpty === null){
                    flag = false
                  }
                }
                jsonObject[element.dataIndex] = cells[index].replace(/"/g, "");
              }
              dataArr.push(jsonObject);
            }else{
              message.error("Header and data not matching")
              setCustomerData([])
              document.getElementById("choosefile").value = null;
            }
          }
          if(flag === false){
            message.error("Please add data in mandatory columns")
            document.getElementById("choosefile").value = null;
          }else{
            if(dataArr.length > 0){
              message.success("Data imported successfully");
              setCustomerData(dataArr)
            }
          }
        }
        reader.readAsText(file);
    } 
  }


 const processImport = async () =>{
   if(dataLoadType === 'Suppliers'){
     if(supplierData.length > 0){
      setLoading(true)
      const response = await getUpsertSupplier(supplierData)
      if(response.data.data.importSupplier.status === "200"){
        message.success(response.data.data.importSupplier.message)
      }else{
        message.error(response.data.data.importSupplier.message)
      }
      setLoading(false)
     }else{
       message.error('No Data')
     }
   }else if(dataLoadType === 'Retail Customers'){
    if(retailCustomerData.length > 0){
      setLoading(true)
      const response = await getUpsertRetailCustomer(retailCustomerData)
      if(response.data.data.importRetailCustomer.status === "200"){
        message.success(response.data.data.importRetailCustomer.message)
        setRetailCustomerData([])
        headerform.resetFields()
        setDataLoadType(null)
      }else{
        message.error(response.data.data.importRetailCustomer.message)
      }
      setLoading(false)
     }else{
       message.error('No Data')
     }
   }else if(dataLoadType === 'Customers'){
    if(customerData.length > 0){
      setLoading(true)
      const response = await getUpsertCustomers(customerData)
      if(response.data.data.importCustomer.status === "200"){
        message.success(response.data.data.importCustomer.message)
        setCustomerData([])
        headerform.resetFields()
        setDataLoadType(null)
      }else{
        message.error(response.data.data.importCustomer.message)
      }
      setLoading(false)
     }else{
       message.error('No Data')
     }
   }else if(dataLoadType === 'Product Catalogue'){
    if(productCatalougeData.length > 0){
      setLoading(true)
      const fieldValues = headerform.getFieldsValue(true)
      const response = await getUpsertProductCatalouge(productCatalougeData,fieldValues.productCatalougeName)
      if(response.data.data.importProductCatalogue.status === "200"){
        message.success(response.data.data.importProductCatalogue.message)
        setCustomerData([])
        headerform.resetFields()
        setDataLoadType(null)
      }else{
        message.error(response.data.data.importProductCatalogue.message)
      }
      setLoading(false)
     }else{
       message.error('No Data')
     }
   }else if(dataLoadType === 'Stock Opening Balance'){
     const businessUnit = headerform.getFieldValue('businessUnit')
     if(businessUnit === undefined || businessUnit === null){
       message.error("Please select business unit!")
     }else{
      if(stockOpeningData.length > 0){
        setLoading(true)
        const fieldValues = headerform.getFieldsValue(true)
        const todaysDate = new Date()
        const todaysFormattedDate = moment(todaysDate).format('YYYY-MM-DD')
        const response = await getUpsertStockOpeningBalance(stockOpeningData,businessUnit,todaysFormattedDate)
        if(response.data.data.importStock.status === "200"){
          message.success(response.data.data.importStock.message)
          setStockOpeningData([])
          headerform.resetFields()
          setDataLoadType(null)
        }else{
          message.error(response.data.data.importStock.message)
        }
        setLoading(false)
       }else{
         message.error('No Data')
       }
     }
   }else if(dataLoadType === 'Products'){
    if(productData.length > 0){
      setLoading(true)
      const response = await getUpsertImportedProducts(productData)
      if(response.data.data.importProduct.status === "200"){
        message.success(response.data.data.importProduct.message)
        setStockOpeningData([])
        headerform.resetFields()
        setDataLoadType(null)
      }else{
        message.error(response.data.data.importProduct.message)
      }
      setLoading(false)
     }else{
       message.error('No Data')
     }
   }else if (dataLoadType === "Purchase Invoices"){
    const formData = headerform.getFieldsValue(true)
    const businessUnit = formData.businessUnit
    const invoiceDate = formData.inVoiceDate

    if((businessUnit === undefined || businessUnit === null) && (invoiceDate === undefined || invoiceDate === null)){
      message.error("Please select business unit and Invoice Date!")
    }else{
      if(purchaseInvoiceData.length > 0){
        setLoading(true)
        const FormattedInvoiceDate = moment(invoiceDate).format('YYYY-MM-DD')
        const response = await getUpsertPurchaseInvoice(purchaseInvoiceData,businessUnit,FormattedInvoiceDate,mProductIdForPurchaseInvoice)
        if(response.data.data.importPurchaseInvoices.status === "200"){
          message.success(response.data.data.importPurchaseInvoices.message)
          setPurchaseInvoiceData([])
          setMproductIdForPurchaseInvoice(null)
          headerform.resetFields()
          setDataLoadType(null)
        }else{
          message.error(response.data.data.importPurchaseInvoices.message)
        }
        setLoading(false)
       }else{
         message.error('No Data')
       }
    }
   }else if(dataLoadType === "Sales Invoices"){
    const formData = headerform.getFieldsValue(true)
    const businessUnit = formData.businessUnit
    const invoiceDate = formData.inVoiceDate

    if((businessUnit === undefined || businessUnit === null) && (invoiceDate === undefined || invoiceDate === null)){
      message.error("Please select business unit and Invoice Date!")
    }else{
      if(salesInvoiceData.length > 0){
        setLoading(true)
        const FormattedInvoiceDate = moment(invoiceDate).format('YYYY-MM-DD')
        const response = await getUpsertSalesInvoice(salesInvoiceData,businessUnit,FormattedInvoiceDate,mproductIdForSalesInvoice)
        if(response.data.data.importSalesInvoices.status === "200"){
          message.success(response.data.data.importSalesInvoices.message)
          setSalesInvoiceData([])
          setMproductIdForSalesInvoice(null)
          headerform.resetFields()
          setDataLoadType(null)
        }else{
          message.error(response.data.data.importSalesInvoices.message)
        }
        setLoading(false)
       }else{
         message.error('No Data')
       }
    }
   }
 }

    return (
      <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />} spinning={loading}>
        <div>
          <Row>
            <Col span={12}>
              <h2 style={{ fontWeight: "700", fontSize: "16px", color: "rgb(0 0 0 / 65%)", marginBottom: "0px", marginTop: "1%" }}>Initial Data Load</h2>
            </Col>
            <Col span={12}>
              <span style={{ float: "right" }}>
                <Button onClick={processImport} style={{ marginBottom: "8px", backgroundColor: "rgb(8 158 164)", color: "white", width: "93px", height: "33px" }}>Process</Button>
              </span>
            </Col>
          </Row>
          <Card style={{ marginBottom: "8px" }}>
            <Form layout="vertical" form={headerform} name="control-hooks" /* onFinish={onFinish} */>
              <Row gutter={16}>
                <Col className="gutter-row" span={6}>
                  <Form.Item name="dlType" label="Data Load Type" style={{ marginBottom: "8px" }}>
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      // onFocus={getBusinessUnit}
                      onChange={onChageDataLoadType}
                    >
                        <Option key='Prd' value="Products" title="Products">
                          Products
                        </Option>
                        <Option key='Sup' value="Suppliers" title="Suppliers">
                          Suppliers
                        </Option>
                        <Option key='Rc' value="Retail Customers" title="Retail Customers">
                          Retail Customer
                        </Option>
                        <Option key='Cus' value="Customers" title="Customers">
                          Customers
                        </Option>
                        <Option key='5' value="Product Catalogue" title="Product Catalogue">
                        Product Catalogue
                        </Option>
                        <Option key='Pi' value="Purchase Invoices" title="Purchase Invoices">
                        Purchase Invoices
                        </Option>
                        <Option key='Si' value="Sales Invoices" title="Sales Invoices">
                        Sales Invoices
                        </Option>
                        <Option key='SOB' value="Stock Opening Balance" title="Stock Opening Balance">
                        Stock Opening Balance
                        </Option>
                    </Select>
                  </Form.Item>
                </Col>
                {dataLoadType === "Product Catalogue"?
                <Col className="gutter-row" span={6}>
                <Form.Item name="productCatalougeName" label="Product Catalouge Name" style={{ marginBottom: "8px" }}>
                  <Input />
                </Form.Item>
                </Col>:null}
                {dataLoadType === "Stock Opening Balance" || dataLoadType === "Purchase Invoices" || dataLoadType === "Sales Invoices"?
                <Col className="gutter-row" span={6}>
                <Form.Item name="businessUnit" label="Business unit" style={{ marginBottom: "8px" }}>
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {bunitData.map((data, index) => (
                        <Option key={data.csBunitId} value={data.csBunitId} title={data.bUnitName}>
                          {data.bUnitName}
                        </Option>
                      ))}
                    </Select>
                </Form.Item>
                </Col>:null}
                {dataLoadType === "Purchase Invoices" || dataLoadType === "Sales Invoices" ?
                <Col className="gutter-row" span={6}>
                <Form.Item name="inVoiceDate" label="Invoice Date" style={{ marginBottom: "8px" }}>
                  <DatePicker style={{width:'100%'}}/>
                </Form.Item>
                </Col>
                :null}
              </Row>
            </Form>
          </Card>
          {/* <p />   */}
          {dataLoadType === "Products" ?
          <Row style={{textAlign:'right'}}>
            <Col span={24}>
              <Tooltip placement="top" title={"Download Template"}>
                  <Button size="small" onClick={downloadImportTemplateForProducts}>
                    <DownloadOutlined />
                  </Button>
                </Tooltip>
                &nbsp;
                <input id="choosefile" type="file" accept=".csv" onChange={readImportProducts}/>
            </Col>
          </Row> 
          :dataLoadType === "Suppliers" ?
          <Row style={{textAlign:'right'}}>
          <Col span={24}>
            <Tooltip placement="top" title={"Download Template"}>
                <Button size="small" onClick={downloadImportTemplateForSuppliers}>
                  <DownloadOutlined />
                </Button>
              </Tooltip>
              &nbsp;
              <input id="choosefile" type="file" accept=".csv" onChange={readImportSuppliers}/>
          </Col>
        </Row> 
          :dataLoadType ==="Retail Customers" ?
          <Row style={{textAlign:'right'}}>
          <Col span={24}>
            <Tooltip placement="top" title={"Download Template"}>
                <Button size="small" onClick={downloadImportTemplateForRetailCustomers}>
                  <DownloadOutlined />
                </Button>
              </Tooltip>
              &nbsp;
              <input id="choosefile" type="file" accept=".csv" onChange={readImportRetailCustomers}/>
          </Col>
        </Row> 
          :dataLoadType === "Customers"?
          <Row style={{textAlign:'right'}}>
          <Col span={24}>
            <Tooltip placement="top" title={"Download Template"}>
                <Button size="small" onClick={downloadImportTemplateForCustomers}>
                  <DownloadOutlined />
                </Button>
              </Tooltip>
              &nbsp;
              <input id="choosefile" type="file" accept=".csv" onChange={readImportCustomers}/>
          </Col>
        </Row> 
        :dataLoadType === "Product Catalogue"?
        <Row style={{textAlign:'right'}}>
          <Col span={24}>
            <Tooltip placement="top" title={"Download Template"}>
                <Button size="small" onClick={downloadImportTemplateForProductCatalouge}>
                  <DownloadOutlined />
                </Button>
              </Tooltip>
              &nbsp;
              <input id="choosefile" type="file" accept=".csv" onChange={readImportProductCatalouge}/>
          </Col>
        </Row>
          :dataLoadType === "Stock Opening Balance"?
          <Row style={{textAlign:'right'}}>
            <Col span={24}>
              <Tooltip placement="top" title={"Download Template"}>
                  <Button size="small" onClick={downloadImportTemplateForSob}>
                    <DownloadOutlined />
                  </Button>
                </Tooltip>
                &nbsp;
                <input id="choosefile" type="file" accept=".csv" onChange={readImportProductSob}/>
            </Col>
          </Row>
            :dataLoadType === "Purchase Invoices"?
            <Row style={{textAlign:'right'}}>
            <Col span={24}>
              <Tooltip placement="top" title={"Download Template"}>
                  <Button size="small" onClick={downloadImportTemplateForPi}>
                    <DownloadOutlined />
                  </Button>
                </Tooltip>
                &nbsp;
                <input id="choosefile" type="file" accept=".csv" onChange={readImportProductPi}/>
            </Col>
          </Row>
            :dataLoadType === "Sales Invoices"?
            <Row style={{textAlign:'right'}}>
            <Col span={24}>
              <Tooltip placement="top" title={"Download Template"}>
                  <Button size="small" onClick={downloadImportTemplateForSi}>
                    <DownloadOutlined />
                  </Button>
                </Tooltip>
                &nbsp;
                <input id="choosefile" type="file" accept=".csv" onChange={readImportProductSi}/>
            </Col>
          </Row>
            :null}
          <p />  
          <Row>
            <Col span={24}>
            {dataLoadType === "Products" ?
              <Card>
              <div>
                  <Table 
                    columns={productColumns} 
                    dataSource={productData}
                    style={{ fontSize: "12px" }}
                    size="small"
                    sticky={true}
                    scroll={{ y: "30vh",x: "100%"}}
                    pagination={false}
                    />
              </div>
            </Card>
            :dataLoadType === "Suppliers" ?
            <Card>
              <div>
                  <Table 
                    columns={suppliersCols} 
                    dataSource={supplierData}
                    style={{ fontSize: "12px" }}
                    size="small"
                    sticky={true}
                    scroll={{ y: "30vh",x: "100%"}}
                    pagination={false}
                    />
              </div>
            </Card>
            :dataLoadType ==="Retail Customers" ?
            <Card>
              <div>
                  <Table 
                    columns={retailCustomerCols} 
                    dataSource={retailCustomerData}
                    style={{ fontSize: "12px" }}
                    size="small"
                    sticky={true}
                    scroll={{ y: "30vh",x: "100%"}}
                    pagination={false}
                    />
              </div>
            </Card>
            :dataLoadType === "Customers"?
            <Card>
              <div>
                  <Table 
                    columns={customerCols} 
                    dataSource={customerData}
                    style={{ fontSize: "12px" }}
                    size="small"
                    sticky={true}
                    scroll={{ y: "30vh",x: "100%"}}
                    pagination={false}
                    />
              </div>
            </Card>
            :dataLoadType === "Product Catalogue"?
            <Card>
              <div>
                  <Table 
                    columns={productCatalougeColumns} 
                    dataSource={productCatalougeData}
                    style={{ fontSize: "12px" }}
                    size="small"
                    sticky={true}
                    scroll={{ y: "30vh",x: "100%"}}
                    pagination={false}
                    />
              </div>
            </Card>
            :dataLoadType === "Stock Opening Balance"?
            <Card>
              <div>
                  <Table 
                    columns={stockOpeningColumns} 
                    dataSource={stockOpeningData}
                    style={{ fontSize: "12px" }}
                    size="small"
                    sticky={true}
                    scroll={{ y: "30vh",x: "100%"}}
                    pagination={false}
                    />
              </div>
            </Card>
            :dataLoadType === "Purchase Invoices"?
            <Card>
              <div>
                  <Table 
                    columns={purchaseInvoiceColumns} 
                    dataSource={purchaseInvoiceData}
                    style={{ fontSize: "12px" }}
                    size="small"
                    sticky={true}
                    scroll={{ y: "30vh",x: "100%"}}
                    pagination={false}
                    />
              </div>
            </Card>
            :dataLoadType === "Sales Invoices"?
            <Card>
              <div>
                  <Table 
                    columns={salesInvoiceColumns} 
                    dataSource={salesInvoiceData}
                    style={{ fontSize: "12px" }}
                    size="small"
                    sticky={true}
                    scroll={{ y: "30vh",x: "100%"}}
                    pagination={false}
                    />
              </div>
            </Card>
            :null}
            </Col>  
          </Row>       
        </div>
      </Spin>
    );
}

export default InitialDataLoad